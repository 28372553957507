
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { currencyZAR, getTerminalCardProduct, isTerminalCardSelected } from '~/utils/utils';

export default Vue.extend({
  data() {
    return {
      isTerminalCardSelected,
      error: false,
      messageError: '',
      paymentMethods: [] as any,
      prices: [] as any,
      onboardingMerchant: {} as any,
      loading: false,
    };
  },
  computed: {
    ...mapState('auth', ['merchant', 'user', 'allMerchantAccounts']),
  },
  created() {
    this.onboardingMerchant = this.merchant;
    if (this.merchant) {
      this.getPriceList(this.merchant.pricelistId);
    }
  },
  mounted() {
    this.$root.$on('next-to-custom', async () => {
      await this.next();
    });

    this.$root.$on('next-to-fees', async () => {
      await this.next();
    });
  },
  methods: {
    ...mapActions({ getPriceListById: 'auth/getPriceListById' }),
    ...mapGetters({ getMerchantAccount: 'auth/merchant' }),
    checkSelectedPaymentMethods(): boolean {
      const selected = this.paymentMethods.filter((paymentMethod) => paymentMethod.value);
      if (selected && selected.length > 0) return true;
      return false;
    },
    convertAmount(amount) {
      if (amount) return currencyZAR(amount);
    },
    async next() {
      this.error = false;
      const selected = this.paymentMethods.filter((item) => item.value);
      if (selected && selected.length > 0) {
        await this.paymentMethods.forEach((element) => {
          if (element.method === 'peachEft') {
            this.onboardingMerchant.paymentPlans.paymentMethods.eft = element.value;
          }
          if (element.method === 'peachCard') {
            this.onboardingMerchant.paymentPlans.paymentMethods.creditOrDebitCard = element.value;
          }
          if (element.method === 'debicheck') {
            this.onboardingMerchant.paymentPlans.paymentMethods.debiCheck = element.value;
          }
          if (element.method === 'payat') {
            this.onboardingMerchant.paymentPlans.paymentMethods.payAt = element.value;
          }
          if (isTerminalCardSelected(element.method)) {
            this.onboardingMerchant.paymentPlans.paymentMethods.terminalCard = element.value;
          }
        });
        this.$emit('save', this.onboardingMerchant);
        setTimeout(() => {
          this.$emit('next');
        }, 500);
      } else {
        this.error = true;
        this.messageError = 'Please select at least one payment method before you can continue.';
      }
    },
    checkbox(type) {
      this.paymentMethods.forEach((element) => {
        if (element.method === type) {
          element.value = !element.value;
        }
      });
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    async getPriceList(priceListId: string) {
      try {
        if (!priceListId) {
          throw new Error('No price list id found');
        }
        this.loading = true;
        this.prices = await this.getPriceListById(priceListId);
        this.loadPaymentMethods(this.prices);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error('Price lists could not load: ', error.stack);
        this.error = true;
        this.messageError =
          'An unexpected error occurred and the price list could not be loaded. Please reload the page.';
        throw error;
      } finally {
        this.loading = false;
      }
    },
    loadPaymentMethods(prices: any) {
      if (!prices || !prices.products) {
        throw new Error('No price list found');
      }
      this.paymentMethods = [];
      prices.products.forEach((product) => {
        if (
          product.feeType === 'paymentMethod' &&
          (product.name === 'paymentMethodDebicheck' ||
            product.name === 'paymentMethodPeachEft' ||
            product.name === 'paymentMethodPeachCard' ||
            product.name === 'paymentMethodPayat')
        ) {
          this.paymentMethods.push({ ...product, value: false });
        }
      });
      const paymentMethod = getTerminalCardProduct(prices.products) as any;
      if (paymentMethod) {
        this.paymentMethods.push({ ...paymentMethod, value: false });
      }
    },
  },
});
