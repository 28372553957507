export default function ({ store, $axios, redirect }) {
  console.log('Setup Axios', store.state.auth);

  // Devmode Setup
  if (process.env.VUE_APP_API_KEY && process.env.DEBUG) {
    // console.log('Api Key found')
    $axios.setHeader('apiKey', process.env.VUE_APP_API_KEY);

    $axios.onError((error) => {
      const code = parseInt(error.response && error.response.status);

      if (code === 401 && process.env.VUE_APP_API_KEY && process.env.DEBUG) {
        redirect('/');
      }
    });
  }
}
