/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import moment from 'moment';
import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index';
import { activeOrders, cancelledOrders, completedOrders, filterDaterange, filterSearch } from '~/libs/filters';
import { IOrder, IOrderShareRequest, Product } from '~/models/orders';
import { IPayment } from '~/models/payment-plans';

export const state = () => {
  return {
    orders: {},
    current: null,
    listBusy: false,
    busy: false,
    filter: {
      daterange: {
        from: moment(),
        to: null,
      },
      state: null,
    },
  };
};

export const getters: GetterTree<any, any> = {
  list(state) {
    return Object.values(state.orders);
  },

  getOrder(state) {
    const orders = state.orders;
    return (id: string) => {
      // eslint-disable-next-line no-prototype-builtins
      if (orders.hasOwnProperty(id)) {
        return _.clone(orders[id]);
      }

      return null;
    };
  },

  filter(state, getters) {
    return (params) => {
      let list = _.clone(getters.list);
      const { daterange, status, search } = params;

      if (daterange) {
        list = filterDaterange(list, 'endDateMax', daterange);
      }

      if (status) {
        list = list.filter((order) => order.state === status);
      }

      if (search) {
        list = filterSearch(list, search);
      }

      return list;
    };
  },

  getPayments(state, getters) {
    return (order: IOrder) => {
      order = getters.getOrder(order._id);

      const { plans } = order;

      if (plans) {
        return plans.reduce((allPayments: IPayment[], plan) => {
          const { payments } = plan;
          if (payments) allPayments = [...allPayments, ...payments];

          return allPayments;
        }, []);
      }
      return [];
    };
  },

  getTotalPaid(state, getters) {
    return (order: IOrder) => {
      let total = 0;
      const payments = getters.getPayments(order);
      if (payments.length > 0) {
        payments
          .filter((payment) => payment.paid)
          .forEach((payment) => {
            total += payment.amount;
          });
      }
      return total;
    };
  },

  getOutstanding(state, getters) {
    return (order: IOrder) => {
      const paid = getters.getTotalPaid(order);
      let outstanding = 0;
      if (order.plans) outstanding = order.plans.reduce((s, p: any) => s + p.amountDue + p.depositDue, 0) - paid;

      return outstanding < 0 ? 0 : outstanding;
    };
  },

  getBenefactor(state, getters) {
    return (order: IOrder) => {
      const { plans } = order;
      if (plans) {
        const { benefactor } = plans[0];
        if (benefactor) return benefactor;
      }
      return {};
    };
  },

  filtered(state, getters) {
    // Date range filter
    return getters.filter(state.filter);
  },

  withPayments(state, getters) {
    return getters.paymentPlans.filter((plan) => plan.payments);
  },

  paymentPlans(state, getters) {
    return getters.filtered.reduce((plans, order) => {
      if (order.plans) {
        plans = [...plans, ...order.plans];
      }
      return plans;
    }, []);
  },
  activeOrders(state, getters) {
    const orders = activeOrders(getters.list);
    const _orders = [] as any;
    return orders.reduce((total, order) => {
      if (order.plans && order.state === 'PLACED') {
        _orders.push(order);
      }
      return _orders;
    }, 0);
    // return activeOrders(getters.list)
  },
  cancelledOrders(state, getters) {
    // const orders = cancelledOrders(getters.list)
    // let _orders = [] as any;
    // return orders.reduce((total, order) => {
    //   if (order.state === 'CANCELLED' && order.initiatorId) {
    //     _orders.push(order);
    //   }
    //   return _orders
    // }, 0)
    return cancelledOrders(getters.list);
  },
  completedOrders(state, getters) {
    return completedOrders(getters.list);
  },

  totalDue(state, getters) {
    const orders = activeOrders(getters.filtered);
    return orders.reduce((total, order) => {
      total += order.amountDue;
      return total;
    }, 0);
  },

  totalAmountForActivePlans(state, getters) {
    const orders = activeOrders(getters.filtered);
    return orders.reduce((total, order) => {
      if (order.plans) {
        order.plans.forEach((plan) => {
          total += plan.amountDueExcludingFee + plan.amountDueExcludingFee;
        });
      }
      return total;
    }, 0);
  },
  currentDueAmount(state, getters) {
    const orders = activeOrders(getters.filtered);
    return orders.reduce((total, order) => {
      if (order.plans) {
        order.plans.forEach((plan) => {
          if (plan.payments) {
            plan.payments.forEach((payment) => {
              if (payment.due) {
                const _day = moment().add(30, 'days').endOf('day');
                if (
                  moment(payment.due).isSameOrAfter(moment()) &&
                  moment(payment.due).endOf('days').isSameOrBefore(_day)
                ) {
                  total += payment.amount;
                }
              }
            });
          }
        });
      }
      return total;
    }, 0);
  },
  completedOrdersAndActiveOrdersValue(state, getters) {
    const orders = completedOrders(getters.filtered);
    const placedOrders = activeOrders(getters.filtered);
    const placedOrdersTotal = placedOrders.reduce((total, order) => {
      if (order.plans) {
        order.plans.forEach((plan) => {
          total += plan.amountDueExcludingFee + plan.amountDueExcludingFee;
        });
      }
      return total;
    }, 0);

    const completedOrdersTotal = orders.reduce((total, order) => {
      if (order.plans) {
        order.plans.forEach((plan) => {
          total += plan.amountDueExcludingFee + plan.amountDueExcludingFee;
        });
      }
      return total;
    }, 0);
    const totalValue = completedOrdersTotal + placedOrdersTotal;
    const totalCount = placedOrders.length + orders.length;
    const averageValue = totalValue / totalCount;
    return averageValue;
  },

  totalOutstanding(state, getters) {
    const orders = activeOrders(getters.filtered);
    return orders.reduce((total, order) => {
      if (order.plans) {
        order.plans.forEach((plan) => {
          total += plan.amountDue;
        });
      }
      return total;
    }, 0);
  },
  activeOrdersLastMonths(state, getters) {
    const orders = activeOrders(getters.list);
    const activePlansArray = [] as any;
    return orders.reduce((total, order) => {
      if (order) {
        if (order.state === 'PLACED') {
          const currentDate = moment().endOf('days');
          const lastMonth = moment().subtract(12, 'months').endOf('days');
          if (moment(order.createdAt).endOf('days').isBetween(lastMonth, currentDate, null, '()')) {
            activePlansArray.push(order);
          }
        }
      }
      return activePlansArray;
    }, 0);
  },
  completedOrdersLastMonths(state, getters) {
    const orders = completedOrders(getters.list);
    const completedPlansArray = [] as any;
    return orders.reduce((total, order) => {
      if (order) {
        if (order.state === 'COMPLETED') {
          const currentDate = moment().endOf('days');
          const lastMonth = moment().subtract(12, 'months').endOf('days');
          if (moment(order.createdAt).endOf('days').isBetween(lastMonth, currentDate, null, '()')) {
            completedPlansArray.push(order);
          }
        }
      }
      return completedPlansArray;
    }, 0);
  },
  cancelledOrdersLastMonths(state, getters) {
    const orders = cancelledOrders(getters.list);
    const cancelledPlansArray = [] as any;
    return orders.reduce((total, order) => {
      if (order) {
        if (order.state === 'CANCELLED') {
          const currentDate = moment().endOf('days');
          const lastMonth = moment().subtract(12, 'months').endOf('days');
          if (moment(order.createdAt).endOf('days').isBetween(lastMonth, currentDate, null, '()')) {
            cancelledPlansArray.push(order);
          }
        }
      }
      return cancelledPlansArray;
    }, 0);
  },
};

export const mutations: MutationTree<any> = {
  listBusy(state, payload) {
    state.listBusy = payload;
  },
  setOrders(state, payload) {
    if (payload) {
      // let orders = _.clone(state.orders)
      // state.orders = _.merge(orders, _.keyBy(payload, (o) => o._id))
      // state.orders = payload;
      state.orders = _.keyBy(payload, (o) => o._id);
    } else {
      state.orders = [];
    }
  },
  setOrder(state, payload) {
    state.orders[payload._id] = payload;
  },
  setDateRange(state, payload) {
    state.filter.daterange = payload;
  },
  setCurrentOrder(state, payload) {
    state.current = payload;
  },
};

export const actions: ActionTree<any, any> = {
  async fetchOrders({ commit }, params) {
    commit('listBusy', true);
    if (params.limit == null || params.limit === 0) params.limit = 0; // Change to 15
    if (params.state === 'ALL') params.state = null;
    // Next line doesn't work, but keep to not break things
    if (params.populate == null && params.populateObject == null)
      params.populate = 'plans,plans.payments,plans.payments.connectorData,plans.benefactor';

    const list = await this.$axios.$get('orders', { params });
    commit('setOrders', list.orders);
    commit('listBusy', false);
  },
  async fetchOrder({ commit }, id) {
    const order = await this.$axios.$get(
      `orders/${id}?populate=plans,plans.payments,plans.payments.connectorData,plans.benefactor`,
    );
    commit('setOrder', order);
    return order;
  },
  async fetchPaymentPlan({ commit }, planId) {
    return await this.$axios.$get(`payment-plans/${planId}`);
  },
  async fetchAllOrders({ commit }, params) {
    if (params.state === 'ALL') delete params.state;
    if (params.populate == null && params.populateObject == null)
      params.populate = 'plans,plans.payments,plans.payments.connectorData,plans.benefactor,supplier';
    const orders = await this.$axios.$get('orders', {
      params,
    });
    commit('setOrders', orders.orders);
    return orders;
  },
  async fetchPayments({ commit }, params) {
    if (params.state === 'MISSED') {
      params.state = 'false';
      params.to = moment().toDate();
      params.failed = null;
    }

    if (params.state === 'FAILED') {
      params.state = null;
    }

    if (params.state === 'SETTLED') {
      params.state = null;
      params.settled = 'SETTLED';
    }

    return await this.$axios.$get(`payments`, {
      params: {
        limit: params.limit,
        paid: params.state,
        toDatePayments: params.to,
        fromDatePayments: params.from,
        type: params.type,
        search: params.search,
        settled: params.settled,
        failed: params.failed,
        sort: params.sort,
        skip: params.skip,
        method: params.method,
        provider: params.provider,
      },
    });
  },
  async fetchPayment({ commit }, _id) {
    return await this.$axios.$get(`payments/${_id}`);
  },
  async makeOfflinePayment({ commit }, payment) {
    return await this.$axios.$post('offline-payments', payment);
  },
  async refundPayment({ commit }, _id) {
    return await this.$axios.$get(`refund-payments/${_id}`);
  },
  async fetchSettlements({ commit }, params?: any) {
    if (params.search) {
      if (!isNaN(+params.search)) {
        params.search = params.search.trim() * 100;
      }
    }

    if (params.to) params.to = moment(params.to).endOf('day').toDate();
    if (params.from) params.from = moment(params.from).startOf('day').toDate();

    return await this.$axios.$get(`settlements`, {
      params: {
        settled: params.settled,
        group: params.group,
        fromDate: params.from,
        toDate: params.to,
        search: params.search,
        reference: params.reference,
      },
    });
  },
  async preview({ commit }, order: IOrder) {
    const data = _.pick(order, [
      'amountDue',
      'depositPerc',
      'depositAmount',
      'depositType',
      'endDateMax',
      'endDateMin',
      'absorbsFee',
      'percFee',
      'flatFee',
      'products',
    ]);
    if (data.depositType !== 'FLAT') {
      delete data.depositAmount;
    }
    data.amountDue = _.toNumber(data.amountDue.toFixed(0));
    const plans = await this.$axios.$post('/payment-plan/preview', data);
    return plans.paymentPlans || {};
  },
  async createOrder({ commit, dispatch }, order: IOrder) {
    if (order.depositType !== 'FLAT') {
      delete order.depositAmount;
    }
    order = await this.$axios.$post('orders', {
      ...order,
      products: [
        <Product>{
          amount: _.toNumber(order.amountDue.toFixed(0)),
          sku: order.name,
          name: order.name,
        },
      ],
    });

    // Also update store
    dispatch('fetchOrders', {});

    commit('setCurrentOrder', order);
    return order;
  },
  async shareOrder({ commit }, data: IOrderShareRequest) {
    return await this.$axios.$post('emails', data);
    // return await this.$axios.$post('orders-send-request', data)
  },
  filterDateRange({ commit }, params) {
    commit('setDateRange', params);
  },
  resetDateFilter({ commit }) {
    commit('setDateRange', { from: null, to: null });
  },
  getUserByID(userID) {
    return this.$axios.get(`${process.env.VUE_APP_API_URL}users?_id=${userID}`);
  },
  // Getting graphs data
  getPaymentPlanGraphsData() {
    return this.$axios.get(`graphs/orders`);
  },
  getPaymentPlanValueData() {
    return this.$axios.get(`graphs/payment-plans`);
  },
  getPaymentsGraphData() {
    return this.$axios.get(`graphs/payments`);
  },
  async getAggregatedGraphData() {
    const ordersByStatus = (await this.$axios.get(`graphs/orders?interval=NONE`)).data;
    const planValue = (await this.$axios.get(`graphs/payment-plans?interval=NONE&state=PLACED&months=60`)).data;
    const paymentsDue = (await this.$axios.get(`graphs/paymentsDue?due=30`)).data;
    const planValueAverage = (
      await this.$axios.get(`graphs/payment-plans?interval=NONE&state=PLACED,CANCELLED,COMPLETED`)
    ).data;
    return { ordersByStatus, planValue, paymentsDue, planValueAverage };
  },
  async updateOrder({ commit }, data: any) {
    const _order = {
      orderCancellationRequest: data.orderCancellationRequest,
      orderRefundRequest: data.orderRefundRequest,
      notifications: data.notifications,
      state: data.state,
      cancellationTerms: data.cancellationTerms,
      groupPaymentPlan: data.groupPaymentPlan,
      completedOrderRefundRequest: data.completedOrderRefundRequest,
      orderRefundFee: data.orderRefundFee,
      orderRefundAmount: data.orderRefundAmount,
    };
    return await this.$axios.put(`orders/${data._id}`, _order);
  },
  async orderAmendment({ commit }, data: any) {
    return await this.$axios.post(`order-amendments`, data);
  },
  async fetchSettlementsById({ commit }, id) {
    const data = await fetch(`${process.env.VUE_APP_API_URL_V2}settlements/${id}?report=true`, {
      credentials: 'include',
    }).then((res) => res.json());
    return data;
  },
};
