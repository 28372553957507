
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import { getTerminalCardProduct, isTerminalCardSelected } from '~/utils/utils';

export default Vue.extend({
  name: 'LayupContract',
  props: {
    merchantName: {
      type: String,
      default: '',
    },
    merchantRegistration: {
      type: String,
      default: '',
    },
    merchantAddress: {
      type: Object as any,
      default() {
        return {} as any;
      },
    },
    settlementStructure: {
      type: Object as any,
      default() {
        return {} as any;
      },
    },
    // eslint-disable-next-line vue/prop-name-casing
    BankDetails: {
      type: Object as any,
      default() {
        return {
          bankName: {} as any,
          account: {} as any,
          branchCode: {} as any,
          accountType: {} as any,
        } as any;
      },
    },
    merchantBankFee: {
      type: Number,
      default: 0,
    },
    adminFee: {
      type: Number,
      default: 0,
    },
    merchantData: {
      type: Object as any,
      default() {
        return {} as any;
      },
    },
  },
  data() {
    return {
      isTerminalCardSelected,
      date: moment().format('DD-MM-YYYY'),
      settlementType: '',
      merchantUser: '',
      merchantUserCellNumber: '',
      merchantAddress1: '',
      account: [] as any,
      adminPercFee: 0,
      bankPercFee: 0,
      successFee: 0,
      accounts: {} as any,
      merchantVatNumber: 0 as any,
      settlementDay: '',
      settlementFrequency: '',
      planTypes: [] as any,
      paymentMethods: [] as any,
      settlement: {} as any,
      paymentTypes: [] as any,
      paymentPlanState: [] as any,
      successAndCancellationFee: {} as any,
      laybyPlanType: {} as any,
      subscriptionPlanType: {} as any,
    };
  },
  computed: {
    ...mapState('auth', ['user', 'merchant', 'priceList']),
  },
  created() {
    this.merchantUser = this.user.name;
    this.merchantUserCellNumber = this.user.cellNumber;
    let address = this.user.merchantOnboarding
      ? this.user.merchantOnboarding.physicalAddress
      : this.merchant.billingAddress;
    const addr = {
      a: address.streetAddress,
      b: address.city,
      c: address.province,
      d: address.country,
      e: address.postal_code,
    };
    if (addr.a)
      address = Object.entries(addr)
        .map((x) => x[1])
        .join(', ');

    this.merchantAddress1 = address;

    const settlement = this.settlementStructure ? this.settlementStructure : ({} as any);
    switch (settlement.settlementType) {
      case 'ALL':
        this.settlementType = 'ALL';
        break;
      case 'COMPLETEDCANCELLED':
        this.settlementType = 'COMPLETED & CANCELLED';
        break;
      case 'DEPOSITCOMPLETEDCANCELLED':
        this.settlementType = 'DEPOSIT, COMPLETED & CANCELLED';
        break;
      case 'DEPOSITCOMPLETED':
        this.settlementType = 'DEPOSIT & COMPLETED';
        break;
      case 'COMPLETED':
        this.settlementType = 'COMPLETED';
        break;
      default:
        break;
    }

    const merchant = this.merchantData as any;

    if (this.priceList && this.priceList.products) {
      this.priceList.products.forEach((product) => {
        if (product.feeType === 'paymentplanType') {
          if (product.method === 'layby') {
            this.laybyPlanType = product;
          }
          if (product.method === 'subscription') {
            this.subscriptionPlanType = product;
          }
        }
        if (product.feeType === 'settlement') {
          this.settlement = product;
        }
        if (product.feeType === 'paymentType') {
          this.paymentTypes.push(product);
        }

        if (product.feeType === 'orderState') {
          this.paymentPlanState.push(product);
          if (this.paymentPlanState && this.paymentPlanState.length > 0) {
            this.successAndCancellationFee = {
              successFee: this.paymentPlanState.filter((plan) => plan.method.toLowerCase() === 'completed')[0]
                ? this.paymentPlanState.filter((plan) => plan.method.toLowerCase() === 'completed')[0].flat
                : 0,
              successPerc: this.paymentPlanState.filter((plan) => plan.method.toLowerCase() === 'completed')[0]
                ? this.paymentPlanState.filter((plan) => plan.method.toLowerCase() === 'completed')[0].percentage
                : 0,
              cancellationFee: this.paymentPlanState.filter((plan) => plan.method.toLowerCase() === 'cancelled')[0]
                ? this.paymentPlanState.filter((plan) => plan.method.toLowerCase() === 'cancelled')[0].flat
                : 0,
              cancellationPerc: this.paymentPlanState.filter((plan) => plan.method.toLowerCase() === 'cancelled')[0]
                ? this.paymentPlanState.filter((plan) => plan.method.toLowerCase() === 'cancelled')[0].percentage
                : 0,
            };
          }
        }

        if (product.feeType === 'accountType') {
          this.accounts = product;
        }

        if (
          (product.feeType === 'paymentMethod' && product.name === 'paymentMethodDebicheck') ||
          product.name === 'paymentMethodPeachEft' ||
          product.name === 'paymentMethodPeachCard' ||
          product.name === 'paymentMethodPayat'
        ) {
          if (merchant && merchant.paymentPlans && merchant.paymentPlans.paymentMethods) {
            if (merchant.paymentPlans.paymentMethods.eft && product.method === 'peachEft') {
              this.paymentMethods.push(product);
            }
            if (merchant.paymentPlans.paymentMethods.debiCheck && product.method === 'debicheck') {
              this.paymentMethods.push(product);
            }
            if (merchant.paymentPlans.paymentMethods.payAt && product.method === 'payat') {
              this.paymentMethods.push(product);
            }
            if (
              merchant.paymentPlans.paymentMethods.creditOrDebitCard &&
              (product.method === 'card' || product.method === 'peachCard')
            ) {
              this.paymentMethods.push(product);
            }
          }
        }
      });
      if (merchant.paymentPlans.paymentMethods.terminalCard) {
        const paymentMethod = getTerminalCardProduct(this.priceList.products);
        if (paymentMethod) {
          this.paymentMethods.push(paymentMethod);
        }
      }
    }
    this.loadMerchantAccounts();
    this.organiseBillingAndSettlement(this.merchant);
  },
  mounted() {},
  async activated() {
    await this.loadMerchantAccounts();
    this.organiseBillingAndSettlement(this.merchant);
  },
  methods: {
    ...mapActions({ getMerchantAccounts: 'auth/getMerchantAccounts' }),
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    async loadMerchantAccounts() {
      const data = this.merchant ? this.merchant : ({} as any);
      if (data) this.merchantVatNumber = data.vatNo | 0;
      await this.getMerchantAccounts()
        .then((result) => {
          if (result)
            // eslint-disable-next-line array-callback-return
            this.account = result.filter((account: any) => {
              if (data.accountType === account.name) return account;
            });
          if (this.account && this.account[0] != null) {
            this.adminPercFee = this.account[0].adminFeePerc;
            this.bankPercFee = this.account[0].bankFeePerc;
            this.successFee = this.account[0].successFee;
            // this.accounts = this.account[0]
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    organiseBillingAndSettlement(merchantRecord: any) {
      if (merchantRecord) {
        this.settlementFrequency = merchantRecord.settlementFrequency;

        switch (merchantRecord.settlementFrequency) {
          case 'DAILY': {
            this.settlementDay = 'DAILY';
            break;
          }
          case 'MONTHLY': {
            if (merchantRecord.settlementDay === 1) {
              this.settlementDay = '1st';
            }
            if (merchantRecord.settlementDay === 2) {
              this.settlementDay = '2nd';
            }
            if (merchantRecord.settlementDay === 3) {
              this.settlementDay = '3rd';
            }
            if (merchantRecord.settlementDay === 4) {
              this.settlementDay = '4th';
            }
            if (merchantRecord.settlementDay === 5) {
              this.settlementDay = '5th';
            }
            if (merchantRecord.settlementDay === 6) {
              this.settlementDay = '6th';
            }
            if (merchantRecord.settlementDay === 7) {
              this.settlementDay = '7th';
            }
            if (merchantRecord.settlementDay === 8) {
              this.settlementDay = '8th';
            }
            if (merchantRecord.settlementDay === 9) {
              this.settlementDay = '9th';
            }
            if (merchantRecord.settlementDay === 10) {
              this.settlementDay = '10th';
            }
            if (merchantRecord.settlementDay === 11) {
              this.settlementDay = '11th`';
            }
            if (merchantRecord.settlementDay === 12) {
              this.settlementDay = '12th';
            }
            if (merchantRecord.settlementDay === 13) {
              this.settlementDay = '13th';
            }
            if (merchantRecord.settlementDay === 14) {
              this.settlementDay = '14th';
            }
            if (merchantRecord.settlementDay === 15) {
              this.settlementDay = '15th';
            }
            if (merchantRecord.settlementDay === 16) {
              this.settlementDay = '16th';
            }
            if (merchantRecord.settlementDay === 17) {
              this.settlementDay = '17th';
            }
            if (merchantRecord.settlementDay === 18) {
              this.settlementDay = '18th';
            }
            if (merchantRecord.settlementDay === 19) {
              this.settlementDay = '19th';
            }
            if (merchantRecord.settlementDay === 20) {
              this.settlementDay = '20th';
            }
            if (merchantRecord.settlementDay === 21) {
              this.settlementDay = '21st';
            }
            if (merchantRecord.settlementDay === 22) {
              this.settlementDay = '22nd';
            }
            if (merchantRecord.settlementDay === 23) {
              this.settlementDay = '23rd';
            }
            if (merchantRecord.settlementDay === 24) {
              this.settlementDay = '24th';
            }
            if (merchantRecord.settlementDay === 25) {
              this.settlementDay = '25th';
            }
            if (merchantRecord.settlementDay === 26) {
              this.settlementDay = '26th';
            }
            if (merchantRecord.settlementDay === 27) {
              this.settlementDay = '27th';
            }
            if (merchantRecord.settlementDay === 28) {
              this.settlementDay = '28th';
            }
            if (merchantRecord.settlementDay === 29) {
              this.settlementDay = '29th';
            }
            if (merchantRecord.settlementDay === 30) {
              this.settlementDay = '30th';
            }
            if (merchantRecord.settlementDay === 31) {
              this.settlementDay = '31st';
            }
            break;
          }
          case 'WEEKLY': {
            if (merchantRecord.settlementDay === 1) {
              this.settlementDay = 'SUNDAY';
            }
            if (merchantRecord.settlementDay === 2) {
              this.settlementDay = 'MONDAY';
            }
            if (merchantRecord.settlementDay === 3) {
              this.settlementDay = 'TUESDAY';
            }
            if (merchantRecord.settlementDay === 4) {
              this.settlementDay = 'WEDNESDAY';
            }
            if (merchantRecord.settlementDay === 5) {
              this.settlementDay = 'THURSDAY';
            }
            if (merchantRecord.settlementDay === 6) {
              this.settlementDay = 'FRIDAY';
            }
            if (merchantRecord.settlementDay === 7) {
              this.settlementDay = 'SATURDADY';
            }
            break;
          }
          default:
            break;
        }
      }
    },
    convertCurrency(amount, modifier = 100) {
      if (amount == null) return '';
      const amountReg = amount.toString().match(/[\d.]+/);
      if (amountReg) {
        amount = amountReg[0];
      } else {
        amount = '';
      }

      return new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
      }).format(amount / modifier);
    },
  },
});
