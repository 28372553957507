
import { Component, Prop, Watch } from 'nuxt-property-decorator';
import { ValidationProvider } from 'vee-validate';
import Vue from 'vue';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class VInput extends Vue {
  @Prop({ default: '' }) value: any;
  @Prop({}) rules: String;
  @Prop({}) vid: String;
  @Prop({ default: false, required: false }) error: Boolean;
  // @ts-ignore
  innerValue: any = this.value;

  @Watch('innerValue')
  onInnerValueChange(val) {
    this.$emit('input', val);
  }

  @Watch('value')
  onValueChange(val) {
    this.innerValue = val;
  }
}
