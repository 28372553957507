
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import * as _ from 'lodash';
import ContractChangeRequestForm from './ContractChangeRequestForm.vue';

export default Vue.extend({
  name: 'SettlementStructure',
  components: {
    ContractChangeRequestForm,
  },
  props: {
    onboarding: {
      type: Object as any,
      default() {
        return {} as any;
      },
    },
  },
  data() {
    return {
      formMerchant: {} as any,
      settlementDay: '',
      settlementType: '',
      settlementFrequency: '',
      transactionFee: 0,
      tooltipMessage: '',
      tooltipMessageFre: '',
      tooltipMessageDay: '',
      tooltipMessageFee: 'The total fees, as a percentage, incurred on every successful payment.',
      account: [] as any,
      NoOfUsers: 0,
      monthlyFee: 0,
    };
  },
  computed: {
    ...mapState('auth', ['merchant', 'user', 'allMerchantAccounts']),
  },
  watch: {
    onboarding(newVal) {
      this.formMerchant = _.merge(this.formMerchant, newVal);
      this.$root.$emit('updateSettlement');
    },
  },
  async created() {
    if (!_.isEmpty(this.merchant)) {
      this.formMerchant = _.merge(this.formMerchant, this.merchant);
    } else {
      this.formMerchant = _.merge(this.formMerchant, this.onboarding);
    }
    if (this.formMerchant) await this.organiseBillingAndSettlement(this.formMerchant);

    this.$root.$on('updateSettlement', async () => {
      await this.organiseBillingAndSettlement(this.formMerchant);
      await this.loadMerchantAccounts();
    });
    await this.loadMerchantAccounts();
  },
  async activated() {
    if (this.formMerchant) await this.organiseBillingAndSettlement(this.formMerchant);
    await this.loadMerchantAccounts();
  },
  methods: {
    ...mapActions({ getMerchantAccounts: 'auth/getMerchantAccounts' }),
    ...mapGetters({ getMerchantAccount: 'auth/merchant' }),
    loadMerchantAccounts() {
      if (this.allMerchantAccounts && !_.isEmpty(this.formMerchant)) {
        // eslint-disable-next-line array-callback-return
        this.account = this.allMerchantAccounts.filter((account) => {
          if (account.name === this.formMerchant.accountType) return account;
        });
        if (!_.isEmpty(this.account)) {
          // this.NoOfUsers = this.account[0].NoOfUsers
          this.monthlyFee = this.account[0].amount;
        }
      }
      if (this.formMerchant && this.formMerchant.accountSettings != null)
        this.NoOfUsers = this.formMerchant.accountSettings.numberOfUsers;
    },
    isEmpty(obj) {
      return _.isEmpty(obj);
    },
    changeRequest() {
      this.$modal.show(
        ContractChangeRequestForm,
        { merchantData: this.formMerchant },
        {
          scrollable: true,
          height: 'auto',
          adaptive: true,
        },
      );
    },
    nextToContract() {
      this.$emit('next:toContract', this.formMerchant);
    },
    skipToDashboard() {
      this.$emit('dashboard');
    },
    organiseBillingAndSettlement(merchantRecord: any) {
      this.settlementFrequency = merchantRecord.settlementFrequency;
      this.transactionFee = merchantRecord.percFee;
      switch (merchantRecord.settlementType) {
        case 'ALL':
          this.settlementType = 'ALL';
          this.tooltipMessage = 'ALL payments for ALL payments plans';
          break;
        case 'COMPLETEDCANCELLED':
          this.settlementType = 'COMPLETED & CANCELLED';
          this.tooltipMessage = 'Payments relating to COMPLETED & CANCELLED payment plans';
          break;
        case 'DEPOSITCOMPLETEDCANCELLED':
          this.settlementType = 'DEPOSIT, COMPLETED & CANCELLED';
          this.tooltipMessage = `All DEPOSIT payments received and the balance of payments
                    related to COMPLETED & CANCELLED payment plans`;
          break;
        case 'DEPOSITCOMPLETED':
          this.settlementType = 'DEPOSIT & COMPLETED';
          this.tooltipMessage = `All DEPOSIT payments received and and the balance of
                    payments related to COMPLETED payment plans`;
          break;
        case 'COMPLETED':
          this.settlementType = 'COMPLETED';
          this.tooltipMessage = 'Payments for COMPLETED payment plans only';
          break;
        default:
          break;
      }

      switch (merchantRecord.settlementFrequency) {
        case 'DAILY': {
          this.settlementDay = 'DAILY';
          this.tooltipMessageFre = 'Settlements processed daily.';
          this.tooltipMessageDay = 'N/A';
          break;
        }
        case 'MONTHLY': {
          this.tooltipMessageDay = `This value will determine the day of the month
                    on which your settlement will occur e.g. 1 - 31 May`;
          this.tooltipMessageFre = `Settlements will be processed every month on the same
                    day, determined by the value you select below.`;
          if (merchantRecord.settlementDay === 1) {
            this.settlementDay = '1st';
          }
          if (merchantRecord.settlementDay === 2) {
            this.settlementDay = '2nd';
          }
          if (merchantRecord.settlementDay === 3) {
            this.settlementDay = '3rd';
          }
          if (merchantRecord.settlementDay === 4) {
            this.settlementDay = '4th';
          }
          if (merchantRecord.settlementDay === 5) {
            this.settlementDay = '5th';
          }
          if (merchantRecord.settlementDay === 6) {
            this.settlementDay = '6th';
          }
          if (merchantRecord.settlementDay === 7) {
            this.settlementDay = '7th';
          }
          if (merchantRecord.settlementDay === 8) {
            this.settlementDay = '8th';
          }
          if (merchantRecord.settlementDay === 9) {
            this.settlementDay = '9th';
          }
          if (merchantRecord.settlementDay === 10) {
            this.settlementDay = '10th';
          }
          if (merchantRecord.settlementDay === 11) {
            this.settlementDay = '11th`';
          }
          if (merchantRecord.settlementDay === 12) {
            this.settlementDay = '12th';
          }
          if (merchantRecord.settlementDay === 13) {
            this.settlementDay = '13th';
          }
          if (merchantRecord.settlementDay === 14) {
            this.settlementDay = '14th';
          }
          if (merchantRecord.settlementDay === 15) {
            this.settlementDay = '15th';
          }
          if (merchantRecord.settlementDay === 16) {
            this.settlementDay = '16th';
          }
          if (merchantRecord.settlementDay === 17) {
            this.settlementDay = '17th';
          }
          if (merchantRecord.settlementDay === 18) {
            this.settlementDay = '18th';
          }
          if (merchantRecord.settlementDay === 19) {
            this.settlementDay = '19th';
          }
          if (merchantRecord.settlementDay === 20) {
            this.settlementDay = '20th';
          }
          if (merchantRecord.settlementDay === 21) {
            this.settlementDay = '21st';
          }
          if (merchantRecord.settlementDay === 22) {
            this.settlementDay = '22nd';
          }
          if (merchantRecord.settlementDay === 23) {
            this.settlementDay = '23rd';
          }
          if (merchantRecord.settlementDay === 24) {
            this.settlementDay = '24th';
          }
          if (merchantRecord.settlementDay === 25) {
            this.settlementDay = '25th';
          }
          if (merchantRecord.settlementDay === 26) {
            this.settlementDay = '26th';
          }
          if (merchantRecord.settlementDay === 27) {
            this.settlementDay = '27th';
          }
          if (merchantRecord.settlementDay === 28) {
            this.settlementDay = '28th';
          }
          if (merchantRecord.settlementDay === 29) {
            this.settlementDay = '29th';
          }
          if (merchantRecord.settlementDay === 30) {
            this.settlementDay = '30th';
          }
          if (merchantRecord.settlementDay === 31) {
            this.settlementDay = '31st';
          }
          break;
        }
        case 'WEEKLY': {
          this.tooltipMessageFre = `Settlements will be processed every week on
                    the same day determined by the value you select below.`;

          this.tooltipMessageDay = `This value (1 - 7) will determine the day of the week
                    on which your settlement will occur e.g. 1 = Sunday, 2 = Monday etc.`;
          if (merchantRecord.settlementDay === 1) {
            this.settlementDay = 'SUNDAY';
          }
          if (merchantRecord.settlementDay === 2) {
            this.settlementDay = 'MONDAY';
          }
          if (merchantRecord.settlementDay === 3) {
            this.settlementDay = 'TUESDAY';
          }
          if (merchantRecord.settlementDay === 4) {
            this.settlementDay = 'WEDNESDAY';
          }
          if (merchantRecord.settlementDay === 5) {
            this.settlementDay = 'THURSDAY';
          }
          if (merchantRecord.settlementDay === 6) {
            this.settlementDay = 'FRIDAY';
          }
          if (merchantRecord.settlementDay === 7) {
            this.settlementDay = 'SATURDADY';
          }
          break;
        }
        default:
          break;
      }
    },
  },
});
