import { ActionTree, MutationTree, GetterTree } from 'vuex/types/index';

export const state = () => {
  return {
    payment: {},
  };
};

export const getters: GetterTree<any, any> = {
  getPayment(state) {
    const payment = state.payment;
    return payment;
  },
};

export const mutations: MutationTree<any> = {
  setPayment(state, payload) {
    state.payment = payload;
  },
};

export const actions: ActionTree<any, any> = {
  async generateRefund({ commit }, payment: any) {
    const result = await this.$axios.$post('generate-refund', payment);
    return result;
  },

  async manualRefund({ commit }, payment: any) {
    const result = await this.$axios.$post('manual-refund', payment);
    return result;
  },

  async realtimeRefund({ commit }, payment: any) {
    const result = await this.$axios.$post('realtime-refund', payment);
    return result;
  },
};
