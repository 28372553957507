/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTree } from 'vuex';
import { IMerchant, IUser } from '~/models/auth';

interface AuthState {
  user: IUser;
  merchant: IMerchant;
  authorized: boolean;
  busy: boolean;
  allMerchantAccounts?: any;
  priceList?: any;
}

export const state = () => {
  return <AuthState>{
    authorized: false,
    busy: false,
    merchant: {
      bankDetailsValidation: {
        bankName: {
          verified: false,
        },
        account: {
          verified: false,
        },
        accountType: {
          verified: false,
        },
        branchCode: {
          verified: false,
        },
        idNumber: {
          verified: false,
        },
        clientName: {
          verified: false,
        },
        cellNumber: {
          verified: false,
        },
        initials: {
          verified: false,
        },
        email: {
          verified: false,
        },
      },
      address: {},
    },
  };
};

export const getters = {
  clientUrl() {
    return process.env.CLIENT_URL;
  },
  merchant(state) {
    return state.merchant;
  },
  merchantAccounts(state) {
    return state.allMerchantAccounts;
  },
};

export const mutations = {
  setUser(state, user: IUser) {
    state.user = user;
  },
  setMerchant(state, merchant) {
    state.merchant = merchant;
  },
  // State related
  setAuthorized(state, authed: boolean) {
    state.authorized = authed;
  },
  setBusy(state, busy: boolean) {
    state.busy = busy;
  },
  setMerchantAccounts(state, allMerchantAccounts) {
    state.allMerchantAccounts = allMerchantAccounts;
  },
  setPriceList(state, priceList) {
    state.priceList = priceList;
  },
  reset(state) {
    state = <AuthState>{
      authorized: false,
      busy: false,
      merchant: {},
    };
  },
};

export const actions: ActionTree<any, any> = {
  setUser({ commit }, user) {
    commit('setUser', user);
  },
  async tryLogin({ commit, dispatch }) {
    try {
      commit('setBusy', true);
      const me = await this.$axios.$get('auth/me');
      if (me.role == null || me.role !== 'MERCHANT') {
        commit('setMerchant', null);
        commit('setUser', null);
        commit('reset');
        throw new Error('User must be MERCHANT');
      }
      commit('setUser', me);
      commit('setAuthorized', true);
      if (me.merchantId) dispatch('fetchMerchant', me.merchantId);
    } catch (error) {
      commit('setAuthorized', false);
      commit('setMerchant', null);
      commit('setUser', null);
      commit('reset');
      commit('setBusy', false);

      return false;
    }
    commit('setBusy', false);

    return true;
  },

  async fetchMerchant({ commit }, merchantId) {
    const merchant = await this.$axios.$get(`merchants/${merchantId}`);
    commit('setMerchant', merchant);
    return merchant;
  },

  async saveMerchant({ commit }, merchant) {
    merchant = await this.$axios.$put(`merchants/${merchant._id}`, merchant);
    commit('setMerchant', merchant);
  },

  async saveUser({ commit }, user) {
    if (!user.redirectTo) {
      user.redirectTo = `${process.env.MERCHANT_URL}/dashboard/profile`;
    }
    if (user.password === '') delete user.password;
    user = await this.$axios.$put(`users/${user._id}`, user);
    commit('setUser', user);
  },

  async login(context, params) {
    try {
      context.commit('reset');
      context.commit('setBusy', true);

      const response = await this.$axios.$post('auth/login', {
        username: params.username,
        password: params.password,
      });

      if (!response?.twoFactorAuthenticationRequired) {
        context.dispatch('tryLogin');
      }
      return response;
    } catch (error) {
      context.commit('setBusy', false);
      throw error;
    }
  },

  async logout({ commit }) {
    commit('setMerchant', null);
    commit('setUser', null);
    commit('reset');
    await localStorage.clear();
    await this.$axios.$get('auth/logout');
    return commit('reset');
  },
  async AddNewUser({ commit }, user: any) {
    const payload = user;
    if (payload.username === '') delete payload.username;
    if (payload.email === '') delete payload.email;
    if (payload.cellNumber === '') delete payload.cellNumber;
    if (payload.name === '') delete payload.name;
    if (payload.password === '') delete payload.password;
    if (payload.email != null) payload.email = payload.email.toLowerCase();
    return await this.$axios.$post('/users', payload);
  },
  async Register({ commit }, user: any) {
    // const payload = <any>pick([
    //   'name', 'username', 'password', 'email', 'cellNumber', 'shouldWelcome', 'role',
    // ], user);
    const payload = user;
    if (payload.username === '') delete payload.username;
    if (payload.email === '') delete payload.email;
    if (payload.cellNumber === '') delete payload.cellNumber;
    if (payload.name === '') delete payload.name;
    if (payload.password === '') delete payload.password;
    const redirectTo = `${process.env.MERCHANT_URL}/email-verification-success`;
    if (redirectTo) {
      payload.redirectTo = redirectTo;
    }
    if (payload.email != null) payload.email = payload.email.toLowerCase();
    return await this.$axios.$post('/auth/register', payload);
  },
  async getUsersByMerchant({ commit }) {
    return await this.$axios.$get(`/users`);
  },
  async getUser({ commit }, id: string) {
    return await this.$axios.$get(`/users/${id}`);
  },
  async updateMerchantUser({ commit }, user) {
    return await this.$axios.$put(`/users/${user._id}`, user);
  },
  async deleteMerchantUser({ commit }, _id) {
    return await this.$axios.$delete(`/users/${_id}`);
  },
  async sendEmailVerification({ commit }, data: any) {
    return await this.$axios.$post('/auth/email-verification', data);
  },
  async sendMerchantSupportAndContactTicket({ commit }, details: any) {
    return await this.$axios.$post('/emails', { data: details });
  },
  async verify({ commit }, vUser) {
    const cellNumber = encodeURIComponent(vUser.cellNumber);
    const { data } = await this.$axios.get(`/verify?token=${vUser.otp}&msisdn=${cellNumber}`);
    return data;
  },
  async twoFactorAuthenticationVerification({ commit }, otp: string) {
    const { data } = await this.$axios.get(`/verify?token=${otp}`);
    return data;
  },
  // For merchant onboarding
  async onboardingNewMerchant({ commit }, merchant: any) {
    return await this.$axios.$post('/merchants', merchant);
  },

  // Sign contract
  async signContract({ commit }, data: any) {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    };

    return await this.$axios.$post('/sign-merchant-contract', data, { headers });
  },

  // validate bank details
  async validateBankDetails({ commit }, id: string) {
    return await this.$axios.get(`/merchants/bankDetailsValidation/${id}`);
  },

  async downloadContract({ commit }, data: any) {
    return await this.$axios.$post('/sign-merchant-contract', data, { responseType: 'blob' });
  },

  async getMerchantAccounts({ commit }) {
    const allMerchantAccounts = await this.$axios.$get('/merchant-accounts');
    commit('setMerchantAccounts', allMerchantAccounts);
    return allMerchantAccounts;
  },
  async sendStatement({ commit }, orderData: any) {
    const { data } = await this.$axios.post('/statement', {
      orderId: orderData.orderId,
      type: 'email',
      email: orderData.email,
      paymentPlanId: orderData.paymentPlanId,
    });
    return data;
  },
  async DownloadSettlementReport({ commit }, url: any) {
    const { data: s3SignedUrl } = await this.$axios({
      url: `${process.env.VUE_APP_API_URL_V2}${url}`,
      method: 'GET',
      withCredentials: true,
      maxRedirects: 0,
    });
    return this.$axios({
      url: s3SignedUrl,
      method: 'GET',
      responseType: 'blob',
      withCredentials: false,
    });
  },
  async getPriceListById({ commit }, id: string) {
    const { data } = await this.$axios.get(`/pricelists/${id}`);
    commit('setPriceList', data);
    return data;
  },
  async generateTerminalKey({ commit }) {
    const response = await this.$axios.$get('/auth/terminal-key');
    return response.terminalKey;
  },
  async generateApiKey({ commit }) {
    const response = await this.$axios.$get('/auth/apikey');
    return response.apikey;
  },
};
