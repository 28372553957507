import { ActionTree, MutationTree, GetterTree } from 'vuex/types/index';
import type { IInvoice } from './types';

export const state = () => {
  return {
    invoices: [] as Partial<IInvoice>[],
    invoiceReport: {} as any,
    invoicePdfLocation: String,
  };
};

export const getters: GetterTree<any, any> = {
  getInvoices(state) {
    const invoices = state.invoices;
    return invoices;
  },
  getInvoiceReport(state) {
    const invoiceReport = state.invoiceReport;
    return invoiceReport;
  },
  getInvoicePdfLocation(state) {
    return state.invoicePdfLocation;
  },
};

export const mutations: MutationTree<any> = {
  setInvoices(state, invoices: IInvoice[]) {
    state.invoices = invoices;
  },
  setInvoiceReport(state, invoiceReport: any) {
    state.invoiceReport = invoiceReport;
  },
  setInvoicePdfLocation(state, invoicePdfLocation: string) {
    state.invoicePdfLocation = invoicePdfLocation;
  },
};

export const actions: ActionTree<any, any> = {
  async fetchInvoices({ commit }, filters?: { from?: string; to?: string; reference?: string; status?: string }) {
    const response: { invoices: IInvoice[] } = await this.$axios.$get('invoices', {
      params: {
        reference: filters?.reference?.toUpperCase(),
        status: filters?.status,
        to: filters?.to,
        from: filters?.from,
      },
    });
    const invoices: IInvoice[] = response.invoices;

    commit('setInvoices', invoices);
    return invoices;
  },

  async getInvoiceReport({ commit }, invoiceId: string) {
    const response: { report: IInvoice } = await this.$axios.$get(`invoices/${invoiceId}/report`);
    commit('setInvoiceReport', response.report);
    return response.report;
  },

  async downloadInvoicePdf({ commit }, invoiceId: string) {
    const response = await this.$axios.$get(`invoices/${invoiceId}/pdf`);
    commit('setInvoicePdfLocation', response.report);
    return response;
  },
};
