
import Vue from 'vue';
import { Action, Component, Emit, Prop, State, Watch } from 'nuxt-property-decorator';
import { ValidationObserver } from 'vee-validate';
import moment from 'moment';
import * as _ from 'lodash';
import { currency } from '../../plugins/filters';
import VInput from '~/components/Form/VInput.vue';
import VTextarea from '~/components/Form/VTextarea.vue';
import ToggleInput from '~/components/Form/ToggleInput.vue';
import VFile from '~/components/Form/VFile.vue';
import VCurrency from '~/components/Form/VCurrency.vue';
import VPercentage from '~/components/Form/VPercentage.vue';
import VDatepicker from '~/components/Form/VDatepicker.vue';
import { IOrder, generateOrder } from '~/models/orders';
import { DepositType } from '~/pages/dashboard/settings-models/types';

@Component({
  components: {
    VInput,
    VTextarea,
    ToggleInput,
    VFile,
    VCurrency,
    VPercentage,
    VDatepicker,
    ValidationObserver,
  },
})
export default class CreateOrder extends Vue {
  @State((state) => state.auth.merchant) merchant: any;
  @State((state) => state.auth.user) user: any;
  @Action('orders/preview') previewPlan;
  @Prop({ default: () => {} }) duplicateOrder!: IOrder;

  order: IOrder = generateOrder();
  paymentMethods = {
    eft: false,
    creditOrDebitCard: false,
    debiCheck: false,
  };

  customFeatures = {
    logo: {
      enabled: false,
      value: '',
    },
    imageUrl: {
      enabled: false,
      value: '',
    },
    paymentWidget: {
      enabled: false,
      value: '',
    },
  };

  get feeToCustomer() {
    return !this.order.absorbsFee;
  }

  set feeToCustomer(val) {
    this.order.absorbsFee = !val;
  }

  orderExpiryOptions = [
    {
      value: 'MINUTES',
      key: 'Minute(s)',
    },
    {
      value: 'HOURS',
      key: 'Hour(s)',
    },
    {
      value: 'DAYS',
      key: 'Day(s)',
    },
  ];

  selectedValue = '';
  addedTimeValue: number = 0;
  paymentPlans: any = null;
  reviewNext = false;
  first = true;
  oldTotal = this.order.amountDue;
  endDate = this.order.endDateMax;
  count = 0;
  change = 0;
  toReturn = false;
  canRecalculatePlan = false;

  columnSize = 12;
  minDateError = false;
  minDate = '';
  maxDate = '';
  maxDateError = false;
  required = false;

  created() {
    if (this.merchant.imageUrl) this.order.imageUrl = this.merchant.imageUrl;
    if (this.merchant.percFee) this.order.percFee = this.merchant.percFee;

    if (this.merchant.paymentPlans.defaultDepositType.enabled) {
      if (this.merchant.paymentPlans.defaultDepositType.depositType === DepositType.Instalment) {
        this.order.depositType = DepositType.Instalment;
      }
      if (this.merchant.paymentPlans.defaultDepositType.depositType === DepositType.Percentage) {
        this.order.depositType = DepositType.Percentage;
        this.order.depositPerc = this.merchant.paymentPlans.defaultDepositType.percentage;
      }

      if (this.merchant.paymentPlans.defaultDepositType.depositType === DepositType.Flat) {
        this.order.depositType = DepositType.Flat;
        this.order.depositAmount = this.merchant.paymentPlans.defaultDepositType.flat;
      }
    }

    if (this.merchant.paymentPlans?.suspendOnCancel) {
      this.order.suspendOnCancel = this.merchant.paymentPlans.suspendOnCancel;
    }

    if (
      !this.merchant.paymentPlans.minMonths.enabled &&
      this.merchant.paymentPlans.maxMonths.enabled &&
      this.merchant.paymentPlans.maxMonths.value > 0
    ) {
      const maxDate = moment()
        .add(this.merchant.paymentPlans.maxMonths.value, 'month')
        .endOf('month')
        .format('YYYY-MM-DD');
      this.order.endDateMax = maxDate;
    }

    if (this.merchant.paymentPlans.minMonths.enabled && this.merchant.paymentPlans.minMonths.value > 0) {
      const minDate = moment()
        .add(this.merchant.paymentPlans.minMonths.value, 'month')
        .endOf('month')
        .format('YYYY-MM-DD');
      this.order.endDateMax = minDate;
    }

    if (this.duplicateOrder) {
      const _order = _.pick(this.duplicateOrder, [
        'reference',
        'name',
        'depositAmount',
        'amountDue',
        'depositType',
        'absorbsFee',
        'endDateMax',
        'imageUrl',
        'percFee',
        'depositPerc',
        'endDateMin',
      ]);
      this.order = _.merge(this.order, _order);
      this.canRecalculatePlan = true;
    }
  }

  @Emit('created:order')
  createOrder() {
    this.order.paymentMethods = this.paymentMethods;
    this.order.customFeatures = this.customFeatures;
    if (this.merchant.imageUrl) this.order.imageUrl = this.merchant.imageUrl;
    // Check if date is valid and only then adjust timezone to prevent any interruption with existing error handling.
    if (moment(this.order.endDateMax).isValid()) {
      // Add 12 hours when the API eventually converts this into UTC, it will calculate the maximum payment plan inclusive of selecting the 28th.
      this.order.endDateMax = moment(this.order.endDateMax).add(12, 'hours').toISOString();
    }
    return this.order;
  }

  nextToReview() {
    this.required = false;
    if (
      this.order &&
      (this.order.name === '' || this.order.reference === '' || this.order.name == null || this.order.reference == null)
    ) {
      this.required = true;
    } else {
      this.reviewNext = false;
      this.$emit('next:toReview', this.order);
    }
  }

  reset() {
    this.reviewNext = false;
  }

  checkExpireSelections() {
    if (this.selectedValue === 'MINUTES') {
      if (this.addedTimeValue >= 0 && this.addedTimeValue <= 86400) {
        this.order.expire = parseInt(this.addedTimeValue + '');
        return true;
      }
    }
    if (this.selectedValue === 'DAYS') {
      if (this.addedTimeValue >= 0 && this.addedTimeValue <= 60) {
        const hours = this.addedTimeValue * 24;
        const mins = hours * 60;
        this.order.expire = mins;
        return true;
      }
    }
    if (this.selectedValue === 'HOURS') {
      if (this.addedTimeValue >= 0 && this.addedTimeValue <= 1440) {
        const mins = this.addedTimeValue * 60;
        this.order.expire = mins;
        return true;
      }
    }
    return false;
  }

  checkTimeType() {
    if (this.addedTimeValue && !this.selectedValue) {
      return false;
    }
    if (!this.addedTimeValue && this.selectedValue) {
      return false;
    }
    if (this.selectedValue && this.addedTimeValue) {
      if (this.selectedValue === 'MINUTES') {
        if (this.addedTimeValue >= 0 && this.addedTimeValue <= 86400) {
          this.order.expire = parseInt(this.addedTimeValue + '');
        } else {
          Vue.$toast.error('Alert!', {
            position: 'top',
            message: 'Minutes cannot less than 0 or exceed 86400.',
          });
          this.addedTimeValue = 0;
          return false;
        }
      }
      if (this.selectedValue === 'DAYS') {
        if (this.addedTimeValue >= 0 && this.addedTimeValue <= 60) {
          const hours = this.addedTimeValue * 24;
          const mins = hours * 60;
          this.order.expire = mins;
        } else {
          Vue.$toast.error('Alert!', {
            position: 'top',
            message: 'Days cannot be less than 0 or exceed 60.',
          });
          this.addedTimeValue = 0;
          return false;
        }
      }
      if (this.selectedValue === 'HOURS') {
        if (this.addedTimeValue >= 0 && this.addedTimeValue <= 1440) {
          const mins = this.addedTimeValue * 60;
          this.order.expire = mins;
        } else {
          Vue.$toast.error('Alert!', {
            position: 'top',
            message: 'Hours cannot be less than 0 or exceed 1440.',
          });
          this.addedTimeValue = 0;
          return false;
        }
      }
    }
    return true;
  }

  disabledDates(date) {
    return moment(date).format('x') < moment().format('x');
  }

  async checkDates() {
    this.minDate =
      this.merchant.paymentPlans.minMonths.enabled && this.merchant.paymentPlans.minMonths.value > 0
        ? moment().add(this.merchant.paymentPlans.minMonths.value, 'months').endOf('day').format('YYYY-MM-DD')
        : moment().add(1, 'months').endOf('day').format('YYYY-MM-DD');
    this.maxDate =
      this.merchant.paymentPlans.maxMonths.enabled && this.merchant.paymentPlans.maxMonths.value > 0
        ? moment().add(this.merchant.paymentPlans.maxMonths.value, 'months').endOf('day').format('YYYY-MM-DD')
        : '';

    if (this.merchant.paymentPlans.maxMonths.enabled) {
      if (
        moment(this.order.endDateMax).endOf('day').format('x') >
        moment().add(this.merchant.paymentPlans.maxMonths.value, 'months').endOf('month').format('x')
      ) {
        this.maxDateError = true;
        this.reviewNext = false;
        return false;
      } else {
        this.maxDateError = false;
      }
    }

    if (this.merchant.paymentPlans.minMonths.enabled) {
      if (
        moment(this.order.endDateMax).endOf('day').format('x') <
        moment().add(this.merchant.paymentPlans.minMonths.value, 'months').endOf('month').format('x')
      ) {
        this.minDateError = true;
        this.reviewNext = false;
        return false;
      } else {
        this.minDateError = false;
      }
    }

    const minMonth =
      this.merchant.paymentPlans.minMonths.enabled && this.merchant.paymentPlans.minMonths.value > 0
        ? this.merchant.paymentPlans.minMonths.value
        : 1;
    if (
      moment(this.order.endDateMax).endOf('day').format('x') >
      moment().add(minMonth, 'months').endOf('month').format('x')
    ) {
      if (this.first && this.count === 0) {
        if (this.order.amountDue) this.paymentPlans = await this.previewPlan(this.order);
        this.first = false;
        this.canRecalculatePlan = false;
        this.reviewNext = true;
        return true;
      }
    }
  }

  /* absorbFee(val) {
    console.log(val)
  } */
  @Watch('order.depositType')
  onDepositTypeChange(val: any) {
    const refs: any = this.$refs;
    Vue.nextTick(() => {
      if (val === 'PERCENTAGE' && refs.depositAmountPerc) {
        refs.depositAmountPerc.$emit('input', refs.depositAmountPerc.$children[0].$children[1].value);
        this.canRecalculatePlan = true;
      }
      if (val === 'FLAT' && refs.depositAmountFlat) {
        refs.depositAmountFlat.$emit('input', refs.depositAmountFlat.value);
        this.canRecalculatePlan = true;
      }
      if (val === 'INSTALMENT' && refs.depositAmountInstalment) {
        this.order.depositAmount = 0;
        refs.depositAmountInstalment.$emit('input', 0);
        this.canRecalculatePlan = true;
      }
    });
  }

  @Watch('feeToCustomer')
  onCustomerPays(val: any) {
    if (val === true) {
      this.$modal.show(
        'dialog',
        {
          title: 'Alert! You are opting to charge the client the LayUp fee',
          text: 'Are you sure?',
          buttons: [
            {
              title: 'Yes',
              default: true, // Will be triggered by default if 'Enter' pressed.
              handler: () => {
                this.canRecalculatePlan = true;
                this.$modal.hide('dialog');
                return true;
              },
            },
            {
              title: 'No', // Button title
              handler: () => {
                this.feeToCustomer = false;
                this.canRecalculatePlan = true;
                this.$modal.hide('dialog');
                return true;
              }, // Button click handler
            },
          ],
        },
        { clickToClose: false, adaptive: true },
      );
    } else {
      this.canRecalculatePlan = true;
      this.order.absorbsFee = true;
    }
  }

  isNumber(evt) {
    evt = evt || window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  changes() {
    if (!this.first) {
      this.canRecalculatePlan = true;
      this.reviewNext = false;
      if (this.canRecalculatePlan) this.columnSize = 9;
      if (!this.canRecalculatePlan) this.columnSize = 12;
    }
  }

  get paymentPlanBreakdown() {
    // Find the longest plan
    let _deposit = this.deposit;
    if (this.paymentPlans) {
      const last = this.paymentPlans.length - 1;
      const plan = this.paymentPlans[last];
      const amount = plan.payments[plan.payments.length - 1].amount;
      let months = plan.months;
      if (this.order.depositType === 'INSTALMENT') {
        this.order.depositAmount = amount;
        months = plan.months;
      }
      let total = this.totalAmount;

      if (!this.order.absorbsFee) {
        _deposit = currency(plan.deposit);
        this.order.depositAmount = plan.deposit;
        total = currency(
          this.paymentPlans[0].payments.reduce((accumulator, current) => accumulator + current.amount, 0),
        );
      } else {
        _deposit = currency(plan.deposit);
      }

      return `DEPOSIT: ${_deposit}   INSTALLMENT(s): ${currency(amount)}/mo for ${months} months   TOTAL: ${total}`;
    }
    return `DEPOSIT: ${_deposit}   INSTALLMENT(s): ${currency(0)}/mo for ${0} months   TOTAL: ${this.totalAmount}`;
  }

  get deposit() {
    if (this.order.depositAmount) return currency(this.order.depositAmount);
    return currency(0);
  }

  get totalAmount() {
    if (this.order.amountDue) return currency(this.order.amountDue);
    return currency(0);
  }

  async recalculatePlan() {
    this.minDateError = false;
    this.maxDateError = false;
    const minMonths =
      this.merchant.paymentPlans.minMonths.enabled && this.merchant.paymentPlans.minMonths.value > 0
        ? this.merchant.paymentPlans.minMonths.value
        : 1;
    if (moment(this.order.endDateMax).format('x') < moment().add(minMonths, 'months').format('x')) {
      this.minDateError = true;
      this.reviewNext = false;
      return;
    }
    if (this.merchant.paymentPlans.maxMonths.enabled) {
      if (
        moment(this.order.endDateMax).format('x') >
        moment().add(this.merchant.paymentPlans.maxMonths.value, 'months').endOf('month').format('x')
      ) {
        this.maxDateError = true;
        this.reviewNext = false;
        return;
      }
    }
    this.paymentPlans = await this.previewPlan(this.order);
    this.canRecalculatePlan = false;
    this.reviewNext = true;
  }

  flatGreatThanTotalAmount() {
    let deposit;
    if (this.order) deposit = this.order.depositAmount;
    if (deposit > this.order.amountDue) {
      return true;
    }
    return false;
  }

  checkPaymentPlanSettings() {
    if (this.user.merchantRole !== 'ADMIN' && !this.merchant.paymentPlans.editPaymentPlanSettings) {
      return false;
    }
    return true;
  }

  isEmpty(value) {
    return _.isEmpty(value);
  }
}
