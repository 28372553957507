<template lang="pug">
.test
  nuxt
</template>

<script>
import { setTimeout } from 'timers';
import Created from '~/components/Plans/Create.vue';

export default {
  created() {
    setTimeout(() => {
      this.$modal.show(Created);
    }, 2000);
  },
};
</script>
