
import Vue from 'vue';
import { ValidationObserver } from 'vee-validate';
import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'VerifyMobile',
  components: {
    ValidationObserver,
  },
  props: {
    cellNumber: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      hasFailed: false,
      otp: '',
      mobile: '',
      resent: false,
      toUpdateMobile: false,
      verifyUser: {} as any,
      backUrl: '' as any,
      cellNumberVerified: false,
      message: 'Your email has been successfully verified',
      forgotPassword: 'false',
    };
  },
  async created() {
    if ((this.$route.query as { mobile?: string }).mobile) {
      this.mobile = (this.$route.query as { mobile?: string }).mobile as string;
    }
    if ((this.$route.query as { forgotPassword?: string }).forgotPassword) {
      this.forgotPassword = (this.$route.query as { forgotPassword?: string }).forgotPassword as string;
    }
    this.backUrl =
      (await localStorage.getItem('mobile-screen')) != null ? await localStorage.getItem('mobile-screen') : '';

    if (this.cellNumber) this.mobile = this.cellNumber;
  },
  methods: {
    ...mapActions('auth', {
      verify: 'verify',
      sendEmailVerification: 'sendEmailVerification',
      updateUser: 'updateUser',
      getUser: 'getUser',
      logout: 'logout',
      setUser: 'setUser',
    }),

    login() {
      this.logout();
      this.$router.push('/');
    },
    async verifyCellNumber() {
      this.loading = true;
      this.cellNumberVerified = false;
      try {
        const user = {
          cellNumber: this.mobile,
          otp: this.otp,
        } as any;
        const verified = await this.verify(user);
        Vue.$toast.success('Alert!', {
          position: 'top',
          message: 'Mobile number verified successfully.',
        });
        await localStorage.removeItem('fromUserReg');
        await localStorage.removeItem('fromDataCell');
        await localStorage.removeItem('fromDataEmail');
        if (this.backUrl === '/forgot-password') {
          this.$router.push('/reset-password');
          return;
        } else if (this.backUrl === '/dashboard') {
          await localStorage.removeItem('mobile-screen');
          location.href = '/dashboard';
          return;
        }
        if (verified && verified.userId) {
          this.verifyUser = await this.getUser(verified.userId);
          this.setUser(this.verifyUser);
          if (this.forgotPassword === 'true') {
            this.$router.push('/reset-password');
          } else {
            this.$router.push('/email-screen');
          }
        }
      } catch (error) {
        this.hasFailed = true;
        this.loading = false;
      }
    },
    async resend() {
      this.resent = true;
      this.toUpdateMobile = false;
      try {
        await this.$axios.post('request-password-reset', {
          redirectTo: `${process.env.MERCHANT_URL}/dashboard`,
          value: this.mobile,
          channel: 'MOBILE',
        });
      } catch (error) {}
    },
    updateMobile() {
      this.$router.push(this.backUrl);
    },
    isNumberKey(evt) {
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
      return true;
    },
  },
});
