
import Vue from 'vue';
import * as _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import ContractChangeRequestForm from './ContractChangeRequestForm.vue';
import Contract from './contract/Contract.vue';
import SettlementStructure from './SettlementStructure.vue';

export default Vue.extend({
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ContractChangeRequestForm,
    Contract,
    // eslint-disable-next-line vue/no-unused-components
    SettlementStructure,
  },
  props: {
    onboarding: {
      type: Object as any,
      default() {
        return {} as any;
      },
    },
  },
  data() {
    return {
      onboardingMerchant: {} as any,
      base_url: '',
      loading: false,
      signContractEnabled: false,
      merchantBoardingData: {} as any,
      polling: null as any,
      merchantContractSigned: false,
      viewSettlementFees: true,
      download: false,
      keyChange: 0,
    };
  },
  computed: {
    ...mapState('auth', ['user', 'merchant']),
  },
  created() {
    this.base_url = process.env.MERCHANT_URL as string;
    if (!_.isEmpty(this.merchant)) {
      this.onboardingMerchant = _.merge(this.merchant, this.onboardingMerchant);
    } else {
      this.onboardingMerchant = _.merge(this.onboardingMerchant, this.onboarding);
    }
    if (this.onboardingMerchant.merchantContractSigned === 'pending') {
      this.polling = setInterval(async () => {
        const merchant = await this.fetchMerchant(this.onboardingMerchant._id);
        if (merchant) this.onboardingMerchant = _.merge(this.onboardingMerchant, merchant);
        this.loading = false;
        this.signContractEnabled = true;
        merchant.docType = 'get';
        if (merchant.merchantContractSigned === 'pending') {
          await this.signOnboardingContract(merchant);
        } else {
          this.merchantContractSigned = true;
          this.$emit('next:complete', merchant);
          clearInterval(this.polling);
        }
      }, 5000);
    }

    // eslint-disable-next-line require-await
    this.$root.$on('getUpdatedContractState', async () => {
      this.onboardingMerchant = _.merge(this.merchant, this.onboardingMerchant);
    });
  },
  methods: {
    ...mapActions({
      signOnboardingContract: 'auth/signContract',
      fetchMerchant: 'auth/fetchMerchant',
      downloadContractLayup: 'auth/downloadContract',
      sendChangeRequest: 'auth/sendMerchantSupportAndContactTicket',
    }),
    isEmpty(obj) {
      return _.isEmpty(obj);
    },
    async sendSignatureLink() {
      try {
        if (!_.isEmpty(this.merchant)) {
          this.onboardingMerchant = _.merge(this.merchant, this.onboardingMerchant);
        } else {
          this.onboardingMerchant = _.merge(this.onboardingMerchant, this.onboarding);
        }
        this.loading = true;
        this.signContractEnabled = false;
        this.onboardingMerchant.docType = 'sign';
        this.onboardingMerchant.type = 'sign';

        const result = await this.signOnboardingContract(this.onboardingMerchant);
        if (result.status === 'success') {
          // Send notification for a new merchant
          Vue.$toast.success('Alert!', {
            position: 'top',
            message: 'An  email with signing instructions has been sent successfully.',
          });

          const data = {
            type: 'new_merchant_registered',
            merchantName: this.onboardingMerchant.name,
            merchantRegisteredEntity: this.onboardingMerchant.registeredEntity,
            merchantUser: this.user.name,
            merchantEmail: this.user.email,
          };

          // Activated merchant notification
          await this.sendChangeRequest(data);

          const merchant = await this.fetchMerchant(this.onboardingMerchant._id);
          if (merchant) this.onboardingMerchant = merchant;
          this.polling = setInterval(async () => {
            const merchant = await this.fetchMerchant(this.onboardingMerchant._id);
            if (merchant) this.onboardingMerchant = merchant;
            this.loading = false;
            this.signContractEnabled = true;
            merchant.docType = 'get';
            if (merchant.merchantContractSigned === 'pending') {
              await this.signOnboardingContract(merchant);
            } else {
              this.$root.$emit('getUpdatedContractState');
              clearInterval(this.polling);
              const _merchant = await this.fetchMerchant(this.onboardingMerchant._id);
              this.$emit('next:complete', _merchant);
              this.merchantContractSigned = true;
            }
          }, 5000);
        } else {
          Vue.$toast.error('Alert!', {
            position: 'top',
            message: 'An error occurred while trying to send contract. Please try again',
          });
          this.loading = false;
        }
      } catch (err) {
        Vue.$toast.error('Alert!', {
          position: 'top',
          message: 'An error occurred while trying to send contract. Please try again',
        });
        this.loading = false;
      }
    },
    nextToBankDetails() {
      this.onboardingMerchant = _.merge(this.onboardingMerchant, this.onboarding);
      this.$emit('next:toBanking', this.onboardingMerchant);
    },
    goBack() {
      this.$emit('goBack:toFees');
    },
    skipToDashboard() {
      clearInterval(this.polling);
      this.$emit('dashboard');
    },
    contractChangeRequest() {
      this.$modal.show(
        ContractChangeRequestForm,
        { merchantData: this.onboardingMerchant },
        {
          scrollable: true,
          height: 'auto',
          adaptive: true,
        },
      );
    },
    done() {
      this.loading = true;
      if (this.merchant.onboardingState) {
        this.onboardingMerchant = this.merchant;
      } else {
        this.onboardingMerchant = this.onboarding;
      }
      this.onboardingMerchant.onboardingState = 'complete';
      this.$emit('submit:updateMerchant', this.onboardingMerchant);
      clearInterval(this.polling);
      document.location.href = '/dashboard';
      this.loading = false;
    },
    async downloadContract() {
      this.loading = true;
      this.download = true;
      if (this.merchant && this.merchant.onboardingState) {
        this.onboardingMerchant = _.merge(this.onboardingMerchant, this.merchant);
      } else {
        this.onboardingMerchant = _.merge(this.onboardingMerchant, this.onboarding);
      }
      this.onboardingMerchant.type = 'download';
      await this.downloadContractLayup(this.onboardingMerchant)
        .then((result) => {
          const blob = new Blob([result], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const fileName = `Layup Licensing Agreement with ${this.onboardingMerchant.name}.pdf`;
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
          this.download = false;
        })
        .catch(() => {
          this.loading = false;
          this.download = false;
        });
    },
  },
});
