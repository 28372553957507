import { extend } from 'vee-validate';
import { required, email, max } from 'vee-validate/dist/rules';

// install the 'required' rule.
extend('required', {
  ...required,
  message: 'This field is required',
});

extend('email', email);

extend('max', {
  ...max,
  message: 'This field may not exceed 60 Characters',
});
