<template>
  <div class="app">
    <md-app>
      <md-app-toolbar v-if="user" class="md-layout md-alignment-center-space-between">
        <md-button v-if="!menuVisible" class="md-icon-button" @click="toggleMenu">
          <md-icon>menu</md-icon>
        </md-button>
        <div class="actions">
          <md-button
            class="create-order-button md-primary md-raised align-self-end"
            :disabled="!user.verifiedEmail || showMerchant"
            @click="showCreate"
            >Create Plan</md-button
          >

          <div id="notificationMenu" style="position: relative; right: 0.7em">
            <b-dropdown
              id="dropdown-1"
              right
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              class="notifications notification-link"
              :disabled="!orders.length || !checkMerchantId()"
            >
              <div id="pointer"></div>
              <h6 class="d-username d-flex justify-content-center">Notifications</h6>
              <template #button-content class="drop-button" v.on:click.native="show = true">
                <p @click="show = true">
                  <md-icon class="icon-menu" :class="{ disable: !checkMerchantId() }">notifications</md-icon>
                  <b-badge v-if="orders.length" pill class="badge" variant="info"> {{ orders.length }}</b-badge>
                </p>
              </template>
              <div v-if="show">
                <li v-for="order in orders" :key="order._id" class="dropdown__menu-item" @click="linkOff">
                  <nuxt-link
                    :to="{ name: `dashboard-plans-id`, params: { id: order._id, order: order } }"
                    class="dropdown__menu-link"
                  >
                    <div class="dropdown__menu-svg">
                      <svg class="svg-icon" viewBox="0 0 20 20">
                        <path
                          d="M17.211,3.39H2.788c-0.22,0-0.4,0.18-0.4,0.4v9.614c0,0.221,0.181,0.402,0.4,0.402h3.206v2.402c0,0.363,0.429,0.533,0.683,0.285l2.72-2.688h7.814c0.221,0,0.401-0.182,0.401-0.402V3.79C17.612,3.569,17.432,3.39,17.211,3.39M16.811,13.004H9.232c-0.106,0-0.206,0.043-0.282,0.117L6.795,15.25v-1.846c0-0.219-0.18-0.4-0.401-0.4H3.189V4.19h13.622V13.004z"
                        ></path>
                      </svg>
                    </div>
                    <div
                      v-if="order.orderRefundRequest === 'REQUESTED' || order.orderRefundRequest === 'ACCEPTED'"
                      class="dropdown__menu-text"
                    >
                      Refund Request: {{ order.reference }}
                    </div>
                    <div v-else class="dropdown__menu-text">Cancellation Request: {{ order.reference }}</div>
                  </nuxt-link>
                </li>
              </div>
            </b-dropdown>
          </div>

          <nuxt-link
            v-if="user && user.merchantRole === 'ADMIN'"
            id="settingMenu"
            class="link"
            to="/dashboard/settings"
            :disabled="showMerchant"
            :event="!user.verifiedEmail || showMerchant ? '' : 'click'"
            @click.native="checkEmailVerification()"
          >
            <md-icon class="icon-menu" :class="{ disable: !checkMerchantId() }">settings</md-icon>
          </nuxt-link>

          <div id="supportMenu" style="position: relative; right: 0.7em">
            <b-dropdown
              id="dropdown-1"
              right
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              class="notifications"
            >
              <div id="pointer"></div>
              <h6 class="d-username d-flex justify-content-center">Support</h6>
              <template #button-content class="drop-button" v.on:click.native="show = true">
                <p @click="show = true">
                  <md-icon class="icon-menu">help</md-icon>
                </p>
              </template>
              <div v-if="show">
                <li class="dropdown__menu-item" @click="show = false">
                  <a href="#" class="dropdown__menu-link" @click="openFreshChat()">
                    <div class="dropdown__menu-svg">
                      <svg class="svg-icon" viewBox="0 0 20 20">
                        <path
                          d="M17.211,3.39H2.788c-0.22,0-0.4,0.18-0.4,0.4v9.614c0,0.221,0.181,0.402,0.4,0.402h3.206v2.402c0,0.363,0.429,0.533,0.683,0.285l2.72-2.688h7.814c0.221,0,0.401-0.182,0.401-0.402V3.79C17.612,3.569,17.432,3.39,17.211,3.39M16.811,13.004H9.232c-0.106,0-0.206,0.043-0.282,0.117L6.795,15.25v-1.846c0-0.219-0.18-0.4-0.401-0.4H3.189V4.19h13.622V13.004z"
                        ></path>
                      </svg>
                    </div>
                    <div class="dropdown__menu-text">Help Via Chat</div>
                  </a>
                </li>
                <li class="dropdown__menu-item" @click="show = false">
                  <a class="dropdown__menu-link" href="https://layup.co.za/faq/" target="_blank">
                    <div class="dropdown__menu-svg">
                      <svg viewBox="0 0 512 512">
                        <path
                          d="M256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28zm7.67-24h-16c-6.627 0-12-5.373-12-12v-.381c0-70.343 77.44-63.619 77.44-107.408 0-20.016-17.761-40.211-57.44-40.211-29.144 0-44.265 9.649-59.211 28.692-3.908 4.98-11.054 5.995-16.248 2.376l-13.134-9.15c-5.625-3.919-6.86-11.771-2.645-17.177C185.658 133.514 210.842 116 255.67 116c52.32 0 97.44 29.751 97.44 80.211 0 67.414-77.44 63.849-77.44 107.408V304c0 6.627-5.373 12-12 12zM256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8z"
                        />
                      </svg>
                    </div>
                    <div class="dropdown__menu-text">Help Center</div>
                  </a>
                </li>
                <li class="dropdown__menu-item" @click="show = false">
                  <nuxt-link :to="`/dashboard/support`" class="dropdown__menu-link" title="Subscriptions">
                    <div class="dropdown__menu-svg">
                      <svg class="svg-icon" viewBox="0 0 20 20">
                        <path
                          d="M17.388,4.751H2.613c-0.213,0-0.389,0.175-0.389,0.389v9.72c0,0.216,0.175,0.389,0.389,0.389h14.775c0.214,0,0.389-0.173,0.389-0.389v-9.72C17.776,4.926,17.602,4.751,17.388,4.751 M16.448,5.53L10,11.984L3.552,5.53H16.448zM3.002,6.081l3.921,3.925l-3.921,3.925V6.081z M3.56,14.471l3.914-3.916l2.253,2.253c0.153,0.153,0.395,0.153,0.548,0l2.253-2.253l3.913,3.916H3.56z M16.999,13.931l-3.921-3.925l3.921-3.925V13.931z"
                        ></path>
                      </svg>
                    </div>
                    <div class="dropdown__menu-text">Submit a Support Ticket</div>
                  </nuxt-link>
                </li>

                <li class="dropdown__menu-item" @click="show = false">
                  <nuxt-link
                    href="#"
                    class="dropdown__menu-link"
                    title="People &amp; sharing"
                    :to="`/dashboard/contact`"
                  >
                    <div class="dropdown__menu-svg">
                      <svg class="svg-icon" viewBox="0 0 20 20">
                        <path
                          d="M13.372,1.781H6.628c-0.696,0-1.265,0.569-1.265,1.265v13.91c0,0.695,0.569,1.265,1.265,1.265h6.744c0.695,0,1.265-0.569,1.265-1.265V3.045C14.637,2.35,14.067,1.781,13.372,1.781 M13.794,16.955c0,0.228-0.194,0.421-0.422,0.421H6.628c-0.228,0-0.421-0.193-0.421-0.421v-0.843h7.587V16.955z M13.794,15.269H6.207V4.731h7.587V15.269z M13.794,3.888H6.207V3.045c0-0.228,0.194-0.421,0.421-0.421h6.744c0.228,0,0.422,0.194,0.422,0.421V3.888z"
                        ></path>
                      </svg>
                    </div>
                    <div class="dropdown__menu-text">Contact Us</div>
                  </nuxt-link>
                </li>
              </div>
            </b-dropdown>
          </div>

          <div id="profileMenu">
            <b-dropdown id="dropdown-1" right class="m-md-2" variant="outline-primary">
              <div id="pointer"></div>
              <template #button-content class="drop-button" @click="show = true">
                <div class="res-circle" @click="show = true">
                  <div class="circle-txt">{{ intials }}</div>
                </div>
              </template>
              <h6 class="d-username d-flex justify-content-center">{{ user.name }}</h6>

              <div v-if="show">
                <li class="dropdown__menu-item" @click="show = false">
                  <nuxt-link
                    class="dropdown__menu-link"
                    title="Account"
                    :to="`/dashboard/profile`"
                    :disabled="showMerchant"
                    :event="!showMerchant ? 'click' : ''"
                  >
                    <div class="dropdown__menu-svg">
                      <svg viewBox="0 0 496 512">
                        <path
                          d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm128 421.6c-35.9 26.5-80.1 42.4-128 42.4s-92.1-15.9-128-42.4V416c0-35.3 28.7-64 64-64 11.1 0 27.5 11.4 64 11.4 36.6 0 52.8-11.4 64-11.4 35.3 0 64 28.7 64 64v13.6zm30.6-27.5c-6.8-46.4-46.3-82.1-94.6-82.1-20.5 0-30.4 11.4-64 11.4S204.6 320 184 320c-48.3 0-87.8 35.7-94.6 82.1C53.9 363.6 32 312.4 32 256c0-119.1 96.9-216 216-216s216 96.9 216 216c0 56.4-21.9 107.6-57.4 146.1zM248 120c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 144c-30.9 0-56-25.1-56-56s25.1-56 56-56 56 25.1 56 56-25.1 56-56 56z"
                        />
                      </svg>
                    </div>
                    <div class="dropdown__menu-text">Account</div>
                  </nuxt-link>
                </li>
                <li class="dropdown__menu-item">
                  <a href="#" class="dropdown__menu-link" title="Account" @click="onLogout()">
                    <div class="dropdown__menu-svg">
                      <svg class="svg-icon" viewBox="0 0 20 20">
                        <path
                          d="M17.308,7.564h-1.993c0-2.929-2.385-5.314-5.314-5.314S4.686,4.635,4.686,7.564H2.693c-0.244,0-0.443,0.2-0.443,0.443v9.3c0,0.243,0.199,0.442,0.443,0.442h14.615c0.243,0,0.442-0.199,0.442-0.442v-9.3C17.75,7.764,17.551,7.564,17.308,7.564 M10,3.136c2.442,0,4.43,1.986,4.43,4.428H5.571C5.571,5.122,7.558,3.136,10,3.136 M16.865,16.864H3.136V8.45h13.729V16.864z M10,10.664c-0.854,0-1.55,0.696-1.55,1.551c0,0.699,0.467,1.292,1.107,1.485v0.95c0,0.243,0.2,0.442,0.443,0.442s0.443-0.199,0.443-0.442V13.7c0.64-0.193,1.106-0.786,1.106-1.485C11.55,11.36,10.854,10.664,10,10.664 M10,12.878c-0.366,0-0.664-0.298-0.664-0.663c0-0.366,0.298-0.665,0.664-0.665c0.365,0,0.664,0.299,0.664,0.665C10.664,12.58,10.365,12.878,10,12.878"
                        ></path>
                      </svg>
                    </div>
                    <div class="dropdown__menu-text">Logout</div>
                  </a>
                </li>
              </div>
            </b-dropdown>
          </div>
        </div>
      </md-app-toolbar>
      <md-app-drawer v-if="user" :md-active.sync="menuVisible" md-persistent="full">
        <md-toolbar class="md-transparent" md-elevation="0">
          <img src="/logo.png" alt="Lay Up logo" class="home-logo" />
          <div class="md-toolbar-section-end">
            <md-button class="md-icon-button btn-cus" @click="toggleMenu">
              <md-icon>keyboard_arrow_left</md-icon>
            </md-button>
          </div>
        </md-toolbar>

        <md-list>
          <md-list-item v-if="!checkMerchantId()">
            <md-icon>loop</md-icon>
            <nuxt-link class="md-list-item-text" to="" @click.native="showNewMerchant()"
              >Onboarding In Progress</nuxt-link
            >
          </md-list-item>
          <md-list-item>
            <md-icon>home</md-icon>
            <nuxt-link
              class="md-list-item-text"
              to="/dashboard"
              :disabled="showMerchant"
              :event="!showMerchant ? 'click' : ''"
              @click.native="mobileToggle()"
              >Home</nuxt-link
            >
          </md-list-item>
          <md-list-item>
            <md-icon>shop</md-icon>
            <nuxt-link
              class="md-list-item-text"
              to="/dashboard/plans"
              :disabled="showMerchant"
              :event="!user.verifiedEmail || showMerchant ? '' : 'click'"
              @click.native="checkEmailVerification()"
              >Payment Plans</nuxt-link
            >
          </md-list-item>
          <md-list-item>
            <md-icon>calendar_view_day</md-icon>
            <nuxt-link
              class="md-list-item-text"
              to="/dashboard/payments-register"
              :disabled="showMerchant"
              :event="!user.verifiedEmail || showMerchant ? '' : 'click'"
              @click.native="checkEmailVerification()"
              >Payments</nuxt-link
            >
          </md-list-item>
          <md-list-item>
            <md-icon>money</md-icon>
            <nuxt-link
              class="md-list-item-text"
              to="/dashboard/invoices"
              :disabled="showMerchant"
              :event="!user.verifiedEmail || showMerchant ? '' : 'click'"
              @click.native="checkEmailVerification()"
              >Invoices</nuxt-link
            >
          </md-list-item>
          <md-list-item>
            <md-icon>credit_card</md-icon>
            <nuxt-link
              class="md-list-item-text"
              to="/dashboard/settlements"
              :disabled="showMerchant"
              :event="!user.verifiedEmail || showMerchant ? '' : 'click'"
              @click.native="checkEmailVerification()"
              >Settlements</nuxt-link
            >
          </md-list-item>
          <md-list-item>
            <md-icon>cancel</md-icon>
            <nuxt-link
              class="md-list-item-text"
              to="/dashboard/Cancellations"
              :disabled="showMerchant"
              :event="!user.verifiedEmail || showMerchant ? '' : 'click'"
              @click.native="checkEmailVerification()"
              >Cancellations</nuxt-link
            >
            <b-badge class="badge-cancellation" variant="info">{{ count.length }}</b-badge>
          </md-list-item>
        </md-list>
      </md-app-drawer>
      <md-app-content>
        <nuxt />
      </md-app-content>
    </md-app>
    <v-dialog />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Vue from 'vue';
import * as _ from 'lodash';
import Modal from '~/components/Plans/Modal.vue';
import MerchantModal from '~/components/Merchant/MerchantModal.vue';
import VerificationScreen from '~/components/Auth/VerifyMobile.vue';
export default {
  name: 'DefaultLayout',
  data: () => ({
    menuVisible: false,
    intials: '',
    windowWidth: window.innerWidth,
    params: {
      search: '',
      limit: 500,
      sort: '-createdAt',
      skip: 0,
    },
    orders: [],
    notifications: [],
    count: [],
    socket: null,
    show: false,
    showMerchant: false,
    environment: process.env.VUE_ENVIRONMENT,
    merchantData: {},
  }),
  computed: {
    ...mapState('auth', ['user', 'merchant']),
  },
  updated() {
    if (this.menuVisible) {
      this.$root.$emit('update-table-columns');
    } else {
      this.$root.$emit('update-table-columns-closed');
    }
  },
  async mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    // Code for showing the live chat
    this.initiateCall();
    this.notifications = [];
    this.environment = process.env.VUE_ENVIRONMENT;
    if (this.user && this.user.name) {
      if (this.user.name) {
        const name = this.user.name;
        const _name = name.split(' ');
        this.intials = _name
          .splice(0, 2)
          .toString()
          .split(',')
          .map((name) => name.charAt(0))
          .join('');
      } else {
        this.intials = 'LT';
      }
    }
    this.merchantData = this.merchant;
    if (this.user.merchantId != null) {
      this.merchantData = await this.fetchMerchant(this.user.merchantId);
    }
    if (this.user && this.user.cellNumber && !this.user.verifiedMobile) {
      await this.sendOtp(this.user.cellNumber);
      await this.showMobileVerificationScreen();
    } else if (!(await this.checkMerchantId())) {
      this.showNewMerchant();
    } else {
      this.notifications = [];
      if (this.user && !this.user.verifiedEmail) {
        Vue.$toast.error('Alert!', {
          position: 'top',
          message: `Your email is not verified. Please verify your email address.`,
        });
        setTimeout(() => {
          this.$router.push('/dashboard/profile');
        }, 1500);
      }
      this.$root.$on('updateNewMerchant', () => {
        this.showMerchant = false;
      });

      this.$root.$on('getUpdatedOrdersNotRead', () => {
        this.fetchAllOrders();
      });

      this.socket = this.$nuxtSocket({ withCredentials: false });
      await this.socket.on('orders:cancellation-request', async () => {
        await this.fetchAllOrders();
        await this.$root.$emit('reloadNoted');
        try {
          await this.$root.$emit('updateAcceptedOrder');
        } catch (error) {}
      });

      if (this.user && this.user.merchantId) {
        this.socket.emit('join', this.user.merchantId);
      }

      if (this.merchant && this.merchant.pricelistId) {
        await this.getPriceListById(this.merchant.pricelistId);
      }
      await this.fetchAllOrders();

      if (this.menuVisible) {
        this.$root.$emit('update-table-columns');
      } else {
        this.$root.$emit('update-table-columns-closed');
      }
    }
  },
  unmounted() {
    this.socket.removeAllListeners('orders:cancellation-request');
    this.socket.off('orders:cancellation-request');
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      fetchMerchant: 'auth/fetchMerchant',
      getPriceListById: 'auth/getPriceListById',
    }),
    ...mapActions({
      fetchOrder: 'orders/fetchOrder',
      fetchOrders: 'orders/fetchAllOrders',
    }),
    async sendOtp(mobile) {
      this.resent = true;
      this.toUpdateMobile = false;
      try {
        await this.$axios.post('request-password-reset', {
          redirectTo: `${process.env.MERCHANT_URL}/dashboard`,
          value: mobile,
          channel: 'MOBILE',
        });
      } catch (error) {
        console.log(error);
      }
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      if (this.menuVisible) {
        this.$root.$emit('update-table-columns');
      } else {
        this.$root.$emit('update-table-columns-closed');
      }
    },
    onLogout() {
      this.logout().then(() => {
        this.$router.replace('/');
      });
    },
    async fetchAllOrders() {
      await this.fetchOrders(this.params)
        .then(async (result) => {
          // Cancellation request orders
          this.orders = result.orders.filter(
            (order) =>
              order.notifications != null &&
              (order.state === 'PLACED' ||
                order.state === 'CANCELLING' ||
                (order.state === 'COMPLETED' && order.orderCancellationRequest == null)) &&
              (order.orderCancellationRequest === 'ACCEPTED' ||
                order.orderCancellationRequest === 'DISPUTE' ||
                order.orderCancellationRequest === 'WITHDRAWN' ||
                order.orderCancellationRequest === 'PENDING' ||
                order.orderRefundRequest === 'REQUESTED' ||
                order.orderRefundRequest === 'ACCEPTED') &&
              order.orderCancellationRequest !== 'REJECTED',
          );

          // Refund request orders
          // let refundOrders = result.orders.filter(order => order.notifications != null
          // && (order.state === 'PLACED' || order.state === 'COMPLETED')
          // && (order.orderRefundRequest === 'REQUESTED'
          //   || order.orderRefundRequest === 'ACCEPTED'));

          this.count = this.orders.filter((order) => {
            return order.orderCancellationRequest !== 'WITHDRAWN' && order.orderRefundRequest == null;
          });

          // Cancellation request orders - filter
          this.orders = this.orders.filter(
            (order) =>
              (order.state === 'PLACED' || order.state === 'CANCELLING' || order.state === 'COMPLETED') &&
              order.orderCancellationRequest !== 'REJECTED' &&
              order.notifications[order.notifications.length - 1].userId !== this.user._id &&
              this.user.merchantRole === 'ADMIN' &&
              (order.notifications[order.notifications.length - 1].read == null ||
                order.notifications[order.notifications.length - 1].read === false),
          );

          let count = 0;
          await this.orders.forEach((order) => {
            if (
              order.notifications[order.notifications.length - 1].read == null ||
              order.notifications[order.notifications.length - 1].read === false
            ) {
              this.notifications.push({
                id: count++,
                change: order.notifications[order.notifications.length - 1].change,
                date: order.notifications[order.notifications.length - 1].date,
                name: order.notifications[order.notifications.length - 1].name,
                reason: order.notifications[order.notifications.length - 1].reason,
                details: order.notifications[order.notifications.length - 1].details,
                orderName: order.name,
                orderId: order._id,
                orderRef: order.reference,
                read: order.notifications[order.notifications.length - 1].read,
                userId: order.notifications[order.notifications.length - 1].userId,
              });
            }
          });

          // Merge orders
          // var allOrders = [...this.orders, ...refundOrders];
          // this.orders = allOrders;

          this.notifications = this.notifications.filter(
            (notification) =>
              (notification.change !== 'ACCEPTED' &&
                notification.userId !== this.user._id &&
                notification.read == null) ||
              notification.read === false,
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showCreate() {
      // shows create plan modal
      this.$modal.show(
        Modal,
        {},
        {
          width: '90%',
          scrollable: true,
          height: 'auto',
          adaptive: true,
        },
      );
    },
    // Onboarding merchant
    showNewMerchant() {
      this.mobileToggle();
      this.showMerchant = true;
      this.$modal.show(
        MerchantModal,
        {},
        {
          width: '90%',
          scrollable: true,
          height: 'auto',
          clickToClose: false,
          adaptive: true,
        },
      );
    },
    async showMobileVerificationScreen() {
      await localStorage.setItem('mobile-screen', '/dashboard');
      this.showMerchant = true;
      this.$modal.show(
        VerificationScreen,
        { cellNumber: this.user.cellNumber },
        {
          scrollable: false,
          height: 'auto',
          clickToClose: false,
          adaptive: true,
          class: 'mobile-verification-modal',
        },
      );
    },
    checkMerchantId() {
      this.environment = process.env.VUE_ENVIRONMENT;
      if (
        (!_.isEmpty(this.user) &&
          (this.user.merchantId == null || this.user.merchantId === '' || this.user.merchantId === 'undefined')) ||
        (this.merchantData &&
          this.merchantData.onboardingState != null &&
          this.merchantData.onboardingState !== 'complete') ||
        (this.merchantData &&
          this.merchantData.merchantContractSigned != null &&
          (this.merchantData.merchantContractSigned === 'pending' ||
            this.merchantData.merchantContractSigned !== 'complete & signed'))
      ) {
        return false;
      }

      return true;
    },
    mobileToggle() {
      if (this.windowWidth <= 767) {
        this.menuVisible = false;
      }
    },
    checkEmailVerification() {
      try {
        if (this.windowWidth <= 767) {
          this.menuVisible = false;
        }
        // this.menuVisible = !this.menuVisible
        if (!this.user.verifiedEmail) {
          Vue.$toast.error('Alert!', {
            position: 'top',
            message: `Please verify your email address to gain access to this screen.`,
          });
        }

        // Clear previous state
        if (window.location.href !== `${process.env.MERCHANT_URL}/dashboard/plans`) {
          localStorage.removeItem('prevState');
        }
      } catch (error) {
        console.log(error);
      }
    },
    goToPlan(orderId) {
      this.$router.push(`/dashboard/plans/${orderId}?notification=true`);
    },
    openFreshChat() {
      if (window.fcWidget.isOpen() !== true) {
        window.fcWidget.open();
      }
    },
    initFreshChat() {
      try {
        window.fcWidget.init({
          token: 'a142ad56-f318-45ac-960d-da9b316f5c09',
          host: 'https://wchat.freshchat.com',
          siteId: 'Merchant',
        });
      } catch (error) {
        console.log(error);
      }
    },
    initialize(i, t) {
      let e;
      // eslint-disable-next-line no-unused-expressions
      i.getElementById(t)
        ? this.initFreshChat()
        : (((e = i.createElement('script')).id = t),
          (e.async = !0),
          (e.src = 'https://wchat.freshchat.com/js/widget.js'),
          (e.onload = this.initFreshChat),
          i.head.appendChild(e));
    },
    initiateCall() {
      this.initialize(document, '#bot');
    },
    linkOff() {
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
.mobile-verification-modal {
  .md-gutter {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
  .update-number-text {
    display: none;
  }

  .landing {
    max-width: 700px !important;
  }
}
.disable {
  color: lightgrey !important;
}
.btn-link:focus,
.btn-link.focus {
  box-shadow: none !important;
}
.md-icon.md-theme-default.md-icon-font {
  z-index: inherit !important;
}
.notification-link {
  display: flex !important;
  position: relative;
  // left: 1em;
}
.plan-icon {
  font-size: 16px !important;
  position: relative;
  top: -0.1em;
}
.notifications {
  position: relative;
  top: 1.1em;
  .icon-menu {
    font-size: 33px !important;
    position: relative;
  }
  .badge {
    position: relative;
    top: -1.2em;
    left: -1em;
    margin-right: -1em;
  }
}
.badge-cancellation {
  max-width: 3em !important;
  height: 1.9em !important;
  padding: 5px;
  position: relative;
  left: -3em;
  // left: -10em;
}
.res-circle {
  width: 100%;
  border-radius: 50%;
  line-height: 0;
  position: relative;
}

.res-circle::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.circle-txt {
  position: absolute;
  bottom: 50%;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 16px;
  font-family: arial, sans-serif;
  font-weight: bold;
  left: -0.5px;
}
.actions {
  .create-order-button {
    margin-right: 2em;
  }
  .d-username,
  .d-username:hover {
    background-color: rgb(240, 236, 236) !important;
    color: black;
    padding: 5px;
    padding-left: 10px;
    padding-top: 12px;
    padding-bottom: 2px;
  }
  .titte {
    position: relative;
    top: -0.1em !important;
    // margin-right: .5em;
    font-size: 20px;
  }
  .btn-outline-primary {
    background-color: #19bdc0;
    border-color: #19bdc0;
    color: white;
    height: 2.4em;
    width: 2.4em;
    border-radius: 50%;
    padding: 8px;
    position: relative;
    // padding-left: 10px;
    top: 0.3em;
    font-size: 18px;
    margin-left: -1px !important;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .btn-outline-primary:hover {
    background-color: #19bdc0;
    border-color: #19bdc0;
  }
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    background-color: #19bdc0;
    border-color: #19bdc0;
  }
  @media only screen and (max-width: 767px) {
    .btn-outline-primary {
      position: relative;
      top: 0.15em;
    }
    .notifications {
      position: relative;
      top: 0.5em;
      .icon-menu {
        font-size: 33px !important;
        position: relative;
      }
      .badge {
        position: relative;
        top: -1em;
      }
    }
    .md-button.md-theme-default {
      z-index: inherit !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.not-responded {
  font-weight: bold;
  color: #000 !important;
}
ol {
  counter-reset: li;
  list-style: none;
  padding: 0;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border-bottom: 1px lightgray solid;
}

ol a {
  position: relative;
  display: block;
  padding: 0.2em 0.2em 0.2em 0.4em;
  margin: 0.3em 0 0.3em 0.3em;
  background: transparent;
  color: darkgray !important;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

ol:last-child {
  border-bottom: none !important;
}

ol a:after {
  position: absolute;
  content: '';
  border: 0.5em solid transparent;
  left: -1em;
  top: 50%;
  margin-top: -0.5em;
  transition: all 0.3s ease-out;
  border-left-color: #19bdc0;
}

ol a:hover:after {
  // left: -.5em;
  border-left-color: #19bdc0;
}

.md-app {
  min-height: 100vh;
  .d-list {
    display: flex;
  }
  .actions {
    margin-left: auto;
    display: flex;
    .link {
      text-decoration: none;
      margin-right: 2em;
      // margin-left: 1em;
      .icon-menu {
        font-size: 30px !important;
        // color: #505050;
        position: relative;
        right: -0.2em;
      }
      position: relative;
      top: 1.67em;
    }
    position: relative;
    top: -0.46em;
  }
  .md-drawer.md-theme-default {
    margin-right: 0px !important;
  }
  .md-drawer {
    width: 240px !important;
    .btn-cus {
      position: relative;
      top: 0.3em;
      right: -0.8em;
    }
    .md-toolbar {
      .home-logo {
        max-height: 60px;
        margin-top: 14px;
        width: 150px;
      }
      hr {
        width: 100%;
      }
    }
    .active-link {
      font-weight: bold;
      text-decoration: none;
    }
  }

  .md-app-drawer {
    z-index: 6;

    .md-list {
      margin-top: 20px;

      .active-link {
        color: #19bdc0;
      }
    }
  }

  .md-content {
    background-color: #e9eff6;
  }
}

@media only screen and (max-width: 767px) {
  .actions {
    .link {
      position: relative;
      top: 1em !important;
    }
    position: relative;
    top: -0.08em !important;
  }

  #profileMenu {
    position: relative;
    top: 0.3em !important;
  }
  #supportMenu {
    position: relative;
    top: 0.3em !important;
  }
  #notificationMenu {
    position: relative;
    top: 0.3em !important;
  }
  #settingMenu {
    position: relative;
    top: 1.35em !important;
  }
}
</style>

<style lang="scss" scoped>
// .profile-dropdown {
//   margin-top: 1em;
// }
.d-username {
  position: relative;
  top: -0.5em;
}
#pointer {
  border-bottom: solid 10px lightgrey;
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
  position: absolute;
  width: 0;
  height: 0;
  top: -10px;
  right: 5px !important;
}
.dropdown__menu-item {
  width: 20em !important;
  text-decoration: none !important;
}

.dropdown__menu-text {
  color: rgb(61, 60, 60);
}

:root {
  --font-primary: 'Open Sans', sans-serif;
  --color-gray: #dadce0;
}

// Global

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
  font-family: var(--font-primary);
  overflow: hidden;
}
hr {
  border: 0;
  height: 0;
  margin: 1.5rem 0;
  border-top: 1px solid var(--color-gray);
}

// Header Menu

.header {
  padding: 2rem 5rem 2rem 5rem;
  &__nav {
    position: relative;
  }
  &__navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__item {
    padding: 1rem;
  }
  &--icon {
    width: 1.65rem;
    height: 1.65rem;
  }
  &--button {
    top: 0;
    right: 0;
    position: absolute;
    margin: 0;
    padding: 0;
    color: gray;
    cursor: pointer;
    border: 1px solid transparent;
    background-color: transparent;
    &:focus {
      outline: 0;
    }
  }
}

// Dropdown Menu

.dropdown__menu {
  top: 100%;
  right: 0;
  position: absolute;
  z-index: 10;
  height: 25rem;
  min-width: 300px;
  margin-top: 1rem;
  overflow-y: auto;
  padding: 2rem 1rem 2rem 0rem;
  border-radius: 12px;
  background-color: white;
  border: 1px solid var(--color-gray);
  background-clip: padding-box;
  &-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.6);
    padding: 0.8rem 0 0.8rem 2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    border-radius: 0 50px 50px 0;
    &:hover {
      color: #17bf63;
      background-color: rgba(79, 192, 141, 0.1);
      text-decoration: none !important;
    }
  }
  &-svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  &-text {
    font-weight: 300;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

// Animation Menu Icon

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.6s;
}
.slide-fade-enter,
.slide-fade-leave-active {
  opacity: 0;
}
.slide-fade-enter {
  transform: translateX(31px);
}
.slide-fade-leave-active {
  transform: translateX(-31px);
}

// Dropdown Menu Animation

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 1s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

// Wrapper

.main {
  margin: 6rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__subtitle {
    color: #4f5959;
    font-weight: 100;
    margin-bottom: 2rem;
  }
  &__link {
    margin-right: 1.5rem;
    display: inline-block;
    &:last-child {
      margin-right: 0;
    }
  }
  &__svg {
    width: 2rem;
    height: 2rem;
    opacity: 0.6;
    color: #94a2b0;
    &:hover {
      color: #ffad1f;
    }
  }
}
</style>
