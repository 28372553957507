
import Vue from 'vue';
import { isMobileOnly } from 'mobile-device-detect';
import { mapActions, mapState, mapGetters } from 'vuex';
import * as _ from 'lodash';
import CreateAccount from './CreateAccount.vue';
import CompanyRegistration from './CompanyRegistration.vue';
import SelectECommercePlatform from './SelectECommercePlatform.vue';
import SignContract from './SignContract.vue';
import Complete from './Complete.vue';
import BankingDetails from './BankingDetails.vue';
import CreditCard from './CreditCard.vue';
import Fees from './Fees.vue';

export default Vue.extend({
  components: {
    CreateAccount,
    CompanyRegistration,
    SelectECommercePlatform,
    SignContract,
    BankingDetails,
    Complete,
    Fees,
    CreditCard,
  },
  data() {
    return {
      disableTabs: false,
      share: false,
      isMobile: isMobileOnly,
      registration: false,
      create: false,
      active: 'create',
      e_commerce: false,
      contract: false,
      complete: false,
      banking: false,
      fees: false,
      creditCard: false,
      type: '',
      registrationHeader: 'Company / Individual Details',
      onboardingMerchant: {} as any,
      merchant: {},
      activeStep: 'create',
      priceList: {},
      index: 0,
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state: any) => state.user,
    }),
    ...mapGetters({ merchantState: 'auth/merchant' }),
  },
  created() {
    this.getBanks();
    if (!_.isEmpty(this.merchantState)) {
      if (this.merchantState.onboardingState === 'new') {
        this.activeStep = 'registration';
      }

      if (this.merchantState.onboardingState === 'registered') {
        this.activeStep = 'banking';
      }

      if (this.merchantState.onboardingState === 'account verified') {
        this.activeStep = 'credit-card';
      }

      if (this.merchantState.onboardingState === 'credit card added') {
        this.activeStep = 'e-commerce';
      }

      if (this.merchantState.onboardingState === 'e-commerce selected') {
        this.activeStep = 'fees';
      }

      if (this.merchantState.onboardingState === 'payment methods selected') {
        this.activeStep = 'contract';
      }

      if (
        this.merchantState.onboardingState === 'complete' &&
        this.merchantState.merchantContractSigned === 'pending'
      ) {
        this.activeStep = 'contract';
      }

      if (this.merchantState.onboardingState === 'complete' && this.merchantState.merchantContractSigned === 'signed') {
        this.activeStep = 'complete';
      }
    }
  },
  methods: {
    ...mapActions({
      loginUser: 'auth/login',
      logout: 'auth/logout',
      CreateNewMerchant: 'auth/onboardingNewMerchant',
      saveMerchant: 'auth/saveMerchant',
      fetchMerchant: 'auth/fetchMerchant',
      getBanks: 'banks/getBanks',
    }),
    setDone(id, next) {
      this[id] = true;
      if (next) {
        this.active = next;
      }
    },
    accountCreated(onboardingMerchant) {
      this.onboardingMerchant = onboardingMerchant;
      if (this.onboardingMerchant.accountType === 'Business Account') {
        this.registrationHeader = 'Enter Company Registration Number';
      }

      if (this.onboardingMerchant.accountType === 'Individual Account') {
        this.registrationHeader = 'Enter User ID Number';
      }
      this.$root.$emit('updateAccountType');
    },
    // creating merchant
    async createMerchant(onboardingMerchant) {
      if (!_.isEmpty(this.merchant)) {
        this.nextToBanking(this.merchant);
        return;
      }

      this.onboardingMerchant = _.merge(this.onboardingMerchant, onboardingMerchant);
      await this.CreateNewMerchant(this.onboardingMerchant)
        .then((result) => {
          this.merchant = result;
          this.$store.commit('auth/setMerchant', result);
          this.nextToRegistration();
        })
        .catch((err: any) => {
          if (err && err.response && err.response.data && err.response.data.includes('Duplicate')) {
            Vue.$toast.error('Merchant Already Registered!', {
              position: 'top',
              message: 'Please note this merchant name has already been registered.',
            });
          } else {
            Vue.$toast.error('Error', {
              position: 'top',
              message: err.response.data,
            });
          }
        });
    },
    // update merchant details
    async save(onboardingMerchant) {
      await this.saveMerchant(onboardingMerchant).then(async () => {
        const merchant = await this.fetchMerchant(onboardingMerchant._id);
        this.$store.commit('auth/setMerchant', merchant);
      });
    },
    nextToRegistration() {
      this.activeStep = 'registration';
      this.setDone('create', this.activeStep);
    },
    nextToBanking(onboardingMerchant) {
      this.onboardingMerchant = onboardingMerchant;
      this.activeStep = 'banking';
      this.setDone('registration', this.activeStep);
      this.index += 1;
    },
    nextToCreditCard(onboardingMerchant) {
      this.onboardingMerchant = onboardingMerchant;
      this.activeStep = 'credit-card';
      this.setDone('banking', this.activeStep);
      this.index += 1;
    },
    nextToECommerce(onboardingMerchant) {
      this.onboardingMerchant = onboardingMerchant;
      this.activeStep = 'e-commerce';
      this.setDone('credit-card', this.activeStep);
    },
    nextToFees(onboardingMerchant) {
      this.onboardingMerchant = onboardingMerchant;
      this.activeStep = 'fees';
      this.setDone('e-commerce', this.activeStep);
      this.index += 1;
    },
    nextToSettlement(onboardingMerchant) {
      this.onboardingMerchant = onboardingMerchant;
      this.activeStep = 'settlement-structure';
      this.setDone('e-commerce', this.activeStep);
    },
    nextToContract(onboardingMerchant) {
      this.onboardingMerchant = onboardingMerchant;
      this.activeStep = 'contract';
      this.setDone('fees', this.activeStep);
      this.index += 1;
    },
    nextToComplete(onboardingMerchant) {
      this.onboardingMerchant = onboardingMerchant;
      this.activeStep = 'complete';
      this.setDone('contract', this.activeStep);
    },
    goBack() {
      this.activeStep = 'created';
      this.setDone('registration', 'created');
    },
    goBackRegistration() {
      this.activeStep = 'banking';
      this.setDone('e-commerce', 'banking');
      this.index += 1;
    },
    goBackECommerce() {
      this.activeStep = 'e-commerce';
      this.setDone('contract', 'e-commerce');
    },
    goBackToContract() {
      this.activeStep = 'registration';
      this.setDone('banking', 'registration');
    },
    goBackSettlementStructure() {
      this.activeStep = 'settlement-structure';
      this.setDone('contract', 'settlement-structure');
    },
    goBackToFees() {
      this.activeStep = 'fees';
      this.setDone('contract', 'fees');
      this.index -= 1;
    },
    close() {
      this.$root.$emit('updateNewMerchant');
      this.$emit('close');
    },
    skipToDashboard() {
      this.$root.$emit('updateNewMerchant');
      this.$emit('close');
      this.$router.push('/dashboard');
    },
    contactSupport() {
      this.$emit('close');
      this.$router.push('/dashboard/support');
    },
  },
});
