
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import ContactSupportFrom from './ContactSupportFrom.vue';
import { BANK_ACCOUNT_TYPES } from '~/common/data';

export default Vue.extend({
  name: 'BankingDetails',
  data() {
    return {
      accountTypes: BANK_ACCOUNT_TYPES,
      isLoading: false,
      showTryAgainLater: false,
      bankDetailSubmitted: false,
      name: '',
      selectedBank: {
        name: '',
        branchCode: '',
      },
      accountNumber: '',
      accountType: '',
      simulated: false,
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state: any) => state.user,
    }),
    ...mapGetters({
      banks: 'banks/getBanks',
      merchant: 'auth/merchant',
    }),
    shouldShowSimulate(): boolean {
      return (
        (process.env.VUE_ENVIRONMENT === 'development' || process.env.VUE_ENVIRONMENT === 'staging') &&
        !this.areBankDetailsVerified
      );
    },
    shouldDisableValidate(): boolean {
      return Boolean(this.selectedBank.name && this.accountNumber && this.accountType && this.selectedBank.branchCode);
    },
    areBankDetailsVerified(): boolean {
      // need to check for undefined merchant because this loaded in merchant modal stepper
      // and the merchant has not been created until the second step
      return Boolean(this.merchant?.bankDetailsValidation.verified);
    },
  },
  created() {
    this.bankDetailSubmitted = false;
    // Attaching users address to merchant address
    // TODO: Move this to a better place that makes more sense
    if (this.user && this.user.merchantOnboarding) {
      const addr = {
        a: this.user.merchantOnboarding.physicalAddress.streetAddress,
        b: this.user.merchantOnboarding.physicalAddress.city,
        c: this.user.merchantOnboarding.physicalAddress.province,
        d: this.user.merchantOnboarding.physicalAddress.country,
        e: this.user.merchantOnboarding.physicalAddress.postalCode,
      };
      if (addr.a) {
        const updatedMerchant = {
          ...this.merchant,
          address: Object.entries(addr)
            .map((x) => x[1])
            .join(', '),
        };
        this.saveMerchant(updatedMerchant);
      }
    }
    this.name = this.merchant?.name;
  },
  methods: {
    ...mapActions({
      sendBankDetails: 'auth/sendMerchantSupportAndContactTicket',
      fetchMerchant: 'auth/fetchMerchant',
      saveMerchant: 'auth/saveMerchant',
    }),
    contactSupport() {
      this.$modal.show(
        ContactSupportFrom,
        { merchantData: this.merchant },
        {
          scrollable: true,
          height: 'auto',
          adaptive: true,
        },
      );
    },
    goBack() {
      this.$emit('goBack:toContract');
    },
    async submit() {
      this.isLoading = true;
      await this.fetchMerchant(this.merchant._id);
      this.$emit('next:toCreditCard', this.merchant);
    },
    skipToDashboard() {
      this.$emit('dashboard');
    },
    async submitBankDetailsForValidation() {
      this.isLoading = true;
      this.showTryAgainLater = false;
      this.bankDetailSubmitted = false;
      const updatedMerchant = {
        ...this.merchant,
        simulated: this.simulated,
        bankDetailsValidation: {
          bankName: {
            value: this.selectedBank.name,
          },
          account: {
            value: this.accountNumber,
          },
          accountType: {
            value: this.accountType,
          },
          branchCode: {
            value: this.selectedBank.branchCode,
          },
          idNumber: {
            value: this.merchant.registeredEntity,
          },
          email: {
            value: this.user.email,
          },
          clientName: {
            value: this.merchant.name,
          },
          cellNumber: {
            value: this.user.cellNumber,
          },
          initials: {
            value: this.merchant.name[0],
          },
        },
      };
      try {
        await this.saveMerchant(updatedMerchant);
      } catch (error) {
        this.showTryAgainLater = true;
        Vue.$toast.error('Alert!', {
          position: 'top',
          message: 'There was an issue validating your bank details. Please try again.',
        });
      }
      if (this.areBankDetailsVerified) {
        Vue.$toast.success('Alert!', {
          position: 'top',
          message: 'Bank details have been validated successfully..',
        });
      }

      this.bankDetailSubmitted = true;
      this.isLoading = false;
    },
  },
});
