export interface BankFromApi {
  _id: string;
  avsInstitutionCode: string;
  branchCode: string;
  displayName: string;
  name: string;
  shortName: string;
}

export type BanksFromApi = BankFromApi[];

export interface Bank {
  name: string;
  branchCode: string;
}

export type Banks = Bank[];

export interface ICard {
  _id?: string;
  userId: string;
  nameOnCard: string;
  cardType: string;
  cardDigits: string;
  defaultCard: boolean;
  expiryMonth: string;
  expiryYear: string;
  timestamp?: number;
}

export enum InvoiceStatus {
  DUE = 'DUE',
  PROCESSING = 'PROCESSING',
  PAID = 'PAID',
}

export interface IProduct {
  _id: string;
  name: string;
  displayName: string;
  feeType: string;
  source: string;
  method?: string;
  accountingCode: string;
}

export type IFeesPerProduct = {
  quantity: number;
  amountTotal: number;
  amountExcludingVAT: number;
  amountVAT: number;
  product: Partial<IProduct>;
}[];
export interface IInvoice {
  _id: string;
  merchantId: string;
  exVatAmount: number;
  vatAmount: number;
  totalAmount: number;
  settlementId?: string;
  vatPercentage: number;
  settled: boolean;
  reconciled: boolean;
  bankTransactionId: string;
  reference: string; // Our reference to be displayed in the accounting software. ie. 4ADTS-LUFEES
  accountingInvoiceId?: string; // The unique identifier of the invoice in the accounting system. ie. 1234-1234-1234-2134
  accountingInvoiceNumber?: string; // A identifier in the accounting software but its only unique within Layup. ie. INV-123
  journalFeeIds?: string[];
  feesPerProduct?: IFeesPerProduct;
  status?: InvoiceStatus;
  createdAt: string;
  journalFees?: any;
  report?: {
    totalFeesAmount: number;
    totalFeesExtAmount: number;
    totalAmountVAT: number;
    feesPerProductExcludingVATTotal?: number;
    feesPerProductVATTotal?: number;
    feesPerProductTotal?: number;
  };
}
