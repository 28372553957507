
import Vue from 'vue';
import { Component, Prop, Watch, Action, State } from 'nuxt-property-decorator';
import _ from 'lodash';
import { IOrder } from '../../models/orders';
import { currency } from '../../plugins/filters';
import ToggleInput from '~/components/Form/ToggleInput.vue';

@Component({
  components: {
    ToggleInput,
  },
})
export default class Review extends Vue {
  @Prop({}) order: IOrder;
  @Action('orders/preview') previewPlan;
  @Action('orders/createOrder') createOrder;
  @State((state) => state.auth.merchant) merchant: any;
  paymentPlans: any = null;
  debounced: any = null;
  amountDue: number = 0;
  selectionvalue = '';
  cancellaitonError = false;

  addedtimeValue = 0;
  required = false;
  orderLimit = 0;
  selection = [
    {
      value: 'MINUTES',
      key: 'Minute(s)',
    },
    {
      value: 'HOURS',
      key: 'Hour(s)',
    },
    {
      value: 'DAYS',
      key: 'Day(s)',
    },
  ];

  updatePreview() {
    if (this.order) {
      if (this.debounced) {
        this.debounced.cancel();
      }
      this.debounced = _.debounce(async () => {
        this.paymentPlans = await this.previewPlan(this.order);
        this.amountDue = this.calculateFullAmount();
      }, 200);
      this.debounced();
      this.cancellaitonError = false;
    }
  }

  calculateFullAmount() {
    if (this.order.absorbsFee) return this.order.amountDue;
    if (this.order.flatFee && this.order.flatFee > 0) return this.order.amountDue + this.order.flatFee;
    if (this.order.percFee && this.order.percFee > 0)
      return this.order.amountDue + _.round((this.order.amountDue * this.order.percFee) / 100, 2);
    return this.order.amountDue;
  }

  created() {
    // If the order is defined set props to preview endpoint
    this.updatePreview();
  }

  @Watch('order', { deep: true })
  onUpdateOrder() {
    this.updatePreview();
  }

  async onConfirm() {
    this.cancellaitonError = false;
    try {
      this.order.enablesSplit = this.merchant.paymentPlans.splitPayments;
      if (this.order.paymentMethods) this.order.paymentMethods.eft = this.merchant.paymentPlans.paymentMethods.eft;
      if (this.order.paymentMethods)
        this.order.paymentMethods.creditOrDebitCard = this.merchant.paymentPlans.paymentMethods.creditOrDebitCard;
      if (this.order.paymentMethods)
        this.order.paymentMethods.debiCheck = this.merchant.paymentPlans.paymentMethods.debiCheck;
      if (this.order.paymentMethods) this.order.paymentMethods.payAt = this.merchant.paymentPlans.paymentMethods.payAt;
      if (this.addedtimeValue <= 0) {
        if (this.order.groupPaymentPlan)
          this.order.groupPaymentPlan.expiry = this.merchant.paymentPlans.orderExpiry.value;
      }

      if (this.selectionvalue === 'MINUTES') {
        if (this.addedtimeValue >= 0 && this.addedtimeValue <= 86400) {
          if (this.order.groupPaymentPlan) this.order.groupPaymentPlan.expiry = parseInt(this.addedtimeValue + '');
        }
      }
      if (this.selectionvalue === 'DAYS') {
        if (this.addedtimeValue >= 0 && this.addedtimeValue <= 60) {
          const hours = this.addedtimeValue * 24;
          const mins = hours * 60;
          if (this.order.groupPaymentPlan) this.order.groupPaymentPlan.expiry = mins;
        }
      }
      if (this.selectionvalue === 'HOURS') {
        if (this.addedtimeValue >= 0 && this.addedtimeValue <= 1440) {
          const mins = this.addedtimeValue * 60;
          if (this.order.groupPaymentPlan) this.order.groupPaymentPlan.expiry = mins;
        }
      }

      if (this.orderLimit) {
        if (this.order.groupPaymentPlan) this.order.groupPaymentPlan.cloneLimit = parseInt(this.orderLimit + '');
      }

      console.log('Order: ', this.order);
      const order = await this.createOrder(this.order);
      this.$emit('reviewed:order', order);
    } catch (error) {
      this.cancellaitonError = true;
    }
  }

  get paymentPlanBreakdown() {
    // Find the longest plan
    if (this.paymentPlans) {
      const last = this.paymentPlans.length - 1;
      const plan = this.paymentPlans[last];
      const amount = plan.payments[plan.payments.length - 1].amount;
      const months = plan.months;
      return `${currency(amount)}/mo for ${months} months`;
    }
    return null;
  }

  @Watch('order.groupPaymentPlan.enabled')
  groupPaymentPlanSelect() {
    if (this.order.groupPaymentPlan) this.order.groupPaymentPlan.enabled != this.order.groupPaymentPlan.enabled;
  }

  goBack() {
    this.$emit('goBack:order');
  }

  isNumber(evt) {
    evt = evt || window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  checkTimeType() {
    if (this.addedtimeValue && !this.selectionvalue) {
      return false;
    }
    if (!this.addedtimeValue && this.selectionvalue) {
      return false;
    }
    if (this.order.groupPaymentPlan && this.order.groupPaymentPlan.enabled && this.addedtimeValue <= 0) {
      this.required = true;
      return false;
    } else if (this.order.groupPaymentPlan && this.order.groupPaymentPlan.enabled && this.addedtimeValue > 0) {
      this.required = false;
      return true;
    }

    if (
      this.order.groupPaymentPlan &&
      this.order.groupPaymentPlan.enabled &&
      this.selectionvalue &&
      this.addedtimeValue
    ) {
      if (this.selectionvalue === 'MINUTES') {
        if (this.addedtimeValue >= 0 && this.addedtimeValue <= 86400) {
          if (this.order.groupPaymentPlan) this.order.groupPaymentPlan.expiry = parseInt(this.addedtimeValue + '');
        } else {
          Vue.$toast.error('Alert!', {
            position: 'top',
            message: 'Minutes cannot less than 0 or exceed 86400.',
          });
          this.addedtimeValue = 0;
          return false;
        }
      }
      if (this.selectionvalue === 'DAYS') {
        if (this.addedtimeValue >= 0 && this.addedtimeValue <= 60) {
          const hours = this.addedtimeValue * 24;
          const mins = hours * 60;
          if (this.order.groupPaymentPlan) this.order.groupPaymentPlan.expiry = mins;
        } else {
          Vue.$toast.error('Alert!', {
            position: 'top',
            message: 'Days cannot be less than 0 or exceed 60.',
          });
          this.addedtimeValue = 0;
          return false;
        }
      }
      if (this.selectionvalue === 'HOURS') {
        if (this.addedtimeValue >= 0 && this.addedtimeValue <= 1440) {
          const mins = this.addedtimeValue * 60;
          if (this.order.groupPaymentPlan) this.order.groupPaymentPlan.expiry = mins;
        } else {
          Vue.$toast.error('Alert!', {
            position: 'top',
            message: 'Hours cannot be less than 0 or exceed 1440.',
          });
          this.addedtimeValue = 0;
          return false;
        }
      }
    }
    return true;
  }
}
