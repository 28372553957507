
import Vue from 'vue';
import * as _ from 'lodash';
import { mapActions, mapState } from 'vuex';

export default Vue.extend({
  props: {
    onboarding: {
      type: Object as any,
      default: () => {},
    },
  },
  data() {
    return {
      platforms: [
        {
          key: 'WooCommerce',
          value: 'WooCommerce',
        },
        {
          key: 'Shopify',
          value: 'Shopify',
        },
        {
          key: 'Magento',
          value: 'Magento',
        },
        {
          key: 'WordPress',
          value: 'WordPress',
        },
        {
          key: 'Wix',
          value: 'Wix',
        },
        {
          key: 'Ecwid',
          value: 'Ecwid',
        },
        {
          key: 'Custom Build',
          value: 'Custom Build',
        },
        {
          key: 'In-store',
          value: 'In-store',
        },
        {
          key: 'No website/not applicable',
          value: 'No website/not applicable',
        },
        {
          key: 'Other',
          value: 'Other',
        },
      ],
      platform: '',
      onboardingMerchant: {} as any,
      notifyUrl: 'http://',
      integrate: false,
      integration: '',
    };
  },
  computed: {
    ...mapState('auth', ['user', 'merchant']),
    isNotifyUrlValid(): boolean {
      if (this.notifyUrl && !this.notifyUrl.includes('http://') && !this.notifyUrl.includes('https://')) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.integrate = false;
    if (!_.isEmpty(this.merchant)) {
      this.onboardingMerchant = _.merge(this.onboardingMerchant, this.merchant);
    } else {
      this.onboardingMerchant = _.merge(this.onboardingMerchant, this.onboarding);
    }
  },
  methods: {
    ...mapActions({ signContract: 'auth/signContract', fetchMerchant: 'auth/fetchMerchant' }),
    nextToContract() {
      if (this.platform) {
        switch (this.platform) {
          case 'WooCommerce':
            this.onboardingMerchant.notifyUrl = `${this.notifyUrl}/wc-api/WC_Layup_Gateway`;
            break;
          case 'Shopify':
            this.onboardingMerchant.notifyUrl = `${this.notifyUrl}/2/webhooks/${this.onboardingMerchant._id}`;
            break;
          case 'Magento':
            this.onboardingMerchant.notifyUrl = `${this.notifyUrl}/rest/V1/layup/webhook`;
            break;
          default:
            this.onboardingMerchant.notifyUrl = null;
            break;
        }
      }

      if (this.notifyUrl && this.notifyUrl !== 'http://') {
        this.onboardingMerchant.domain = new URL(this.notifyUrl).hostname;
      }
      this.onboardingMerchant.onboardingState = 'e-commerce selected';
      const data = JSON.parse(JSON.stringify(this.onboardingMerchant));
      this.$emit('submit:updateMerchant', data);
      this.$emit('next:toSettlement', this.onboardingMerchant);
    },
    goBack() {
      this.$emit('goBack:registration');
    },
    proceed() {
      if (this.platform === 'No website/not applicable' || this.platform === 'Other' || this.platform === 'In-store')
        return true;
      if (this.platform && this.integrate && this.notifyUrl && this.notifyUrl !== 'http://') return true;
      if (this.notifyUrl === 'http://' && !this.integrate && this.integration === 'no') return true;
      return false;
    },
    skipToDashboard() {
      this.$emit('dashboard');
    },
    integrateECommerce(val: string) {
      this.integration = val;
      if (val.toLowerCase() === 'yes') {
        this.integrate = true;
      } else if (val.toLowerCase() === 'no') {
        this.integrate = false;
      }
    },
  },
});
