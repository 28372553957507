export enum DepositType {
  Percentage = 'PERCENTAGE',
  Flat = 'FLAT',
  Instalment = 'INSTALMENT',
}

export interface DepositTypeObject {
  key: string;
  value: DepositType;
}

export type DepositTypeObjects = DepositTypeObject[];
