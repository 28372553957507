
import Vue from 'vue';
import { Component } from 'nuxt-property-decorator';
import { ValidationProvider } from 'vee-validate';
import VInput from './VInput.vue';

@Component({
  extends: VInput,
  components: {
    ValidationProvider,
  },
})
export default class VTextarea extends Vue {}
