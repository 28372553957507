import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1266bccc = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _4b891916 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _495224ce = () => interopDefault(import('../pages/email-screen.vue' /* webpackChunkName: "pages/email-screen" */))
const _6d942eb3 = () => interopDefault(import('../pages/email-verification-success.vue' /* webpackChunkName: "pages/email-verification-success" */))
const _eb2a2016 = () => interopDefault(import('../pages/enter-otp.vue' /* webpackChunkName: "pages/enter-otp" */))
const _450c6af8 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _4aa13d36 = () => interopDefault(import('../pages/mobile-screen.vue' /* webpackChunkName: "pages/mobile-screen" */))
const _a92d4046 = () => interopDefault(import('../pages/password-screen.vue' /* webpackChunkName: "pages/password-screen" */))
const _01160598 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _07ef23ac = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _02c878d9 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _e83ae396 = () => interopDefault(import('../pages/verify-mobile.vue' /* webpackChunkName: "pages/verify-mobile" */))
const _9f28a618 = () => interopDefault(import('../pages/dashboard/Cancellations.vue' /* webpackChunkName: "pages/dashboard/Cancellations" */))
const _3b3d2b78 = () => interopDefault(import('../pages/dashboard/Contact.vue' /* webpackChunkName: "pages/dashboard/Contact" */))
const _702b0c3a = () => interopDefault(import('../pages/dashboard/Faq.vue' /* webpackChunkName: "pages/dashboard/Faq" */))
const _60f1f728 = () => interopDefault(import('../pages/dashboard/grouped-payments.vue' /* webpackChunkName: "pages/dashboard/grouped-payments" */))
const _2ebc2556 = () => interopDefault(import('../pages/dashboard/invoices/index.vue' /* webpackChunkName: "pages/dashboard/invoices/index" */))
const _f159d9f2 = () => interopDefault(import('../pages/dashboard/payments-register.vue' /* webpackChunkName: "pages/dashboard/payments-register" */))
const _1eaa8ab1 = () => interopDefault(import('../pages/dashboard/plans/index.vue' /* webpackChunkName: "pages/dashboard/plans/index" */))
const _f1c18926 = () => interopDefault(import('../pages/dashboard/profile.vue' /* webpackChunkName: "pages/dashboard/profile" */))
const _2e248c5b = () => interopDefault(import('../pages/dashboard/profile/BillingAndFees.vue' /* webpackChunkName: "pages/dashboard/profile/BillingAndFees" */))
const _6ceba32c = () => interopDefault(import('../pages/dashboard/profile/credit-card/AddNewCard.vue' /* webpackChunkName: "pages/dashboard/profile/credit-card/AddNewCard" */))
const _2fa82424 = () => interopDefault(import('../pages/dashboard/profile/credit-card/NewDefaultCard.vue' /* webpackChunkName: "pages/dashboard/profile/credit-card/NewDefaultCard" */))
const _95a0acec = () => interopDefault(import('../pages/dashboard/profile/credit-card/SavedCard.vue' /* webpackChunkName: "pages/dashboard/profile/credit-card/SavedCard" */))
const _1a4fbeec = () => interopDefault(import('../pages/dashboard/profile/credit-card/SetDefaultCard.vue' /* webpackChunkName: "pages/dashboard/profile/credit-card/SetDefaultCard" */))
const _364f1f0f = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _7d21d415 = () => interopDefault(import('../pages/dashboard/settlement.vue' /* webpackChunkName: "pages/dashboard/settlement" */))
const _2f91619c = () => interopDefault(import('../pages/dashboard/settlement-report.vue' /* webpackChunkName: "pages/dashboard/settlement-report" */))
const _456ad290 = () => interopDefault(import('../pages/dashboard/settlement-report/Fees.vue' /* webpackChunkName: "pages/dashboard/settlement-report/Fees" */))
const _495425f0 = () => interopDefault(import('../pages/dashboard/settlement-report/Orders.vue' /* webpackChunkName: "pages/dashboard/settlement-report/Orders" */))
const _5674de90 = () => interopDefault(import('../pages/dashboard/settlement-report/Payments.vue' /* webpackChunkName: "pages/dashboard/settlement-report/Payments" */))
const _5cac2b8c = () => interopDefault(import('../pages/dashboard/settlement-report/PopoverOrder.vue' /* webpackChunkName: "pages/dashboard/settlement-report/PopoverOrder" */))
const _976cf2da = () => interopDefault(import('../pages/dashboard/settlement-report/Summary.vue' /* webpackChunkName: "pages/dashboard/settlement-report/Summary" */))
const _2ac44fae = () => interopDefault(import('../pages/dashboard/settlements.vue' /* webpackChunkName: "pages/dashboard/settlements" */))
const _0e2fae93 = () => interopDefault(import('../pages/dashboard/support.vue' /* webpackChunkName: "pages/dashboard/support" */))
const _6bd95de3 = () => interopDefault(import('../pages/dashboard/ViewContract.vue' /* webpackChunkName: "pages/dashboard/ViewContract" */))
const _d6973cfa = () => interopDefault(import('../pages/dashboard/settings-models/API.vue' /* webpackChunkName: "pages/dashboard/settings-models/API" */))
const _7a8f66f9 = () => interopDefault(import('../pages/dashboard/settings-models/Cancellations.vue' /* webpackChunkName: "pages/dashboard/settings-models/Cancellations" */))
const _52aa9298 = () => interopDefault(import('../pages/dashboard/settings-models/ClientCommunication.vue' /* webpackChunkName: "pages/dashboard/settings-models/ClientCommunication" */))
const _17f8442d = () => interopDefault(import('../pages/dashboard/settings-models/constants.ts' /* webpackChunkName: "pages/dashboard/settings-models/constants" */))
const _ec308508 = () => interopDefault(import('../pages/dashboard/settings-models/Customization.vue' /* webpackChunkName: "pages/dashboard/settings-models/Customization" */))
const _e81afa06 = () => interopDefault(import('../pages/dashboard/settings-models/Customization/defaultImage.vue' /* webpackChunkName: "pages/dashboard/settings-models/Customization/defaultImage" */))
const _37af2f8e = () => interopDefault(import('../pages/dashboard/settings-models/Customization/logo.vue' /* webpackChunkName: "pages/dashboard/settings-models/Customization/logo" */))
const _3cb60dc2 = () => interopDefault(import('../pages/dashboard/settings-models/Customization/PaymentWidgetLogo.vue' /* webpackChunkName: "pages/dashboard/settings-models/Customization/PaymentWidgetLogo" */))
const _c46061ec = () => interopDefault(import('../pages/dashboard/settings-models/Customization/ReviewWidgetLogo.vue' /* webpackChunkName: "pages/dashboard/settings-models/Customization/ReviewWidgetLogo" */))
const _de083e28 = () => interopDefault(import('../pages/dashboard/settings-models/EditModal.vue' /* webpackChunkName: "pages/dashboard/settings-models/EditModal" */))
const _a303e448 = () => interopDefault(import('../pages/dashboard/settings-models/EditUser.vue' /* webpackChunkName: "pages/dashboard/settings-models/EditUser" */))
const _5e469818 = () => interopDefault(import('../pages/dashboard/settings-models/NewUser.vue' /* webpackChunkName: "pages/dashboard/settings-models/NewUser" */))
const _49911811 = () => interopDefault(import('../pages/dashboard/settings-models/Notifications.vue' /* webpackChunkName: "pages/dashboard/settings-models/Notifications" */))
const _6625dbc1 = () => interopDefault(import('../pages/dashboard/settings-models/Organisation.vue' /* webpackChunkName: "pages/dashboard/settings-models/Organisation" */))
const _372f41eb = () => interopDefault(import('../pages/dashboard/settings-models/PaymentPlans.vue' /* webpackChunkName: "pages/dashboard/settings-models/PaymentPlans" */))
const _dd923716 = () => interopDefault(import('../pages/dashboard/settings-models/RequestChangeModal.vue' /* webpackChunkName: "pages/dashboard/settings-models/RequestChangeModal" */))
const _30e8766a = () => interopDefault(import('../pages/dashboard/settings-models/SecuritySettings.vue' /* webpackChunkName: "pages/dashboard/settings-models/SecuritySettings" */))
const _3abc62c4 = () => interopDefault(import('../pages/dashboard/settings-models/Team.vue' /* webpackChunkName: "pages/dashboard/settings-models/Team" */))
const _7224e443 = () => interopDefault(import('../pages/dashboard/settings-models/types.ts' /* webpackChunkName: "pages/dashboard/settings-models/types" */))
const _28d7ee3a = () => interopDefault(import('../pages/dashboard/settings-models/notification-modals/AddEmails.vue' /* webpackChunkName: "pages/dashboard/settings-models/notification-modals/AddEmails" */))
const _321e9791 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _376a55c0 = () => interopDefault(import('../pages/dashboard/invoices/_id/index.vue' /* webpackChunkName: "pages/dashboard/invoices/_id/index" */))
const _5a2635c8 = () => interopDefault(import('../pages/dashboard/plans/_id/index.vue' /* webpackChunkName: "pages/dashboard/plans/_id/index" */))
const _0181a28d = () => interopDefault(import('../pages/dashboard/plans/_id/_planId.vue' /* webpackChunkName: "pages/dashboard/plans/_id/_planId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active-link',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _1266bccc,
    name: "about"
  }, {
    path: "/dashboard",
    component: _4b891916,
    name: "dashboard"
  }, {
    path: "/email-screen",
    component: _495224ce,
    name: "email-screen"
  }, {
    path: "/email-verification-success",
    component: _6d942eb3,
    name: "email-verification-success"
  }, {
    path: "/enter-otp",
    component: _eb2a2016,
    name: "enter-otp"
  }, {
    path: "/forgot-password",
    component: _450c6af8,
    name: "forgot-password"
  }, {
    path: "/mobile-screen",
    component: _4aa13d36,
    name: "mobile-screen"
  }, {
    path: "/password-screen",
    component: _a92d4046,
    name: "password-screen"
  }, {
    path: "/register",
    component: _01160598,
    name: "register"
  }, {
    path: "/reset-password",
    component: _07ef23ac,
    name: "reset-password"
  }, {
    path: "/verify-email",
    component: _02c878d9,
    name: "verify-email"
  }, {
    path: "/verify-mobile",
    component: _e83ae396,
    name: "verify-mobile"
  }, {
    path: "/dashboard/Cancellations",
    component: _9f28a618,
    name: "dashboard-Cancellations"
  }, {
    path: "/dashboard/Contact",
    component: _3b3d2b78,
    name: "dashboard-Contact"
  }, {
    path: "/dashboard/Faq",
    component: _702b0c3a,
    name: "dashboard-Faq"
  }, {
    path: "/dashboard/grouped-payments",
    component: _60f1f728,
    name: "dashboard-grouped-payments"
  }, {
    path: "/dashboard/invoices",
    component: _2ebc2556,
    name: "dashboard-invoices"
  }, {
    path: "/dashboard/payments-register",
    component: _f159d9f2,
    name: "dashboard-payments-register"
  }, {
    path: "/dashboard/plans",
    component: _1eaa8ab1,
    name: "dashboard-plans"
  }, {
    path: "/dashboard/profile",
    component: _f1c18926,
    name: "dashboard-profile",
    children: [{
      path: "BillingAndFees",
      component: _2e248c5b,
      name: "dashboard-profile-BillingAndFees"
    }, {
      path: "credit-card/AddNewCard",
      component: _6ceba32c,
      name: "dashboard-profile-credit-card-AddNewCard"
    }, {
      path: "credit-card/NewDefaultCard",
      component: _2fa82424,
      name: "dashboard-profile-credit-card-NewDefaultCard"
    }, {
      path: "credit-card/SavedCard",
      component: _95a0acec,
      name: "dashboard-profile-credit-card-SavedCard"
    }, {
      path: "credit-card/SetDefaultCard",
      component: _1a4fbeec,
      name: "dashboard-profile-credit-card-SetDefaultCard"
    }]
  }, {
    path: "/dashboard/settings",
    component: _364f1f0f,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/settlement",
    component: _7d21d415,
    name: "dashboard-settlement"
  }, {
    path: "/dashboard/settlement-report",
    component: _2f91619c,
    name: "dashboard-settlement-report",
    children: [{
      path: "Fees",
      component: _456ad290,
      name: "dashboard-settlement-report-Fees"
    }, {
      path: "Orders",
      component: _495425f0,
      name: "dashboard-settlement-report-Orders"
    }, {
      path: "Payments",
      component: _5674de90,
      name: "dashboard-settlement-report-Payments"
    }, {
      path: "PopoverOrder",
      component: _5cac2b8c,
      name: "dashboard-settlement-report-PopoverOrder"
    }, {
      path: "Summary",
      component: _976cf2da,
      name: "dashboard-settlement-report-Summary"
    }]
  }, {
    path: "/dashboard/settlements",
    component: _2ac44fae,
    name: "dashboard-settlements"
  }, {
    path: "/dashboard/support",
    component: _0e2fae93,
    name: "dashboard-support"
  }, {
    path: "/dashboard/ViewContract",
    component: _6bd95de3,
    name: "dashboard-ViewContract"
  }, {
    path: "/dashboard/settings-models/API",
    component: _d6973cfa,
    name: "dashboard-settings-models-API"
  }, {
    path: "/dashboard/settings-models/Cancellations",
    component: _7a8f66f9,
    name: "dashboard-settings-models-Cancellations"
  }, {
    path: "/dashboard/settings-models/ClientCommunication",
    component: _52aa9298,
    name: "dashboard-settings-models-ClientCommunication"
  }, {
    path: "/dashboard/settings-models/constants",
    component: _17f8442d,
    name: "dashboard-settings-models-constants"
  }, {
    path: "/dashboard/settings-models/Customization",
    component: _ec308508,
    name: "dashboard-settings-models-Customization",
    children: [{
      path: "defaultImage",
      component: _e81afa06,
      name: "dashboard-settings-models-Customization-defaultImage"
    }, {
      path: "logo",
      component: _37af2f8e,
      name: "dashboard-settings-models-Customization-logo"
    }, {
      path: "PaymentWidgetLogo",
      component: _3cb60dc2,
      name: "dashboard-settings-models-Customization-PaymentWidgetLogo"
    }, {
      path: "ReviewWidgetLogo",
      component: _c46061ec,
      name: "dashboard-settings-models-Customization-ReviewWidgetLogo"
    }]
  }, {
    path: "/dashboard/settings-models/EditModal",
    component: _de083e28,
    name: "dashboard-settings-models-EditModal"
  }, {
    path: "/dashboard/settings-models/EditUser",
    component: _a303e448,
    name: "dashboard-settings-models-EditUser"
  }, {
    path: "/dashboard/settings-models/NewUser",
    component: _5e469818,
    name: "dashboard-settings-models-NewUser"
  }, {
    path: "/dashboard/settings-models/Notifications",
    component: _49911811,
    name: "dashboard-settings-models-Notifications"
  }, {
    path: "/dashboard/settings-models/Organisation",
    component: _6625dbc1,
    name: "dashboard-settings-models-Organisation"
  }, {
    path: "/dashboard/settings-models/PaymentPlans",
    component: _372f41eb,
    name: "dashboard-settings-models-PaymentPlans"
  }, {
    path: "/dashboard/settings-models/RequestChangeModal",
    component: _dd923716,
    name: "dashboard-settings-models-RequestChangeModal"
  }, {
    path: "/dashboard/settings-models/SecuritySettings",
    component: _30e8766a,
    name: "dashboard-settings-models-SecuritySettings"
  }, {
    path: "/dashboard/settings-models/Team",
    component: _3abc62c4,
    name: "dashboard-settings-models-Team"
  }, {
    path: "/dashboard/settings-models/types",
    component: _7224e443,
    name: "dashboard-settings-models-types"
  }, {
    path: "/dashboard/settings-models/notification-modals/AddEmails",
    component: _28d7ee3a,
    name: "dashboard-settings-models-notification-modals-AddEmails"
  }, {
    path: "/",
    component: _321e9791,
    name: "index"
  }, {
    path: "/dashboard/invoices/:id",
    component: _376a55c0,
    name: "dashboard-invoices-id"
  }, {
    path: "/dashboard/plans/:id",
    component: _5a2635c8,
    name: "dashboard-plans-id"
  }, {
    path: "/dashboard/plans/:id/:planId",
    component: _0181a28d,
    name: "dashboard-plans-id-planId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
