
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      isLoading: false,
      registrationNumber: '',
      vatNo: '',
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters({
      merchant: 'auth/merchant',
    }),
    proceed(): boolean {
      return Boolean(this.registrationNumber);
    },
  },
  methods: {
    goBack() {
      this.$emit('goBack:account');
    },
    nextToBanking() {
      this.isLoading = true;
      const updatedMerchant = {
        ...this.merchant,
        vatNo: this.vatNo,
        registeredEntity: this.registrationNumber,
        onboardingState: 'registered',
      };
      this.$emit('submit:updateMerchant', updatedMerchant);
      this.$emit('next:toBanking', updatedMerchant);
      this.isLoading = false;
    },
    skipToDashboard() {
      this.$emit('dashboard');
    },
    validateCompanyEntity(entity: string) {
      const re = /^((?:[0-9]{4})\/(?:[0-9]{6})\/(?:[0-9]{2}))$/;
      if (entity) {
        return re.test(entity);
      }
      return true;
    },
    insertSlash(val: string) {
      return val.replace(/^(\d{4})(\d{6})/, '$1/$2/');
    },
  },
});
