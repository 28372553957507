export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateMobile(mobile: string): boolean {
  if (!mobile) {
    return false;
  }
  const cleaned = mobile.replace(/[\s+()-.]/g, '');
  const isNumber = /^\d+$/.test(cleaned);
  if (!isNumber) {
    return false;
  }
  if (cleaned.length === 10 && cleaned[0] === '0') {
    return true;
  }
  return cleaned.length >= 9 && cleaned.length <= 13;
}

export function pick(array: string[], collection: { [index: string]: any }) {
  return array.reduce((res: { [index: string]: any }, key: string) => {
    // eslint-disable-next-line no-param-reassign
    res[key] = collection[key];
    return res;
  }, {} as object);
}

export function currencyZAR(amount: any, modifier = 100) {
  if (amount === null) return '';
  const amountReg = amount.toString().match(/[\d.]+/);
  if (amountReg) {
    amount = amountReg[0];
  } else {
    amount = '';
  }

  return new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
  }).format(amount / modifier);
}

export function createSentryException(error: any, $sentry) {
  const MODE = process.env.VUE_ENVIRONMENT;
  const exception = error.error || error.message || error.originalError || error;
  const isLocalDevelopment = MODE !== 'production' && MODE !== 'staging' && MODE !== 'sandbox';

  if (isLocalDevelopment) {
    console.error(exception);
    return;
  }

  $sentry.captureException(exception);
}

export const getTerminalCardProduct = (products: []) =>
  products.find((product: any) => ['paymentMethodDashpay', 'paymentMethodNedbank'].includes(product.name));

export const isTerminalCardSelected = (method: string): boolean => {
  if (['dashpay', 'nedbank'].includes(method)) return true;
  return false;
};

export const checkAvailablePaymentMethodsInPriceList = (products: []) => {
  const defaultTooltip = 'No price available, please contact support';
  const paymentMethods = {
    capitecPay: {
      tooltip: defaultTooltip,
      available: false,
    },
    creditCard: {
      tooltip: defaultTooltip,
      available: false,
    },
    eft: {
      tooltip: defaultTooltip,
      available: false,
    },
    debicheck: {
      tooltip: defaultTooltip,
      available: false,
    },
    payAt: {
      tooltip: defaultTooltip,
      available: false,
    },
    terminalCard: {
      tooltip: defaultTooltip,
      available: false,
    },
  };

  // For old merchants without a price list, enable all payment methods
  if (products == null) {
    paymentMethods.capitecPay = {
      available: true,
      tooltip: 'Allow users to pay using Capitec Pay',
    };
    paymentMethods.creditCard = {
      available: true,
      tooltip: 'Allow users to pay using a credit or debit card',
    };
    paymentMethods.eft = {
      available: true,
      tooltip: 'Allow users to pay using Instant EFt',
    };
    paymentMethods.debicheck = {
      available: true,
      tooltip: 'Allow users to pay using an authorised debit order',
    };
    paymentMethods.payAt = {
      available: true,
      tooltip: 'Allow users to pay using Pay@',
    };
    paymentMethods.terminalCard = {
      available: true,
      tooltip: 'Allow users to pay using terminal card payment',
    };
    return paymentMethods;
  }

  products?.forEach((product: any) => {
    if (product.name.includes('paymentMethodCapitecPay')) {
      paymentMethods.capitecPay = {
        available: true,
        tooltip: 'Allow users to pay using Capitec Pay',
      };
    }
    if (product.name.includes('paymentMethodPeachCard')) {
      paymentMethods.creditCard = {
        available: true,
        tooltip: 'Allow users to pay using a credit or debit card',
      };
    }
    if (product.name.includes('paymentMethodPeachEft')) {
      paymentMethods.eft = {
        available: true,
        tooltip: 'Allow users to pay using Instant EFt',
      };
    }
    if (product.name.includes('paymentMethodDebicheck')) {
      paymentMethods.debicheck = {
        available: true,
        tooltip: 'Allow users to pay using an authorised debit order',
      };
    }
    if (product.name.includes('paymentMethodPayat')) {
      paymentMethods.payAt = {
        available: true,
        tooltip: 'Allow users to pay using Pay@',
      };
    }
    if (product.name.includes('paymentMethodDashpay') || product.name.includes('paymentMethodNedbank')) {
      paymentMethods.terminalCard = {
        available: true,
        tooltip: 'Allow users to pay using terminal card payment',
      };
    }
  });
  return paymentMethods;
};

export const getPaymentMethod = (payment) => {
  if (payment?.connectorData?.paymentMethod) {
    return payment.connectorData.paymentMethod;
  }
  return 'NOT AVAILABLE';
};

export const getPaymentProvider = (payment) => {
  if (payment.connectorData?.paymentProvider) {
    return payment.connectorData?.paymentProvider;
  }
  return 'NOT AVAILABLE';
};

export const checkIfCardIsValid = (card): boolean => {
  if (!card) return false;
  if (!card.nameOnCard) return false;
  if (!card.expiryYear) return false;
  if (!card.expiryMonth) return false;
  if (!card.cardDigits) return false;
  if (!card.cardType) return false;
  return true;
};
