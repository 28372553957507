import moment from 'moment';
import * as PaymentPlans from './payment-plans';

export interface Product {
  amount: number;
  link?: string;
  sku: string;
  name: string;
}

export interface OrderCreationRequest {
  depositAmount: number;
  amount: number;
  products: Product[];
  endDateMax: string;
  endDateMin: string;
  state: 'PARTIAL' | 'PLACED' | 'COMPLETED' | 'CANCELLED' | 'CANCELLING';
  depositPerc: number;
  reference: string;
  name: string;
  imageUrl: string;
  enablesSplit: boolean;
  depositType: 'PERCENTAGE' | 'FLAT' | 'INSTALMENT';
}

export interface IOrderRequest {
  filter?: any;
  sort?: string;
  skip?: number;
  limit?: number;
  select?: string;
}

interface IBenefactor {
  _id: string;
  name: string;
}

export interface IOrder {
  _id?: string;
  name?: string;
  amountDue: number;
  endDateMax: string;
  endDateMin?: string;
  initiatorId?: string;
  products: Product[];
  state: 'PARTIAL' | 'PLACED' | 'COMPLETED' | 'CANCELLED' | 'CANCELLING' | 'EXPIRED' | 'SUSPENDED';
  supplierId?: string;
  plans?: PaymentPlans.IPaymentPlan[];
  flatFee?: number;
  percFee?: number;
  depositPerc: number;
  depositAmount?: number;
  depositType: 'PERCENTAGE' | 'FLAT' | 'INSTALMENT';
  absorbsFee?: boolean;
  reference?: string;
  benefactor?: IBenefactor;
  enablesSplit?: boolean;
  payments?: any;
  expire?: number;
  imageUrl?: string;
  paymentMethods?: {
    eft?: Boolean;
    creditOrDebitCard?: Boolean;
    debiCheck?: Boolean;
    payAt?: Boolean;
  };
  groupPaymentPlan?: {
    enabled: Boolean;
    cloneLimit: number;
    expiry: number;
  };
  customFeatures?: {
    logo?: {
      enabled: boolean;
      value: string;
    };
    imageUrl?: {
      enabled: boolean;
      value: string;
    };
    paymentWidget?: {
      enabled: boolean;
      value: string;
    };
  };
  supplier?: any;
  orderRefundRequest?: string;
  orderRefundAmount?: number;
  orderRefundFee?: number;
  suspendOnCancel?: boolean;
}

export interface IOrderShareRequest {
  data: {
    type?: string;
    orderId?: string;
    orderUrl?: string;
    contact?: string;
    message?: string;
    sendType?: 'email' | 'sms';
  };
}

export function generateOrder(): IOrder {
  return {
    amountDue: 0,
    endDateMax: moment().format('YYYY-MM-DD'),
    endDateMin: moment().format('YYYY-MM-DD'),
    products: [],
    state: 'PARTIAL',
    depositPerc: 20,
    depositType: 'PERCENTAGE',
    absorbsFee: true,
    percFee: undefined,
    flatFee: undefined,
    imageUrl: '',
    groupPaymentPlan: {
      enabled: false,
      cloneLimit: 0,
      expiry: 0,
    },
  } as IOrder;
}
