
import Vue from 'vue';
import * as _ from 'lodash';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'CompleteOnboarding',
  props: {
    onboarding: {
      type: Object as any,
      default() {
        return {} as any;
      },
    },
  },
  data() {
    return {
      onboardingMerchant: {} as any,
      loading: false,
    };
  },
  computed: {
    ...mapState('auth', ['user', 'merchant']),
  },
  created() {
    if (!_.isEmpty(this.merchant)) {
      this.onboardingMerchant = _.merge(this.merchant, this.onboardingMerchant);
    } else {
      this.onboardingMerchant = _.merge(this.onboardingMerchant, this.onboarding);
    }
  },
  methods: {
    done() {
      this.loading = true;
      if (!_.isEmpty(this.merchant)) {
        this.onboardingMerchant = this.merchant;
      } else {
        this.onboardingMerchant = this.onboarding;
      }

      this.onboardingMerchant.onboardingState = 'complete';
      this.onboardingMerchant.merchantContractSigned = 'complete & signed';
      this.$emit('submit:updateMerchant', this.onboardingMerchant);
      setTimeout(() => {
        document.location.href = '/dashboard';
      }, 3000);
    },
  },
});
