import { render, staticRenderFns } from "./SettlementStructure.vue?vue&type=template&id=0b2f86d6&scoped=true&lang=pug&"
import script from "./SettlementStructure.vue?vue&type=script&lang=ts&"
export * from "./SettlementStructure.vue?vue&type=script&lang=ts&"
import style0 from "./SettlementStructure.vue?vue&type=style&index=0&id=0b2f86d6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b2f86d6",
  null
  
)

export default component.exports