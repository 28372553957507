
import Vue from 'vue';
import { isMobileOnly } from 'mobile-device-detect';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { IOrder } from '../../models/orders';
import Create from './Create.vue';
import Review from './Review.vue';
import Share from './Share.vue';

export default Vue.extend({
  name: 'CreateOrderModal',
  components: {
    Create,
    Review,
    Share,
  },
  props: {
    duplicateOrder: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: 'create',
      create: false,
      review: false,
      share: false,
      order: null as IOrder | null,
      completeOrder: null as IOrder | null,
      isMobile: isMobileOnly,
      disableTabs: true,
    };
  },
  computed: {
    ...mapGetters('auth', {
      merchantAccount: 'merchant',
    }),
  },
  methods: {
    setDone(id, next) {
      this[id] = true;
      if (next) {
        this.active = next;
      }
    },
    goBack() {
      this.setDone('created', 're');
    },
    nextToReview(order) {
      this.order = { ...order, flatFee: this.merchantAccount.flatFee, percFee: this.merchantAccount.percFee };
      this.setDone('created', 'review');
    },
    orderCreateDone(order) {
      this.order = { ...order, flatFee: this.merchantAccount.flatFee, percFee: this.merchantAccount.percFee };
    },
    orderReviewed(order) {
      this.completeOrder = _.clone(order);
      this.disableTabs = false;
      this.setDone('review', 'share');
    },
    orderDone() {
      this.$emit('close');
    },
  },
});
