
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import * as _ from 'lodash';
import { currencyZAR, getTerminalCardProduct, isTerminalCardSelected } from '~/utils/utils';

export default Vue.extend({
  props: {
    onboarding: {
      type: Object as any,
      default() {
        return {} as any;
      },
    },
    priceList: {
      type: Object as any,
      default() {
        return {} as any;
      },
    },
  },
  data() {
    return {
      isTerminalCardSelected,
      paymentMethods: [] as any,
      paymentPlans: [] as any,
      paymentPlanType: [] as any,
      paymentPlanState: [] as any,
      transfer: {} as any,
      refund: {} as any,
      accounts: [] as any,
      settlements: [] as any,
      prices: [] as any,
      onboardingMerchant: {} as any,
      loading: false,
      paymentMethodsTooltips: {
        peachEft:
          'An electronic funds transfer, is a method where customers can pay for goods or services by electronically transferring funds from their bank account using their internet banking login details during the checkout process.',
        peachCard:
          'A debit/credit card payment is a method where customers can pay for goods or services using a plastic card that is linked to a checking or credit account, allowing for electronic funds transfer.',
        debicheck:
          'Debicheck allows a debit order mandaate to be set up in realtime. A debit order is a method where customers authorise a merchant to automatically deduct a set amount from their bank account on a specified date for a specified period, allowing for recurring payments without the need for manual transactions. Using DEbicheck the customer authorises the mandate in realtime utilising internet banking or a mobile banking app',
        payat: 'pay at',
      },
      paymentPlanStateTooltips: {
        suspended: 'A suspended payment plan is one that has been temporarily paused and may resume in the future.',
        completed: 'A completed payment plan is one that has been fully paid off and completed.',
        cancelled:
          'A canceled payment plan is one that has been terminated and will not continue, potentially with a refund or credit issued.',
        placed:
          'A payment plan that has been initiated and is now active. A deposit payment was made, and the payment plan is currently ongoing, with the remaining payments scheduled for future dates.',
      },
      accountTypeTooltips: {
        pro: 'Offer additional features, services, and benefits, at a higher cost.',
        standard: 'More basic set of features and services, at a lower cost.',
        custom:
          'Tailored to the specific needs and requirements of the merchant, with a cost that may vary based on the agreed-upon services and features.',
      },
      paymentTypeTooltips: {
        deposit:
          'Deposit payment type refers to when customers pay an upfront amount, potentially refundable, to initiate a payment plan for a product or service.',
        contribution:
          'Contribution payment type refers to when customers make  additional payments towards a product or service at any time following the deposit, to pay off the balance or make progress on the payment plan.',
        refund:
          'Refund payment type is occurs when a customers requests their money back for a previously made payment, usually under certain conditions and policies established by the merchant.',
        transfer:
          ' A Transfer payment type occurs when a customer requests that funds get transferred between payment plans. This can only occur for payment plans that oroginate from the same merchant.',
      },
      paymentPlanTypeTooltips: {
        layby: 'A one-time agreement where customers make payments at regular intervals until the balance is paid off',
        subscription:
          'A recurring plan where customers are charged at regular intervals to receive products or services at those intervals.',
        partpay:
          'A payment plan where customers can pay for a products  or service in instalments, with a portion of the total amount due paid at irregular intervals. A deposit is required and the balance upon final request by the merchant.',
        fullpayment: '',
      },
      settlementStateTooltips: {
        daily: 'When the funds are transferred to the merchant on a daily basis',
        weekly: 'When the funds are transferred to the merchant on a weekly basis.',
        monthly: 'When the funds are transferred to the merchant on a monthly basis.',
        yearly: 'When the funds are transferred to the merchant on a yearly basis.',
        settlement: 'Settlement',
      },
    };
  },
  computed: {
    ...mapState('auth', ['merchant', 'user']),
  },
  async activated() {
    await this.pullMerchantPriceList();
  },
  created() {
    this.$root.$on('next-to-account', () => {
      this.next();
    });
    if (!_.isEmpty(this.merchant)) {
      this.onboardingMerchant = _.merge(this.onboardingMerchant, this.merchant);
    } else {
      this.onboardingMerchant = _.merge(this.onboardingMerchant, this.onboarding);
    }
  },
  methods: {
    ...mapActions({
      fetchMerchant: 'auth/fetchMerchant',
      getPriceListById: 'auth/getPriceListById',
    }),
    ...mapGetters({ getMerchantAccount: 'auth/merchant' }),
    next() {
      this.$emit('next');
    },
    async pullMerchantPriceList() {
      this.paymentMethods = [];
      this.paymentPlans = [];
      this.paymentPlanType = [];
      this.paymentPlanState = [];
      this.accounts = [];
      this.settlements = [];
      this.loading = true;

      if (_.isEmpty(this.priceList)) {
        if (this.onboardingMerchant.pricelistId)
          this.prices = await this.getPriceListById(this.onboardingMerchant.pricelistId);
      } else this.prices = this.priceList;

      let updatedMerchant;
      if (this.onboardingMerchant && this.onboardingMerchant._id) {
        updatedMerchant = await this.fetchMerchant(this.onboardingMerchant._id);
      }
      if (!_.isEmpty(this.prices) && this.prices.products && this.prices.products.length > 0 && updatedMerchant) {
        await this.prices.products.forEach((product) => {
          if (
            (product.feeType === 'paymentMethod' && product.name === 'paymentMethodDebicheck') ||
            product.name === 'paymentMethodPeachEft' ||
            product.name === 'paymentMethodPeachCard' ||
            product.name === 'paymentMethodPayat'
          ) {
            if (updatedMerchant && updatedMerchant.paymentPlans && updatedMerchant.paymentPlans.paymentMethods) {
              if (updatedMerchant.paymentPlans.paymentMethods.eft && product.method === 'peachEft') {
                this.paymentMethods.push(product);
              }
              if (updatedMerchant.paymentPlans.paymentMethods.debiCheck && product.method === 'debicheck') {
                this.paymentMethods.push(product);
              }
              if (
                updatedMerchant.paymentPlans.paymentMethods.creditOrDebitCard &&
                (product.method === 'card' || product.method === 'peachCard')
              ) {
                this.paymentMethods.push(product);
              }
              if (updatedMerchant.paymentPlans.paymentMethods.payAt && product.method === 'payat') {
                this.paymentMethods.push(product);
              }
            }
          }

          if (product.feeType === 'paymentType') {
            this.paymentPlans.push(product);
          }

          if (product.feeType === 'paymentplanType') {
            this.paymentPlanType.push(product);
          }

          if (product.feeType === 'orderState') {
            this.paymentPlanState.push(product);
          }

          if (product.feeType === 'settlement') {
            this.settlements.push(product);
          }

          if (product.feeType === 'accountType') {
            this.accounts.push(product);
          }
        });
        if (updatedMerchant.paymentPlans.paymentMethods.terminalCard) {
          const paymentMethod = getTerminalCardProduct(this.prices.products);
          if (paymentMethod) {
            this.paymentMethods.push(paymentMethod);
          }
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    currency(amount) {
      return currencyZAR(amount);
    },
  },
});
