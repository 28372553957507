
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import * as _ from 'lodash';
import { checkIfCardIsValid } from '~/utils/utils';

export default Vue.extend({
  name: 'CreditCard',
  components: {},
  props: {
    step: {
      type: '' as any,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      checkIfCardIsValid,
      notifyUrl: '',
      isLoading: false,
      defaultCard: false,
      redirect: `${process.env.MERCHANT_URL}/dashboard`,
      loadingForm: false,
      isError: false,
      cardId: '',
      isCardAddedSuccessfully: false,
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state: any) => state.user,
    }),
    ...mapGetters({
      merchant: 'auth/merchant',
    }),
  },
  async mounted() {
    this.notifyUrl = process.env.VUE_NOTIFY_URL!;
    const params = new URL(document.location.toString()).searchParams;
    const paramCardId = params.get('cardId')!;
    this.cardId = paramCardId ?? (await localStorage.getItem('cardId')!);
    const addMerchantCard = this.asyncDebounce(this.addCard, 1000, { leading: true, trailing: true });

    if (this.cardId) {
      const card = await this.getCard(this.cardId);
      this.isError = this.isEmpty(card);
      this.isCardAddedSuccessfully = this.checkIfCardIsValid(card);
      // Set card as default card
      if (this.isCardAddedSuccessfully) {
        const payload = {
          ...card,
          defaultCard: true,
        };
        await this.updateCard(payload);
      }
      localStorage.removeItem('cardId');
    } else if (this.step === 'credit-card') {
      await addMerchantCard();
    }
  },
  methods: {
    ...mapActions({
      fetchMerchant: 'auth/fetchMerchant',
      saveMerchant: 'auth/saveMerchant',
      createCard: 'cards/createCard',
      getCards: 'cards/getCards',
      getCard: 'cards/getCard',
      updateCard: 'cards/updateCard',
    }),
    asyncDebounce(func, wait, options?) {
      const debounced = _.debounce(
        (resolve, reject, args) => {
          func(...args)
            .then(resolve)
            .catch(reject);
        },
        wait,
        options,
      );
      return (...args) =>
        new Promise((resolve, reject) => {
          debounced(resolve, reject, args);
        });
    },
    isEmpty(value: any) {
      if (value && value.cardDigits && value.nameOnCard && value.expiryYear && value.expiryMonth) return false;
      return true;
    },
    async reEnterCreditCard() {
      this.isError = false;
      this.cardId = '';
      await this.addCard();
    },
    addCopyAndPayJs(checkoutId: string) {
      const script = document.createElement('script');
      script.setAttribute('src', `${process.env.VUE_COPY_AND_PAY_WIDGET}?checkoutId=${checkoutId}`);
      document.head.appendChild(script);
    },
    removeCheckbox() {
      const elements = document.getElementsByClassName('customInput') as any;
      const elem = document.getElementsByClassName('customLabel') as any;
      while (elements.length > 0 || elem.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
        elem[0].parentNode.removeChild(elem[0]);
      }
      const payButtonText = document.querySelector('.wpwl-button-pay') as any;
      if (payButtonText) {
        payButtonText.innerHTML = 'Add Card';
      }
      this.loadingForm = false;
      return false;
    },
    async addCard(): Promise<void> {
      this.isLoading = true;

      try {
        const card = {
          defaultCard: this.defaultCard,
          redirectUrl: this.redirect,
        } as any;

        const createdCard = (await this.createCard(card)) as any;
        const { checkoutId } = createdCard;
        this.cardId = createdCard?._id.toString();
        this.notifyUrl = `${process.env.VUE_APP_API_URL}cards/${checkoutId}/redirect`;
        await this.addCopyAndPayJs(checkoutId);
        this.loadingForm = true;
        await setTimeout(async () => {
          await this.removeCheckbox();
        }, 1000);
        localStorage.setItem('cardId', createdCard?._id.toString());
        await this.getCards();
      } catch (e) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async nextToECommerce() {
      try {
        this.isLoading = true;
        const payload = {
          ...this.merchant,
          onboardingState: 'credit card added',
        };
        localStorage.removeItem('cardId');
        this.$emit('submit:updateMerchant', payload);
        const result = await this.fetchMerchant(this.merchant._id);
        this.$emit('next:toECommerce', result);
      } catch (err) {
      } finally {
        this.isLoading = false;
      }
    },
    skipToDashboard() {
      this.$emit('dashboard');
    },
    close() {},
  },
});
