
import Vue from 'vue';
import { Component, Prop, Action } from 'nuxt-property-decorator';
import Modal from '~/components/Plans/Modal.vue';

@Component
export default class Success extends Vue {
  @Prop({ default: '' }) message: string;
  @Prop({ default: '' }) orderId: string;
  @Action('orders/fetchOrders') fetchOrders;

  filter: { skip: number; limit: number } = {
    skip: 0,
    limit: 15,
  };

  orderDone() {
    this.$router.push('/dashboard/plans');
    this.$emit('close');
  }

  paymentPlans() {
    this.$router.push(`/dashboard/plans/${this.orderId}`);
    this.$emit('close');
  }

  showCreate() {
    this.$emit('close');
    this.$modal.show(
      Modal,
      {},
      {
        width: '90%',
        scrollable: true,
        height: 'auto',
        adaptive: true,
      },
    );
  }
}
