
import Vue from 'vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { mapActions, mapState } from 'vuex';
import * as _ from 'lodash';

// Slides
import PaymentMethods from './fees/PaymentMethods.vue';
import CustomAccount from './fees/CustomAccount.vue';
import FeesDetails from './fees/FeesDetails.vue';
import SettlementStructure from './SettlementStructure.vue';

export default Vue.extend({
  name: 'FeeStructure',
  components: {
    Swiper,
    SwiperSlide,
    PaymentMethods,
    CustomAccount,
    FeesDetails,
    SettlementStructure,
  },
  props: {
    onboarding: {
      type: Object as any,
      default() {
        return {} as any;
      },
    },
    priceList: {
      type: Object as any,
      default() {
        return {} as any;
      },
    },
  },
  data() {
    return {
      swiperOptions: {
        allowTouchMove: false,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
        },
      },
      ref: null as any,
      onboardingMerchant: {} as any,
      currentSlide: null as any,
      accountType: '' as any,
      methodsSelected: false,
      priceListData: {} as any,
    };
  },
  computed: {
    ...mapState('auth', ['merchant', 'user']),
  },
  watch: {
    currentSlide(newSlide) {
      this.currentSlide = newSlide;
      if (this.onboardingMerchant.accountType) {
        if (this.onboardingMerchant.accountType.toLowerCase() === 'custom') {
          this.accountType = this.onboardingMerchant.accountType.toLowerCase();
        }
        if (this.onboardingMerchant.paymentPlans) {
          if (
            (this.onboardingMerchant.paymentPlans.paymentMethods.eft ||
              this.onboardingMerchant.paymentPlans.paymentMethods.creditOrDebitCard ||
              this.onboardingMerchant.paymentPlans.paymentMethods.debiCheck) &&
            this.methodsSelected === false
          ) {
            this.ref.swiper.swiperInstance.slideNext();
            this.methodsSelected = true;
            this.currentSlide = this.ref.swiper.swiperInstance.activeIndex;
          }
        }
      }
    },
  },
  async activated() {
    if (this.priceList) {
      this.priceListData = this.priceList;
    } else if (this.onboardingMerchant.pricelistId) {
      this.priceListData = await this.getPriceListById(this.onboardingMerchant.pricelistId);
    }
  },
  mounted() {
    this.ref = this.$refs;
    this.currentSlide = this.ref.swiper.swiperInstance.activeIndex;
    if (!_.isEmpty(this.merchant)) {
      this.onboardingMerchant = _.merge(this.onboardingMerchant, this.merchant);
    } else {
      this.onboardingMerchant = _.merge(this.onboardingMerchant, this.onboarding);
    }
  },
  methods: {
    ...mapActions({ fetchMerchant: 'auth/fetchMerchant', getPriceListById: 'auth/getPriceListById' }),
    nextInSlide() {
      this.currentSlide = this.ref.swiper.swiperInstance.activeIndex;
      if (
        (this.onboardingMerchant.accountType.toLowerCase() === 'custom' ||
          this.onboardingMerchant.accountType.toLowerCase() === 'free' ||
          this.onboardingMerchant.accountType.toLowerCase() === 'trial') &&
        this.onboardingMerchant.pricelistId == null
      ) {
        if (this.currentSlide === 0) {
          this.$root.$emit('next-to-payments');
        } else if (this.currentSlide === 1) {
          this.$root.$emit('next-to-fees');
        } else if (this.currentSlide === 2) {
          this.$root.$emit('next-to-account');
        } else {
          this.nextToSign();
        }
      } else if (this.currentSlide === 0) {
        this.$root.$emit('next-to-fees');
      } else if (this.currentSlide === 1) {
        this.$root.$emit('next-to-account');
      } else {
        this.nextToSign();
      }
    },
    skipToDashboard() {
      this.$emit('dashboard');
    },
    async nextToSign() {
      this.onboardingMerchant.onboardingState = 'payment methods selected';
      await this.fetchMerchant(this.onboardingMerchant._id)
        .then((result) => {
          result.onboardingState = 'payment methods selected';
          this.$emit('submit:updateMerchant', result);
          this.$emit('next:toRegistration', result);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    next() {
      if (this.ref && this.ref.swiper) {
        this.ref.swiper.swiperInstance.slideNext();
        this.currentSlide = this.ref.swiper.swiperInstance.activeIndex;
      }
    },
    close() {
      if (this.ref && this.ref.swiper) {
        this.ref.swiper.swiperInstance.slidePrev();
        this.currentSlide = this.ref.swiper.swiperInstance.activeIndex;
      }
    },
    save(onboardingMerchant) {
      this.$emit('submit:updateMerchant', onboardingMerchant);
    },
  },
});
