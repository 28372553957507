
import { Action, Component, Getter, Prop, Watch } from 'nuxt-property-decorator';
import QrcodeVue from 'qrcode.vue';
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import ToggleInput from '~/components/Form/ToggleInput.vue';
import VInput from '~/components/Form/VInput.vue';
import Modal from '~/components/Plans/Modal.vue';
import { IOrder, IOrderShareRequest } from '../../models/orders';
import Success from './Success.vue';

@Component({
  components: {
    VInput,
    ToggleInput,
    QrcodeVue,
    ValidationObserver,
  },
})
export default class Share extends Vue {
  @Prop({ default: () => {} }) order!: IOrder;
  @Prop({ default: false }) closeOnComplete!: boolean;

  @Getter('auth/clientUrl') clientUrl;
  @Action('orders/shareOrder') shareOrder;

  active: string = 'email';
  currentOrder: IOrder = this.order;
  tabSend = 'EMAIL';
  iconName = '';
  shareRequestEmail: IOrderShareRequest = {
    data: {
      orderId: this.order._id || '',
      type: 'orders_send_request',
      orderUrl: '',
      contact: '',
      message: '',
      sendType: 'email',
    },
  };

  shareRequestSms: IOrderShareRequest = {
    data: {
      type: 'orders_send_request',
      orderId: this.order._id || '',
      orderUrl: '',
      contact: '',
      message: '',
      sendType: 'sms',
    },
  };

  emailRequired = false;
  cellNumberRequired = false;
  show = true;

  shouldCloseOnComplete = this.closeOnComplete;

  @Watch('order')
  onOrderChange(val: any) {
    this.currentOrder = val;
  }

  setShare(val: any) {
    if (val === 'cell') {
      this.tabSend = 'SMS';
      this.iconName = 'sms';
    }
    if (val === 'link') {
      this.tabSend = 'COPY';
      this.iconName = 'content_copy';
    }
    if (val === 'email') {
      this.tabSend = 'EMAIL';
      this.iconName = 'email';
    }
    if (val === 'qrcode') {
      this.tabSend = 'QR Code';
    }
    this.active = val;
  }

  onSuccess() {
    Vue.$toast.success('Alert!', {
      position: 'top',
      message: `Copied order link successfully`,
    });
  }

  displaySuccessMessage(message: string, id?: string) {
    this.$modal.show(Success, {
      message,
      orderId: id,
    });
  }

  onError() {
    Vue.$toast.error('Alert!', {
      position: 'top',
      message: `Copied order link unsuccessfully`,
    });
  }

  displayRequiredOrErrorMessage(message: string) {
    Vue.$toast.error('Alert!', {
      position: 'top',
      message,
    });
  }

  async setNotification(payload: IOrderShareRequest, type: string) {
    this.cellNumberRequired = false;
    this.emailRequired = false;
    this.shouldCloseOnComplete = true;
    try {
      if (payload.data.contact) {
        await this.shareOrder(payload);
        this.displaySuccessMessage(`Your ${type} link has been sent Successfully!`, this.currentOrder._id);
        this.shouldCloseOnComplete = true;
      } else {
        if (type === 'sms') this.cellNumberRequired = true;
        if (type === 'email') this.emailRequired = true;
        this.shouldCloseOnComplete = false;
        this.displayRequiredOrErrorMessage('Please fill in required fields.');
      }
      if (this.shouldCloseOnComplete) {
        this.$emit('close');
      }
    } catch (error: any) {
      this.shouldCloseOnComplete = false;
      let errorMessage = 'Something went wrong. Please try again later';
      if (error?.response?.data) errorMessage = error.response.data;
      this.displayRequiredOrErrorMessage(errorMessage);
    }
  }

  async shareComplete() {
    switch (this.active) {
      case 'cell': {
        await this.setNotification(this.shareRequestSms, 'sms');
        break;
      }
      case 'email':
      default: {
        await this.setNotification(this.shareRequestEmail, 'email');
        break;
      }
    }
  }

  orderDone() {
    this.$router.push('/dashboard/plans');
    this.$emit('share:complete');
    this.$emit('close');
  }

  paymentPlans() {
    if (this.currentOrder) {
      this.$router.push(`/dashboard/plans/${this.currentOrder._id}`);
      this.$emit('share:complete');
    }
  }

  showCreate() {
    this.$emit('share:complete');
    this.$modal.show(
      Modal,
      {},
      {
        width: '90%',
        scrollable: true,
        height: 'auto',
        adaptive: true,
      },
    );
  }

  get orderUrl() {
    if (this.currentOrder) {
      return `${this.clientUrl}/order/${this.currentOrder._id}`;
    }
    return null;
  }

  created() {
    this.shareRequestEmail.data.orderId = this.shareRequestSms.data.orderId = this.order._id;
    this.shareRequestEmail.data.orderUrl = this.shareRequestSms.data.orderUrl = this.orderUrl || '';
  }
}
