
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import VTextarea from '../Form/VTextarea.vue';

export default Vue.extend({
  name: 'ContactSupport',
  components: {
    VTextarea,
  },
  data() {
    return {
      message: '',
      loading: false,
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state: any) => state.user,
    }),
    ...mapGetters({
      merchant: 'auth/merchant',
    }),
  },
  methods: {
    ...mapActions({ sendChangeRequest: 'auth/sendMerchantSupportAndContactTicket' }),
    async send() {
      // ToDo: send contract change request
      this.loading = true;
      const data = {
        merchantName: this.merchant.name,
        message: this.message,
        type: 'bank_details_validation_support',
        userEmail: this.user.email,
        user: this.user.name,
        merchantId: this.merchant._id,
      };
      try {
        await this.sendChangeRequest(data);
        Vue.$toast.success('Alert!', {
          position: 'top',
          message: 'Sent email successfully to support.',
        });
        this.close();
      } catch {
        Vue.$toast.error('Alert!', {
          position: 'top',
          message: 'There was an error, please try again.',
        });
      }

      this.loading = false;
    },
    close() {
      this.$emit('close');
    },
  },
});
