import Vue from 'vue';
import moment from 'moment';

export const currency = function (amount, modifier = 100) {
  if (amount === null) return '';
  const amountReg = amount.toString().match(/[\d.]+/);
  if (amountReg) {
    amount = amountReg[0];
  } else {
    amount = '';
  }

  return new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
  }).format(amount / modifier);
};

Vue.filter('currency', currency);

export const date = function (date, format = 'D MMMM YYYY') {
  return moment(date).format(format);
};

Vue.filter('date', date);
