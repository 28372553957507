
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import VTextarea from '../Form/VTextarea.vue';

export default Vue.extend({
  name: 'ChangeRequest',
  components: {
    VTextarea,
  },
  props: {
    merchantData: {} as any,
  },
  data() {
    return {
      message: '',
      loading: false,
      merchantOnboarding: {} as any,
    };
  },
  computed: {
    ...mapState('auth', ['user', 'merchant']),
  },
  created() {
    this.merchantOnboarding = this.merchant;
  },
  methods: {
    ...mapActions({ sendChangeRequest: 'auth/sendMerchantSupportAndContactTicket' }),
    async send() {
      // ToDo: send contract change request
      this.loading = true;
      const data = {
        merchantName: this.merchantOnboarding.name,
        message: this.message,
        type: 'change_request',
        email: this.user.email,
        name: this.user.name,
      };
      await this.sendChangeRequest(data)
        .then((result) => {
          console.log(result);
          Vue.$toast.success('Alert!', {
            position: 'top',
            message: 'Sent email successfully to support.',
          });
          this.loading = false;
          this.$emit('close');
        })
        .catch(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit('close');
    },
  },
});
