
import Vue from 'vue';

export default Vue.extend({
  props: {
    onboarding: {
      type: Object as any,
    },
  },
  mounted() {
    this.$root.$on('next-to-payments', async () => {
      this.next();
    });
  },
  methods: {
    next() {
      this.$emit('next');
    },
  },
});
