
import Vue from 'vue';
export default Vue.extend({
  props: {
    label: {
      required: false,
      type: String,
      default: '',
    },
    value: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.value,
    };
  },
  watch: {
    checked(val) {
      this.$emit('input', val);
    },
  },
});
