import Vue from 'vue'
import 'vue-material/dist/vue-material.min.css'

  import  { MdDrawer,MdRadio,MdMenu,MdContent,MdList,MdButton,MdToolbar } from 'vue-material/dist/components'

    Vue.use(MdDrawer)

    Vue.use(MdRadio)

    Vue.use(MdMenu)

    Vue.use(MdContent)

    Vue.use(MdList)

    Vue.use(MdButton)

    Vue.use(MdToolbar)
