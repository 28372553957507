import { ActionTree, MutationTree, GetterTree } from 'vuex/types/index';
import type { ICard } from './types';

export const state = () => {
  return {
    cards: [] as ICard[],
  };
};

export const getters: GetterTree<any, any> = {
  getCards(state) {
    const cards = state.cards;
    return cards;
  },
};

export const mutations: MutationTree<any> = {
  setCards(state, cards: ICard[]) {
    state.cards = cards;
  },
};

export const actions: ActionTree<any, any> = {
  async getCards({ commit }) {
    const cards: { clientCards: ICard[] } = await this.$axios.$get('cards');

    const filteredCards = cards.clientCards
      ?.filter((card) => card.cardDigits && card.cardType && card.nameOnCard && card.expiryMonth && card.expiryYear)
      .sort((a: any, b: any) => {
        return b.defaultCard - a.defaultCard;
      });
    commit('setCards', filteredCards);
    return filteredCards;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getCard({ commit }, cardId: string) {
    const card: ICard = await this.$axios.$get(`cards/${cardId}`);
    return card;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async createCard({ commit }, payload: Partial<ICard>) {
    const card = await this.$axios.$post('/cards', payload);
    return card;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async deleteCard({ commit }, cardId: string) {
    const card = await this.$axios.$delete(`cards/${cardId}`);
    return card;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async updateCard({ commit }, card: ICard) {
    const result = await this.$axios.$put(`cards/${card._id}`, card);
    return result;
  },
};
