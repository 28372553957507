
import Vue from 'vue';
import { ValidationProvider } from 'vee-validate';
import { Prop, Component, Watch } from 'nuxt-property-decorator';
import _ from 'lodash';
import { currency } from '../../plugins/filters';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class VCurrency extends Vue {
  @Prop({ default: '' }) value!: number;
  @Prop({}) rules: String;
  @Prop({}) vid: String;
  @Prop({ default: false }) disabled: Boolean;
  @Prop({ default: false, required: false }) error: Boolean;

  floatValue: number | null = _.isNumber(this.value) ? this.value / 100 : this.value;

  displayValue: any | null = this.format(this.floatValue);
  hasFocus: boolean = false;

  numbersOnly(e) {
    if (e.code.match(/Key|Space/g)) {
      // Keyboard key detected
      if (e.key.match(/[\d.]+/)) {
        return;
      }
      e.preventDefault();
      e.returnValue = false;
    }
  }

  toDecimal(val) {
    if (val) {
      const filtered = val
        .toString()
        .replace(' ', '')
        .replace(',', '.')
        .match(/[\d.]+/g);

      if (filtered) val = filtered.join('');
    } else {
      return null;
    }

    return parseFloat(val);
  }

  format(val: any) {
    if (val === 0) return null;
    return currency(val, 1);
  }

  onBlur() {
    this.displayValue = this.format(this.floatValue);
    this.hasFocus = false;
  }

  onFocus() {
    this.displayValue = this.toDecimal(this.floatValue);
    this.hasFocus = true;
  }

  @Watch('value')
  onValueChange(val) {
    if (_.isNumber(val)) {
      val = val / 100;
    }
    if (val !== this.floatValue) {
      this.floatValue = val;
    }
  }

  updateFloatValue(e) {
    const val = e.target.value;
    this.floatValue = parseFloat(val);
    this.$emit('change', e);
  }

  @Watch('floatValue')
  onFloatChange(val, old) {
    if (old !== val) {
      this.$emit('input', val * 100);
    }
    this.displayValue = this.hasFocus ? val : this.format(val);
  }
}
