var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"create-account"},[(_vm.loading)?_c('div',[_vm._m(0),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.download)?_c('p',{staticClass:"text-center"},[_vm._v("Please wait while a preview of the contract downloads.")]):_c('p',{staticClass:"text-center"},[_vm._v("Please wait while we create contract and send it to you for signature.")])]):_c('div',{staticClass:"create-account"},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("Sign Contract")]),_vm._v(" "),(
          !_vm.isEmpty(_vm.onboardingMerchant)
            ? _vm.onboardingMerchant.merchantContractSigned !== 'pending' &&
              _vm.onboardingMerchant.merchantContractSigned !== 'signed'
            : _vm.onboarding.merchantContractSigned !== 'pending' && _vm.onboarding.merchantContractSigned !== 'signed'
        )?_c('p',[_vm._v("\n        Please download the contract and sign it. Once complete please send to support@layup.co.za\n      ")]):_vm._e(),_vm._v(" "),(
          !_vm.isEmpty(_vm.onboardingMerchant)
            ? _vm.onboardingMerchant.merchantContractSigned !== 'pending' &&
              _vm.onboardingMerchant.merchantContractSigned !== 'signed'
            : _vm.onboarding.merchantContractSigned !== 'pending' && _vm.onboarding.merchantContractSigned !== 'signed'
        )?_c('keep-alive',[_c('Contract',{attrs:{"merchant-name":!_vm.isEmpty(_vm.onboarding) ? _vm.onboarding.name : _vm.onboardingMerchant.name,"merchant-registration":!_vm.isEmpty(_vm.onboarding) ? _vm.onboarding.registeredEntity : _vm.onboardingMerchant.registeredEntity,"merchant-data":!_vm.isEmpty(_vm.onboarding) ? _vm.onboarding : _vm.onboardingMerchant,"merchant-address":_vm.onboardingMerchant.billingAddress,"bank-details":!_vm.isEmpty(_vm.onboarding) ? _vm.onboarding.bankDetails : _vm.onboardingMerchant.bankDetails,"admin-fee":!_vm.isEmpty(_vm.onboarding) ? _vm.onboarding.adminPercFee : 3,"merchant-bank-fee":!_vm.isEmpty(_vm.onboarding) ? _vm.onboarding.merchantBankingPercFee : 2,"settlement-structure":!_vm.isEmpty(_vm.onboarding)
              ? {
                  percFee: _vm.onboarding.percFee,
                  settlementDay: _vm.onboarding.settlementDay,
                  settlementFrequency: _vm.onboarding.settlementFrequency,
                  settlementType: _vm.onboarding.settlementType,
                }
              : {}}})],1):(
          !_vm.isEmpty(_vm.onboardingMerchant)
            ? _vm.onboardingMerchant.merchantContractSigned === 'pending'
            : _vm.onboarding.merchantContractSigned === 'pending'
        )?_c('div',[_c('p',{staticClass:"text-center"},[_vm._v("Contract status: "+_vm._s(_vm.onboardingMerchant.merchantContractSigned))]),_vm._v(" "),_c('p',{staticClass:"text-center"},[_vm._v("\n          An email has been sent to "+_vm._s(_vm.user.email)+" with instructions to sign the contract. Please go to your email\n          and sign the agreement.\n        ")]),_vm._v(" "),_c('p',{staticClass:"text-center"},[_vm._v("\n          Once you received a notification that the agreement has been signed by all parties you can return here to\n          finalise your onboarding.\n        ")])]):_c('div',[_c('md-icon',{staticClass:"active-icon"},[_vm._v("check")]),_vm._v(" "),_c('p',{staticClass:"text-center"},[_vm._v("\n          The License agreement has been signed. Please click the finish button to complete the process. Thank you.\n        ")])],1)],1)]),_vm._v(" "),_c('hr',{staticClass:"md-seperator"}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 col-sm-12 buttons"},[_c('div',{staticClass:"md-layout md-alignment-center"},[(
            !_vm.isEmpty(_vm.onboardingMerchant)
              ? _vm.onboardingMerchant.merchantContractSigned !== 'pending' &&
                _vm.onboardingMerchant.merchantContractSigned !== 'signed'
              : !(_vm.onboarding.merchantContractSigned !== 'pending' && _vm.onboarding.merchantContractSigned !== 'signed')
          )?_c('md-button',{staticClass:"btn btn-link",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.goBack()}}},[_vm._v("Back")]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"col-md-8 col-sm-12 buttons"},[(
          !_vm.isEmpty(_vm.onboardingMerchant)
            ? _vm.onboardingMerchant.merchantContractSigned !== 'pending' &&
              _vm.onboardingMerchant.merchantContractSigned !== 'signed'
            : !(_vm.onboarding.merchantContractSigned !== 'pending' && _vm.onboarding.merchantContractSigned !== 'signed')
        )?_c('div',{staticClass:"md-layout md-alignment-center"},[_c('md-button',{staticClass:"md-primary md-raised md-layout-item md-size-20 md-small-size-100",attrs:{"disabled":_vm.loading,"name":"downloadAgreement"},on:{"click":function($event){return _vm.downloadContract()}}},[_vm._v("Download Agreement")]),_vm._v(" "),_c('md-button',{staticClass:"md-primary md-raised md-layout-item md-size-20 md-small-size-100",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.contractChangeRequest()}}},[_vm._v("Change Request")])],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-md-2 col-sm-12 buttons",staticStyle:{"float":"right !important"}},[_c('div',{staticClass:"md-layout md-alignment-center"},[_c('md-button',{staticClass:"btn btn-link",attrs:{"name":"skipToDashboard","disabled":_vm.loading},on:{"click":_vm.skipToDashboard}},[_vm._v("Skip to Dashboard")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"})])])
}]

export { render, staticRenderFns }