export default async function ({ store, $axios, redirect, route }) {
  try {
    // Only do this if the user has not been loaded yet
    if (store.state.auth.user) {
      return;
    }

    if (route.name === 'email-verification-success') {
      return;
    }

    if (route.name === 'mobile-screen') {
      return;
    }

    if (route.name === 'email-screen') {
      return;
    }

    if (route.name === 'register') {
      return;
    }

    if (route.name === 'verify-mobile') {
      return;
    }

    if (route.name === 'verify-email') {
      return;
    }

    if (route.name === 'enter-otp') {
      return;
    }

    const isLoggedIn = await store.dispatch('auth/tryLogin');
    const user = await store.state.auth.user;
    // Block user without merchant ID from signing in akha@gg.com
    // Checking if user is admin and grant access to settings
    if (route.name === 'dashboard-settings' && user && user.merchantRole !== 'ADMIN') {
      return redirect('/dashboard');
    }

    if (route.name === 'dashboard-Cancellations' && user && user.merchantRole !== 'ADMIN') {
      return redirect('/dashboard');
    }

    if (isLoggedIn && route.name === 'index') {
      return redirect('/dashboard');
    }

    // if the user should not be here
    if (route.name !== 'index' && route.name !== 'forgot-password' && route.name !== 'reset-password' && !isLoggedIn) {
      return redirect('/');
    }
  } catch (error) {
    return redirect('/');
  }
}
