var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"payment-methods"},[_c('div',{staticClass:"slide-div"},[_c('h3',{staticClass:"text-center"},[_vm._v("Payment Methods")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h5',{staticClass:"text-center"},[_vm._v("Choose Payment Methods")]),_vm._v(" "),_vm._m(0),_vm._v(" "),(_vm.error)?_c('p',{staticClass:"text-center error"},[_vm._v(_vm._s(_vm.messageError))]):_vm._e(),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"1em"}},[_vm._m(1)]):_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',[(_vm.paymentMethods && _vm.paymentMethods.length > 0)?_c('table',{staticClass:"table cus-table"},[_vm._m(2),_vm._v(" "),_c('tbody',_vm._l((_vm.paymentMethods),function(paymentMethod,index){return _c('tr',{key:index},[_c('td',{staticClass:"td",attrs:{"colspan":"2"}},[_c('div',{staticClass:"box",attrs:{"name":`paymentMethodsCheckbox_` + index},on:{"click":function($event){return _vm.checkbox(paymentMethod.method)}}},[_c('div',{staticClass:"circle flex-center",class:{ active: paymentMethod.value }},[_c('div',{staticClass:"circle-content"})]),_vm._v(" "),_c('span',[_vm._v(_vm._s(paymentMethod.method.toLowerCase() === 'peachcard'
                      ? 'Credit & Debit Card'
                      : paymentMethod.method.toLowerCase() === 'peacheft'
                      ? 'Instant Eft'
                      : _vm.isTerminalCardSelected(paymentMethod.method.toLowerCase())
                      ? 'Terminal Card'
                      : _vm.capitalize(paymentMethod.method)))]),_vm._v(" "),_c('div',{staticClass:"right"},[_c('span',[_vm._v(_vm._s(paymentMethod.percentage > 0 ? paymentMethod.percentage + '%' : '')+"\n                      "+_vm._s(paymentMethod.percentage && paymentMethod.flat ? ' + ' : '')+"\n                      "+_vm._s(paymentMethod.flat > 0 ? _vm.convertAmount(paymentMethod.flat) : ''))])])])])])}),0)]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-center"},[_vm._v("\n      Please select from the options below, the preferred payment methods to be available for your customers.\n      "),_c('br'),_vm._v("Note that different fees may apply for certain methods, as indicated next to each option. Your selection\n      can be amended at a later stage.\n    ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',[_c('tr',[_c('th',{staticClass:"td"}),_vm._v(" "),_c('th',{staticClass:"td"},[_vm._v("Per Transaction")])])])
}]

export { render, staticRenderFns }