import moment from 'moment';
import Fuse from 'fuse.js';

const fuseOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ['reference', 'name', 'products.sku', 'plans.benefactor.name'],
};

export interface DateRange {
  from?: Date;
  to?: Date;
}

export function filterDaterange(collection, field: string, daterange: DateRange) {
  if (daterange.from) {
    const from = moment(daterange.from).startOf('day');
    collection = collection.filter((item) => moment(item[field]).startOf('day').format('x') >= from.format('x'));
  }

  if (daterange.to) {
    const to = moment(daterange.to).endOf('day');
    collection = collection.filter((item) => moment(item[field]).endOf('day').format('x') <= to.format('x'));
  }

  return collection;
}

export function filterSearch(list, term) {
  const fuse = new Fuse(list, fuseOptions); // "list" is the item array
  return fuse.search(term);
}

export function activeOrders(list) {
  return list.filter((order) => order.state === 'PLACED');
}

export function cancelledOrders(list) {
  return list.filter((order) => ['CANCELLED'].includes(order.state));
}

export function completedOrders(list) {
  return list.filter((order) => order.state == 'COMPLETED');
}
