
import Vue from 'vue';
import { Component, Prop, Watch } from 'nuxt-property-decorator';
import { ValidationProvider } from 'vee-validate';
import VCurrency from './VCurrency.vue';

@Component({
  components: {
    ValidationProvider,
    VCurrency,
  },
})
export default class VPercentage extends Vue {
  @Prop({ default: '' }) value!: any;
  @Prop({}) rules: String;
  @Prop({}) vid: String;
  @Prop({}) percentage!: number;
  @Prop({ default: 0 }) from!: number;

  percentageFocus: Boolean = false;
  currentPercentage: number = this.percentage;

  numbersOnly(e) {
    if (e.code.match(/Key|Space/g)) {
      // Keyboard key detected
      if (e.key.match(/[\d.]+/)) {
        return;
      }
      e.preventDefault();
      e.returnValue = false;
    }
  }

  get floatValue() {
    return Math.floor((this.currentPercentage / 100) * this.from);
  }

  updatePercentage(e) {
    let val = e.target.value;
    if (val) val = parseFloat(val) * 100;
    this.currentPercentage = parseFloat(((val / this.from) * 100).toFixed(1));
  }

  @Watch('currentPercentage')
  onPercentageUpdate(val) {
    this.$emit('input:percentage', parseFloat(val));
  }

  @Watch('floatValue')
  onFloatChange(val) {
    this.$emit('input', parseFloat(val));
  }
}
