import { ActionTree, MutationTree, GetterTree } from 'vuex/types/index';
import type { BankFromApi, Banks, BanksFromApi } from './types';

export const state = () => {
  return {
    banks: {},
  };
};

export const getters: GetterTree<any, any> = {
  getBanks(state) {
    const banks = state.banks;
    return banks;
  },
};

export const mutations: MutationTree<any> = {
  setBanks(state, banks: Banks) {
    state.banks = banks;
  },
};

export const actions: ActionTree<any, any> = {
  async getBanks({ commit }) {
    const response: { banks: BanksFromApi } = await this.$axios.$get('banks');
    const banks: Banks = response.banks
      .map((bank: BankFromApi) => ({
        name: bank.name,
        branchCode: bank.branchCode,
      }))
      .sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    commit('setBanks', banks);
    return banks;
  },
};
