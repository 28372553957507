
import { ValidationProvider } from 'vee-validate';
import { Component } from 'nuxt-property-decorator';
import VInput from './VInput.vue';
@Component({
  components: {
    ValidationProvider,
  },
})
export default class VDatepicker extends VInput {}
