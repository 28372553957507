
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import * as _ from 'lodash';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import type { IMerchantAccount } from '~/models/auth';

export default Vue.extend({
  components: {
    VueGoogleAutocomplete,
  },
  data() {
    return {
      accountTypes: [] as any,
      accountType: '',
      onboardingMerchant: {} as any,
      loading: false,
      account: {} as any,
      full_address: '',
      merchantName: '',
      merchantOnboardingAddress: {
        name: '',
        physicalAddress: {
          country: '',
          province: '',
          streetAddress: '',
          city: '',
          postalCode: '',
        },
      } as any,
      merchantAccounts: [] as IMerchantAccount[],
    };
  },
  computed: {
    ...mapState('auth', {
      merchant: (state: any) => state.merchant,
      user: (state: any) => state.user,
    }),
    hasName(): boolean {
      return !!this.user.merchantOnboarding.name;
    },
    hasFullAddress(): boolean {
      return !!(
        this.merchantOnboardingAddress.physicalAddress.streetAddress &&
        this.merchantOnboardingAddress.physicalAddress.country &&
        this.merchantOnboardingAddress.physicalAddress.province &&
        this.merchantOnboardingAddress.physicalAddress.postalCode &&
        this.merchantOnboardingAddress.physicalAddress.city
      );
    },
  },
  watch: {
    accountType(value) {
      if (value) {
        if (!_.isEmpty(this.merchantAccounts))
          this.account = this.merchantAccounts.filter((account: any) => {
            if (account.name === value) return account;
            return null;
          });
        if (this.account && this.account.length > 0) {
          this.onboardingMerchant = {
            percFee: this.account[0].adminFeePerc + this.account[0].bankFeePerc,
            settlementType: this.account[0].settlementType,
            settlementFrequency: this.account[0].settlementFrequency,
            settlementDay: this.account[0].settlementDay,
            pricelistId: this.account[0].pricelistId,
          };
        }
      }
    },
  },
  async created() {
    this.merchantAccounts = await this.getMerchantAccounts();
    this.formatMerchantAccountForDisplay();
    if (!_.isEmpty(this.merchant)) {
      this.onboardingMerchant = _.merge(this.onboardingMerchant, this.merchant);
      this.accountType = this.onboardingMerchant.accountType;
    }
    if (!_.isEmpty(this.user.merchantOnboarding)) {
      this.merchantOnboardingAddress = this.user.merchantOnboarding;
    }
  },
  activated() {
    this.checkIfUserEmailIsVerified();
  },
  methods: {
    ...mapActions({
      getUser: 'auth/getUser',
      sendEmailVerification: 'auth/sendEmailVerification',
      getMerchantAccounts: 'auth/getMerchantAccounts',
      setUser: 'auth/setUser',
    }),
    checkForRequiredNotAvailable() {
      if (
        this.merchantOnboardingAddress.name == null ||
        this.merchantOnboardingAddress.name === '' ||
        this.merchantOnboardingAddress.physicalAddress.country == null ||
        this.merchantOnboardingAddress.physicalAddress.country === '' ||
        this.merchantOnboardingAddress.physicalAddress.province == null ||
        this.merchantOnboardingAddress.physicalAddress.province === '' ||
        this.merchantOnboardingAddress.physicalAddress.city == null ||
        this.merchantOnboardingAddress.physicalAddress.city === '' ||
        this.merchantOnboardingAddress.physicalAddress.streetAddress == null ||
        this.merchantOnboardingAddress.physicalAddress.streetAddress === '' ||
        this.merchantOnboardingAddress.physicalAddress.postalCode === '' ||
        this.merchantOnboardingAddress.physicalAddress.postalCode == null
      )
        return true;

      return false;
    },
    changeAddress() {
      this.full_address = 'full address';
    },
    getAddressData(addressData) {
      const country = addressData.country;
      const province = addressData.administrative_area_level_1;
      const city = addressData.locality;
      let address = '';
      let postalCode = '';
      if (addressData.street_number) {
        address = `${addressData.street_number} ${addressData.route}`;
      } else {
        address = addressData.route;
      }

      if (addressData.postal_code) postalCode = addressData.postal_code;

      const addr = {
        a: address,
        b: city,
        c: province,
        d: country,
        e: postalCode,
      };

      this.full_address = Object.entries(addr)
        .map((x) => x[1])
        .join(', ');

      this.merchantOnboardingAddress.physicalAddress.streetAddress = address;
      this.merchantOnboardingAddress.physicalAddress.city = city;
      this.merchantOnboardingAddress.physicalAddress.country = country;
      this.merchantOnboardingAddress.physicalAddress.postalCode = postalCode;
    },
    formatMerchantAccountForDisplay() {
      const merchantAccounts: any[] = [];
      this.merchantAccounts.forEach((element) => {
        if (element && element.active && element.numberOfUsers) {
          merchantAccounts.push({
            key: `${_.capitalize(element.name)} (R ${_.floor(element.amount / 100, 2)} /pm, up to ${
              element.numberOfUsers
            } user/s)`,
            value: `${element.name}`,
          });
        }
      });

      merchantAccounts.push({
        key: `Custom (Request custom account type) `,
        value: `CUSTOM`,
      });

      this.accountTypes = merchantAccounts;
    },
    nextToRegistration() {
      this.onboardingMerchant.accountType = this.accountType;
      if (this.account && this.account.length > 0) {
        this.onboardingMerchant.percFee = this.account[0].adminFeePerc + this.account[0].bankFeePerc;
        this.onboardingMerchant.settlementType = this.account[0].settlementType;
        this.onboardingMerchant.settlementFrequency = this.account[0].settlementFrequency;
        this.onboardingMerchant.settlementDay = this.account[0].settlementDay;
      }
      if (!this.hasFullAddress) {
        Vue.$toast.error('Alert!', {
          position: 'top',
          message: `All business physical address fields are required.`,
        });
        return;
      }

      if (!this.hasName) {
        Vue.$toast.error('Alert!', {
          position: 'top',
          message: `Merchant name is required.`,
        });
        return;
      }

      this.onboardingMerchant.name = this.merchantOnboardingAddress.name;
      this.onboardingMerchant.billingAddress = this.merchantOnboardingAddress.physicalAddress;
      this.onboardingMerchant.onboardingState = 'new';

      this.createAccount();
      this.$emit('submit:merchant', this.onboardingMerchant);
    },
    checkIfUserEmailIsVerified() {
      if (this.user && !this.user.verifiedEmail) {
        const interval = setInterval(async () => {
          const verifiedUser = await this.getUser(this.user._id);
          this.setUser(verifiedUser);
          if (verifiedUser?.verifiedEmail) {
            clearInterval(interval);
          }
        }, 5000);
      }
    },
    goBack() {
      this.$emit('goBack:close');
    },
    createAccount() {
      this.$emit('created:account', this.onboardingMerchant);
    },
    skipToDashboard() {
      this.$emit('dashboard');
    },
    async resendEmailVerificationLink() {
      try {
        this.loading = true;
        const data = {
          name: this.user.name,
          email: this.user.email,
          _id: this.user._id,
          redirectTo: `${process.env.MERCHANT_URL}/email-verification-success`,
        };
        await this.sendEmailVerification(data)
          .then(() => {
            Vue.$toast.success('Alert!', {
              position: 'top',
              message: `Email verification link was sent successfully.`,
            });
            this.loading = false;
          })
          .catch(() => {
            Vue.$toast.error('Alert!', {
              position: 'top',
              message: `Sending email verification was unsuccessfully. Please try again later.`,
            });
            this.loading = false;
          });
      } catch (error) {
        this.loading = false;
      }
    },
  },
});
