
import Vue from 'vue';
import { Component, State } from 'nuxt-property-decorator';
import { ValidationProvider } from 'vee-validate';
import VInput from './VInput.vue';

@Component({
  extends: VInput,
  components: {
    ValidationProvider,
  },
})
export default class VFile extends Vue {
  @State((state) => state.config.CLOUDINARY_NAME) cloudName;
  @State((state) => state.config.UPLOAD_PRESET) uploadPreset;
  onFileChosen(filelist) {
    const file = filelist[0];
    this.uploadFile(file);
  }

  uploadFile(file) {
    /* eslint-ignore-next-line */
    const url = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    // Reset the upload progress bar

    // Update progress (can be used to show progress indicator)
    /* xhr.upload.addEventListener('progress', function(e) {
      console.log(`fileuploadprogress data.loaded: ${e.loaded},
      data.total: ${e.total}`)
    }) */

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully
        const response = JSON.parse(xhr.responseText);
        // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg
        const url = response.secure_url;
        this.$emit('input', url);
        // Create a thumbnail of the uploaded image, with 150px width
      }
    };

    fd.append('upload_preset', this.uploadPreset);
    fd.append('file', file);
    xhr.send(fd);
  }
}
